import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/pl'
import { API } from '../utils/constants'

export const TileBase = styled.div`
  width: 80%;

  @media only screen and (max-width: 1366px) {
    padding: 1em;
  }

  @media only screen and (max-width: 600px) {
    padding: 8px;
    margin-bottom: 1em;
    width: 90%;
  }

  @media screen and (min-width: 600px) {
    &:nth-child(1) {
      margin-bottom: 7.5em;
    }
    &:nth-child(2) {
      margin-top: 7.5em;
    }
    &:nth-child(2n + 3) {
      margin-top: -7.5em;
      margin-bottom: 7.5em;
    }
  }
`

export const TileImage = styled.div`
  padding-top: 56.25%;
  background-image: ${props =>
    /https?:\/\//i.test(props.imageUrl)
      ? `url("${props.imageUrl}")`
      : `url("${API}${props.imageUrl}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const TileHeader = styled.p`
  font-size: 42px;
  font-weight: bold;
  line-height: 1.34;
  text-align: center;
  margin-bottom: 40px;

  @media screen and (max-width: 1280px) {
    font-size: 32px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 769px) {
    font-size: 28px;
    margin-bottom: 28px;
  }

  @media only screen and (max-width: 426px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 16px;
  }
`

export const TileContent = styled.p`
  font-size: 1em;
  line-height: 1.5;
  text-align: left;
  margin-top: 40px;

  @media screen and (max-width: 1280px) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 769px) {
    margin-bottom: 8px;
    margin-top: 28px;
  }

  @media only screen and (max-width: 426px) {
    margin-bottom: 8px;
  }
`

export const TileFooter = styled.p`
  font-size: 0.875em;
  line-height: 1.34;
  margin-left: 54px;
  margin-right: 54px;
  text-align: left;
  margin-top: 2em;
  color: #aaa7ac;

  @media screen and (max-width: 1280px) {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 12px;
    margin-top: 1.5em;
  }

  @media screen and (max-width: 769px) {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: 1em;
  }

  @media only screen and (max-width: 426px) {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: 0.75em;
  }
`

export function TileDetails({ header, content, imageUrl }) {
  return (
    <TileBase>
      <TileHeader>{header}</TileHeader>
      {imageUrl && <TileImage imageUrl={imageUrl} />}
      <TileContent dangerouslySetInnerHTML={{ __html: content }} />
    </TileBase>
  )
}
