import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { callApi } from './../../../utils/api'
import { devices, API, fonts, colors } from './../../../utils/constants'

const Form = styled.form`
  display: flex;
  width: auto;
  height: 100%;
  font-family: ${fonts.mainFont};
  font-weight: 400;
  color: ${colors.dark};
  flex-direction: column;
  button {
    height: 30px;
  }

  @media ${devices.tablet} {
    width: 480px;
  }
`

const InputLabel = styled.label`
  font-size: 1.14rem;
  font-weight: 600;
  margin: 0 0 10px 48px;
`

const Input = styled.input`
  margin: 0 48px 28px 48px;
  background: #f6f8f9;
  border: none;
  padding: 15px;
  font-size: 0.9rem;
`

const FormHeader = styled.span`
  margin: 69px auto;
  font-weight: 700;
  font-size: 1.9rem;
`

const BackToLoginButton = styled.span`
  margin: 15px auto 50px auto;
  cursor: pointer;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1rem;
  span {
    text-decoration: underline;
  }
`

const RecoverButton = styled.button`
  background: ${colors.blue};
  margin: 70px auto 20px auto;
  min-width: 60%;
  font-size: 0.9rem;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  border: none;
  height: 47px !important;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const StatusMessage = styled.div`
  margin: 0 48px;
  font-size: 0.8rem;
  font-family: ${fonts.secondaryFont};
  color: ${props => (props.warning ? 'green' : 'red')};
  display: block;
  height: 13.5px;
  transform: translateY(-22px);
`

export class RecoverForm extends Component {
  state = { isPasswordReset: null }

  render() {
    return (
      <Formik
        initialValues={{ email: '' }}
        onSubmit={values => {
          if (this.state.isPasswordReset) {
            this.props.closeModal()
          } else {
            callApi
              .url(`${API}/user/password-recovery/send`)
              .post({ email: values.email })
              .res(() => {
                this.setState({ isPasswordReset: true })
              })
              .catch(e => {
                console.error(e)
                this.setState({ isPasswordReset: false })
              })
          }
        }}>
        {props => {
          const { values, handleChange, handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <FormHeader>Przypomnij hasło</FormHeader>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                required
              />
              <StatusMessage warning={this.state.isPasswordReset}>
                {this.state.isPasswordReset && (
                  <span>
                    Na twój adres email zostaly przesłane dalsze instrukcje na temat resetowania
                    hasła.
                  </span>
                )}
                {this.state.isPasswordReset === false && <span>Brak użytkownika w systemie</span>}
              </StatusMessage>
              <RecoverButton type="submit">
                {this.state.isPasswordReset ? 'Zamknij' : 'Przypomij'}
              </RecoverButton>
              <BackToLoginButton>
                Przypomniałeś sobie hasło?
                <span onClick={() => this.props.backToLoginForm()}> Zaloguj się</span>
              </BackToLoginButton>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
