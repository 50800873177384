import { init } from '@rematch/core'
import { currentUser } from './modules/models/user'
import { userModal } from './modules/models/modal'

const store = init({
  models: {
    currentUser,
    userModal,
  },
})

export default store
