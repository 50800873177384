import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors } from '../../utils/constants'

const MobileTileContent = styled.div`
  display: block;
  max-width: 335px;
  margin: 0 auto;
  border: solid 1px #d0d9f9;
  padding: 15px;
  margin: 25px auto;

  &:hover {
    border: solid 1px transparent;
    -webkit-box-shadow: 0px 0px 33px -9px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 33px -9px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 33px -9px rgba(0, 0, 0, 0.2);
  }

  @media ${devices.tablet} {
    display: none;
  }
`

const InfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  span:first-child {
    padding: ${props => (props.level ? '5px 0' : '0')};
  }
  span:last-child {
    text-align: ${props => (props.school ? 'right' : 'left')};
    background: ${props => (props.level ? '#efd8a1' : '#fff')};
    padding: ${props => (props.level ? '5px 10px' : '0')};
    margin: 0 0 0 5px;
  }
`

const Info = styled.span`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: ${props => (props.title ? '#aaa7ac' : `${colors.dark}`)};
  line-height: ${props => (props.manager ? '2.5' : 'normal')};
`
const ManagerInfo = styled.span`
  display: block;
`

const formatDate = ISOstring => {
  return new Date(ISOstring).toLocaleDateString('en-GB')
}

export const MobileTile = props => (
  <MobileTileContent>
    <InfoLine>
      <Info title="true">Czas trwania </Info>
      <Info>
        od {formatDate(props.course.startDate)} do {formatDate(props.course.endDate)}
      </Info>
    </InfoLine>
    <InfoLine level="true">
      <Info title="true">Poziom</Info>
      <Info>{props.course.levels}</Info>
    </InfoLine>
    {props.course.role && (
      <InfoLine>
        <Info title="true">Rola</Info>
        <Info>{props.course.role}</Info>
      </InfoLine>
    )}
    <InfoLine>
      <Info title="true">Miejsce kursu</Info>
      <Info>
        {props.course.country} ({props.course.city})
      </Info>
    </InfoLine>
    <InfoLine school="true">
      <Info title="true">Szkoła</Info>
      <Info>{props.course.schoolName}</Info>
    </InfoLine>
    {!props.profile && (
      <InfoLine>
        <Info title="true" manager="true">
          Osoba kontaktowa
        </Info>
        <Info>
          <ManagerInfo>{props.course.managerFirstName}</ManagerInfo>
          <ManagerInfo>{props.course.managerLastName}</ManagerInfo>
        </Info>
      </InfoLine>
    )}
  </MobileTileContent>
)
