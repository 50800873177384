import React, { Component } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import LoginForm from './userModal/LoginForm'
import RegisterForm from './userModal/RegisterForm'
import SchoolRegisterFormHigherOrderComponent from './userModal/SchoolRegisterForm'
import UserRegisterFormHigherOrderComponent from './userModal/UserRegisterForm'
import ActiveModal from './userModal/ActiveModal'
import ActiveNewsletterModal from './userModal/ActiveNewsletterModal'
import { ChangePasswordForm } from './userModal/ChangePasswordForm'
import { userModalTypes, devices } from '../utils/constants'
import closeModalIcon from './../images/closeModalIcon.svg'

const CloseModalButton = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  display: block;
  z-index: 101;

  @media ${devices.tablet} {
    display: none;
  }
`

const loginModalStyles = {
  overlay: { zIndex: 1001, background: `rgba(42, 36, 46, 0.8) ` },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    maxWidth: '90%',
    maxHeight: '80vh',
  },
}

Modal.setAppElement('#root')

const RenderModalContent = props => {
  switch (props.modalFormType) {
    case userModalTypes.login:
      return <LoginForm closeModal={props.toggleUserModal} />
    case userModalTypes.register:
      return (
        <RegisterForm
          closeSelf={props.toggleUserModal}
          setUserModalType={modalType => props.setUserModalType(modalType)}
        />
      )
    case userModalTypes.changePass:
      return <ChangePasswordForm closeSelf={props.toggleUserModal} />
    case userModalTypes.school:
      return (
        <SchoolRegisterFormHigherOrderComponent
          closeSelf={props.toggleUserModal}
          registerFormValues={props.registerFormValues}
          setUserModalType={props.setUserModalType}
        />
      )
    case userModalTypes.active:
      return (
        <ActiveModal closeSelf={props.toggleUserModal} setUserModalType={props.setUserModalType} />
      )
    case userModalTypes.newsletter:
      return (
        <ActiveNewsletterModal
          closeSelf={props.toggleUserModal}
          setUserModalType={props.setUserModalType}
        />
      )
    case userModalTypes.basic:
      return (
        <UserRegisterFormHigherOrderComponent
          closeSelf={props.toggleUserModal}
          registerFormValues={props.registerFormValues}
        />
      )
    default:
      return null
  }
}

export class UserModal extends Component {
  state = {
    registerFormValues: null,
  }

  setUserModalType = prevValues => {
    if (!prevValues.userType) {
      this.props.setUserModalType(prevValues)
    } else {
      this.props.setUserModalType(prevValues.userType)
      this.setState({
        registerFormValues: prevValues,
      })
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isUserModalOpen}
        onRequestClose={() => this.props.toggleUserModal(null)}
        style={loginModalStyles}>
        <RenderModalContent
          {...this.props}
          setUserModalType={this.setUserModalType}
          registerFormValues={this.state.registerFormValues}
        />
        <CloseModalButton src={closeModalIcon} onClick={this.props.toggleUserModal} />
      </Modal>
    )
  }
}
