import wretch from 'wretch'
import applyToken from './../modules/middlewares/applyToken'

export const callApi = wretch().content('application/json')

export const callApiFormData = wretch()

export const callApiWithAuth = wretch()
  .middlewares([applyToken()])
  .content('application/json')

export const callApiWithAuthFormData = wretch()
  .middlewares([applyToken()])
  .headers({})
