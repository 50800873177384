import styled from 'styled-components'
import { colors, devices, fonts } from '../utils/constants'

const ReadMore = styled.span`
  grid-area: readMore;
  color: ${colors.dark};
  font-size: 1.1rem;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  margin: 28px 0 28px 20px;
  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 12px;
    background: ${colors.blue};
    position: relative;
    left: -20px;
    top: 12px;
  }

  @media ${devices.tablet} {
    font-size: 1.55rem;
    margin: 28px 0 28px 30px;

    &::before {
      height: 4px;
      width: 24px;
      left: -30px;
      top: 21px;
    }
  }
`

export default ReadMore
