import React from 'react'
import styled from 'styled-components'
import { devices, colors, API } from '../../utils/constants'
import SchoolPhone from './../../images/school-phone.svg'

const School = styled.div`
  width: ${props => (props.additional ? '50%' : '100%')};
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media ${devices.tablet} {
    &:nth-child(odd) {
      justify-content: ${props => (props.additional ? 'flex-start' : 'center')};
    }

    &:nth-child(even) {
      justify-content: ${props => (props.additional ? 'flex-end' : 'center')};
    }
  }

  @media ${devices.laptop} {
    width: 50%;
  }
`
const SchoolDetails = styled.div`
  width: 335px;
  margin: 0 auto;
  border: solid 1px #d0d9f9;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px 0;

  @media ${devices.tablet} {
    width: '100%';
    margin: 0 0 20px 0;
    border: 'none';

    &:hover {
      -webkit-box-shadow: 18px 17px 29px -18px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 18px 17px 29px -18px rgba(0, 0, 0, 0.2);
      box-shadow: 18px 17px 29px -18px rgba(0, 0, 0, 0.2);
    }
  }

  @media ${devices.laptop} {
    margin: unset;
    width: 100%;
    border: none;
  }
`
const Logo = styled.div`
  display: block;
  height: 74px;
  width: 77px;
  min-width: 77px;
  margin: 15px;

  img {
    width: 100%;
    max-height: 100%;
  }

  @media ${devices.tablet} {
    min-width: '146px';
    height: '136px';
  }

  @media ${devices.laptop} {
    min-width: 196px;
    height: 196px;
  }
`
const FirstLine = styled.div`
  display: flex;
`

const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.span`
  margin: 15px 0 12px 0;
  font-size: 1.6rem;

  @media ${devices.laptop} {
    font-size: 1.3rem;
    margin: 25px 0 12px 0;
  }
`

const Type = styled.span`
  font-size: 0.9rem;
  line-height: 1.64;
  font-weight: 600;
  color: ${colors.blue};
`

const AdressMobile = styled.span`
  font-size: 0.94rem;
  font-weight: 600;
  line-height: 1.33;
  color: #838086;
  width: 100%;
  text-align: center;
  max-height: 20px;
  overflow: hidden;
  margin: 0 0 0 10px;

  @media ${devices.laptop} {
    display: none;
  }
`
const AdressDesktop = styled.span`
  font-size: 0.94rem;
  font-weight: 600;
  line-height: 1.33;
  color: #838086;
  width: 100%;
  text-align: left;
  max-height: 20px;
  overflow: hidden;
  margin: 0;
  display: none;

  @media ${devices.laptop} {
    display: block;
  }
`

const PhoneNumber = styled.span`
  border-radius: 30px;
  background-color: ${colors.blue};
  color: #fff;
  min-width: 117px;
  max-width: 200px;
  padding: 0 20px;
  text-overflow: ellipsis;
  height: 33px;
  text-align: center;
  margin: 0 10px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.9;

  img {
    transform: translateY(3px);
    margin: 0 2px 0 0;
  }
`
const Website = styled.a`
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid ${colors.blue};
  color: ${colors.blue};
  min-width: 136px;
  max-width: 200px;
  padding: 0 20px;
  margin: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 33px;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.9;
  text-decoration: none;
  cursor: pointer;
`

const LastLine = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
`

export const SchoolTile = props => (
  <School additional={props.additional}>
    <SchoolDetails tabletMain={props.tabletMain}>
      <FirstLine>
        <Logo tabletMain={props.tabletMain}>
          <img src={`${API}${props.school.imageUrl}`} alt="school" />
        </Logo>
        <NameAndType>
          <Name tabletMain={props.tabletMain}>{props.school.fullName}</Name>
          <Type>{props.school.type === 'pss' ? 'Patronacka' : 'Licencjonowana'}</Type>
          <AdressDesktop>
            {props.school.street}, {props.school.city}
          </AdressDesktop>
        </NameAndType>
      </FirstLine>
      <AdressMobile>
        {props.school.street}, {props.school.city}
      </AdressMobile>
      <LastLine>
        <PhoneNumber>
          <img src={SchoolPhone} alt="phone" />
          {props.school.phone}
        </PhoneNumber>
        <Website href={`https://${props.school.website}`} target="_blank">
          {props.school.website}
        </Website>
      </LastLine>
    </SchoolDetails>
  </School>
)
