import React from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(FilePondPluginImagePreview)

const AddFeaturedImage = props => {
  return (
    <FilePond
      allowMultiple={false}
      allowRevert={true}
      server={{
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          if (!['image/jpeg', 'image/png'].some(type => type === file.type)) {
            error('')
            return null
          }
          progress(true, 0, 100)
          props.uploadFeaturedImage(file)
          progress(true, 100, 100)
          load()
        },
        revert: (undef, load) => {
          props.uploadFeaturedImage(null)
          load()
        },
      }}
      acceptedFileTypes={['image/jpeg', 'image/png']}
      labelIdle={
        'Przeciągnij plik lub <span class="filepond--label-action">Przeglądaj</span> aby dodać główny obrazek w formacie JPEG lub PNG'
      }
      labelFileloading={'Ładuje plik'}
      labelFileLoadError={'Błąd podczas wgrywania pliku'}
      labelFileProcessing={'Wgrywam plik'}
      labelFileProcessingComplete={'Plik wgrany'}
      labelFileProcessingAborted={'Ładowanie pliku przerwane'}
      labelFileProcessingError={'Błąd podczas wgrywania pliku, czy plik ma dozwolony format'}
      labelTapToCancel={'Naciśnij aby przerwać'}
      labelTapToRetry={'Naciśnij aby spróbować ponownie'}
    />
  )
}

export default AddFeaturedImage
