import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { devices, fonts, colors } from '../../utils/constants'

const NavMenu = styled.nav`
  justify-content: center;
  display: none;
  @media ${devices.laptopPlus} {
    display: flex;
  }
`

const NavItem = styled(Link)`
  padding: 8px;
  align-self: center;
  text-decoration: none;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: ${colors.dark};
  font-size: 14px;

  @media ${devices.laptopL} {
    padding: 0 15px;
  }
`
const StyledUl = styled.ul`
  list-style-type: none;
  overflow: hidden;
  justify-content: center;
  display: none;
  @media ${devices.laptop} {
    display: flex;
  }
`

const StyledLi = styled.li`
  float: left;
  align-self: center;
`

const Dropbtn = styled.div`
  display: inline-block;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 8px;
  padding-left: 0px;
  text-decoration: none;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: ${colors.dark};
  font-size: 14px;
  @media ${devices.laptopL} {
    padding-right: 30px;
  }

  &:hover {
    cursor: default;
  }
`

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 2px 10px 0 rgba(78, 114, 255, 0.15);
  background-color: #ffffff;
  z-index: 100;
  border-top: solid 3px #4e72ff;
`

const DropDownLi = styled(StyledLi)`
  display: inline-block;
  align-self: center;

  &:hover ${DropDownContent} {
    display: block;
  }
`

const StyledA = styled(Link)`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: ${colors.dark};
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }

  padding-right: 14px;

  @media ${devices.laptopL} {
    padding-right: 30px;
  }
`

const FirstStyledA = styled(Link)`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: ${colors.dark};
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }

  padding-left: 8px;
  padding-right: 12px;

  @media ${devices.laptopL} {
    padding-right: 30px;
  }
`

const Arrow = styled.i`
  border: solid #cfd3e4;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  position: relative;
  bottom: 2px;
  -webkit-transform: rotate(45deg);
`

const SubA = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: left;
  font-family: ${fonts.mainFont};
  font-weight: normal;
  color: ${colors.dark};
  font-size: 14px;
  width: 160px;
  height: 40px;
  text-align: left;
  padding-left: 16px;
  padding-top: 11px;
  background-color: #ffffff;

  &:hover {
    background-color: #f7f9fc;
    cursor: pointer;
  }
`

export const DesktopNavigation = (props) => (
  <NavMenu>
    <StyledUl>
      <StyledLi>
        <FirstStyledA to="/">Home</FirstStyledA>
      </StyledLi>
      <StyledLi>
        <StyledA to="/news">Aktualności</StyledA>
      </StyledLi>
      <StyledLi>
        <StyledA to="/schools">Szkoły</StyledA>
      </StyledLi>
      <StyledLi>
        <StyledA to="/courses">Kursy</StyledA>
      </StyledLi>
      <DropDownLi>
        <Dropbtn>
          Instruktorzy <Arrow />
        </Dropbtn>
        <DropDownContent>
          {' '}
          <SubA to="/for-instructors">Dla instruktorów</SubA>
          <SubA to="/instructors">Lista instruktorów</SubA>
          <SubA to="/job-offers">Oferty pracy</SubA>
          <SubA to="/discounts">Zniżki</SubA>
        </DropDownContent>
      </DropDownLi>
      <StyledLi>
        <StyledA to="/for-parents">Dla rodziców</StyledA>
      </StyledLi>
      <StyledLi>
        <StyledA to="/travel-agency">Biura podróży</StyledA>
      </StyledLi>
      <StyledLi>
        <StyledA to="/products">Produkty</StyledA>
      </StyledLi>
    </StyledUl>
  </NavMenu>
)
