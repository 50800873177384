import React, { useEffect, useCallback, useState, useRef } from 'react'
import styled from 'styled-components'
import wretch from 'wretch'
import { getCurrentUserToken } from '../utils/auth'
import { API } from '../utils/constants'
import { Question } from './exam/Question'
import ShortSummary from '../pages/exam/ShortSummary'
import Start from '../pages/exam/Start'
import ResultList from '../pages/exam/ResultList'
import MediaQuery from 'react-responsive'
import ShortSummaryMobile from './exam/ShortSummaryMobile'
import StartMobile from './exam/StartMobile'
import { QuestionMobile } from './exam/QuestionMobile'
import ResultListMobile from './exam/ResultListMobile'

const examId = new URL(window.location).searchParams.get('examId')

export default function Exam() {
  const [examInfo, setExamInfo] = useState(null)
  const [examStarted, setExamStarted] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [res, setRes] = useState({ passed: false, percentage: 0 })
  const [restultDetails, setResultDetails] = useState({ checked: false, result: null })

  const answer = useCallback(
    async (questionId, answerId) => {
      if (!examInfo) return
      let finalQuestion
      currentQuestion === examInfo.questions.length - 1
        ? (finalQuestion = true)
        : (finalQuestion = false)

      const isAnswered = await wretch()
        .url(`${API}/exam/answer`)
        .auth(`Bearer ${getCurrentUserToken()}`)
        .post({
          courseId: examInfo.courseId,
          questionId,
          answerId,
          finalQuestion: finalQuestion,
        })
        .json()

      if (finalQuestion) {
        const percentage = isAnswered.result.percentage
        let passed = true

        isAnswered.result.categories.forEach((category) => {
          if (!category.isCategoryPassed) passed = false
        })
        setRes({ passed: passed, percentage: percentage })
        setResultDetails({ result: isAnswered, checked: false })
      }
    },
    [examInfo, currentQuestion]
  )

  const checkIfExamWasRefreshed = useCallback(async (examInfo) => {
    if (!examInfo) return
    const firstQuestionWithoutAnswer = examInfo.questions[examInfo.questions.length - 1]
    if (examInfo.approachDate) {
      await wretch()
        .url(`${API}/exam/answer`)
        .auth(`Bearer ${getCurrentUserToken()}`)
        .post({
          courseId: examInfo.courseId,
          questionId: firstQuestionWithoutAnswer._id,
          answerId: null,
          finalQuestion: true,
        })
        .json()
        .then(() => {
          window.location.replace('/')
        })
        .catch((er) => {
          console.warn(er)
          window.location.replace('/')
        })
    }
  }, [])

  const getExam = useCallback(async () => {
    try {
      const examInfo = await wretch()
        .url(`${API}/exam/${examId}`)
        .auth(`Bearer ${getCurrentUserToken()}`)
        .get()
        .json()

      await checkIfExamWasRefreshed(examInfo)
      setExamInfo(examInfo)
    } catch (err) {
      console.log(err)
    }
  }, [checkIfExamWasRefreshed])

  const startExam = useCallback(async () => {
    const courseId = examInfo.courseId

    const startExam = await wretch()
      .url(`${API}/exam/start`)
      .auth(`Bearer ${getCurrentUserToken()}`)
      .post({
        courseId,
      })
      .json()

    startExam.questions.map((question) => {
      for (let i = question.answers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = question.answers[i]
        question.answers[i] = question.answers[j]
        question.answers[j] = temp
      }
    })

    setExamInfo((prev) => ({ ...prev, questions: startExam.questions }))
    setExamStarted(true)
  }, [examInfo])

  useEffect(() => {
    getExam()
  }, [getExam])

  useEffect(() => {
    const beforeUnload = (ev) => {
      if (examStarted) {
        ev.preventDefault()
        ev.returnValue = 'Jesteś pewien, ze chcesz wyjść?'
        return 'Jesteś pewien, ze chcesz wyjść?'
      }
    }

    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [examStarted, restultDetails.checked])

  const nextQuestion = useCallback(async () => {
    setCurrentQuestion((prev) => prev + 1)
  })

  if (!examInfo) return <p>Loading...</p>

  if (
    examInfo.questions.length > 0 &&
    currentQuestion === examInfo.questions.length &&
    !restultDetails.checked
  )
    return (
      <>
        <MediaQuery maxWidth={767}>
          <ShortSummaryMobile
            result={res}
            level={examInfo.courseLevel}
            onCheck={() => {
              setResultDetails((prev) => ({ checked: true, result: prev.result }))
            }}
            onReturn={() => window.open('/')}
          />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <ShortSummary
            result={res}
            level={examInfo.courseLevel}
            onCheck={() => {
              setResultDetails((prev) => ({ checked: true, result: prev.result }))
            }}
            onReturn={() => window.open('/')}
          />
        </MediaQuery>
      </>
    )

  return (
    <>
      {!restultDetails.checked && !examStarted && (
        <>
          <MediaQuery maxWidth={767}>
            <StartMobile startExam={() => startExam()} level={examInfo.courseLevel} />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <Start startExam={() => startExam()} level={examInfo.courseLevel} />
          </MediaQuery>
        </>
      )}
      {!restultDetails.checked && examStarted && (
        <>
          <Question
            content={examInfo.questions[currentQuestion].content}
            answers={examInfo.questions[currentQuestion].answers}
            category={examInfo.questions[currentQuestion].category}
            goToNext={nextQuestion}
            answer={answer}
            level={examInfo.courseLevel}
            totalQuestions={examInfo.questions.length}
            currentQuestionNumber={currentQuestion + 1}
            _id={examInfo.questions[currentQuestion]._id}
            media={examInfo.questions[currentQuestion].media}
          />
        </>
      )}
      {restultDetails.checked && (
        <>
          <MediaQuery maxWidth={767}>
            <ResultListMobile
              result={restultDetails.result}
              shortResult={res}
              level={examInfo.courseLevel}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <ResultList
              result={restultDetails.result}
              shortResult={res}
              level={examInfo.courseLevel}
            />
          </MediaQuery>
        </>
      )}
    </>
  )
}
