import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors, API } from './../../utils/constants'

const DesktopTileContent = styled.tr`
  display: none;
  margin: 0 auto;
  border: solid 1px #d0d9f9;

  &:hover {
    border: solid 1px transparent;
    -webkit-box-shadow: 0px 0px 33px -9px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 33px -9px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 33px -9px rgba(0, 0, 0, 0.2);
  }

  @media ${devices.tablet} {
    display: table-row;
  }
`

const TableCell = styled.td`
  font-family: ${props => (props.level ? `${fonts.mainFont}` : `${fonts.secondaryFont}`)};
  font-size: 0.8rem;
  font-weight: 600;
  color: ${props => (props.school ? `${colors.blue}` : `${colors.dark}`)};
  padding: 10px;
  text-align: center !important;
  span {
    display: block;
    background: ${props => (props.level ? '#efd8a1' : '#fff')};
    padding: ${props => (props.level ? '10px 30px' : '0')};
    white-space: normal;
  }

  @media ${devices.laptop} {
    span {
      font-size: ${props => (props.dates && !props.profile ? '1.1rem' : '0.8rem')};
    }
  }
`

const TableCellContact = styled.td`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.dark};
  padding: 29px 0;
  text-align: center;

  div {
    display: inline-block;
    span {
      width: 100%;
      display: block;
      text-align: left;
    }
  }
`

const ContactImg = styled.div`
  display: block;
  width: 56px;
  height: 56px;
  margin: 0 10px 0 0;

  img {
    width: 100%;
    max-height: 56px;
  }
`

const formatDate = ISOstring => {
  return new Date(ISOstring).toLocaleDateString('en-GB')
}

export const DesktopTile = props => (
  <DesktopTileContent>
    <TableCell dates="true" profile={props.profile}>
      <span>
        {formatDate(props.course.startDate)} - {formatDate(props.course.endDate)}
      </span>
    </TableCell>
    <TableCell level="true">
      <span>{props.course.levels}</span>
    </TableCell>
    {props.course.role && (
      <TableCell>
        <span>{props.course.role}</span>
      </TableCell>
    )}
    <TableCell>
      {props.course.country} ({props.course.city})
    </TableCell>
    <TableCell school="true"> {props.course.schoolName} </TableCell>

    {!props.profile && (
      <TableCellContact contact="true">
        <div>
          {props.course.managerAvatar && (
            <ContactImg>
              <img src={`${API}${props.course.managerAvatar}`} alt="" />
            </ContactImg>
          )}
          <div>
            <span>{props.course.managerFirstName}</span>
            <span>{props.course.managerLastName}</span>
          </div>
        </div>
      </TableCellContact>
    )}
  </DesktopTileContent>
)
