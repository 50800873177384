import React, { Component } from 'react'
import { API, colors } from '../../utils/constants'
import { callApi } from '../../utils/api'
import qs from 'querystringify'

export default class ActiveModal extends Component {
  state = { message: 'Ładowanie' }
  componentDidMount() {
    if (window.location.search) {
      const query = qs.parse(window.location.search)
      if (query.activeToken) {
        callApi
          .url(`${API}/user/active`)
          .post({ token: query.activeToken })
          .res(res => {
            this.setState({
              message: 'Konto aktywne',
            })
          })
          .catch(e => {
            this.setState({
              message: 'Problem z aktywacja konta',
            })
            console.error(e)
          })
      }
    }
  }

  render() {
    return (
      <div
        style={{
          width: '300px',
          textAlign: 'center',
          borderRadius: '15px',
          verticalAlign: 'center',
          margin: '10px 0',
        }}>
        <h1>{this.state.message}</h1>
        <button
          style={{
            width: '60px',
            border: 'none',
            background: `${colors.blue}`,
            minWidth: '60%',
            fontSize: '0.9rem',
            fontWeight: '600',
            color: '#fff',
            borderRadius: '30px',
            height: '50px',
            cursor: 'pointer',
            marginBottom: '10px',
          }}
          onClick={this.props.closeSelf}>
          Zamknij
        </button>
      </div>
    )
  }
}
