import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { USER_ROLES, devices, colors, API } from '../../utils/constants'

const InstructorWrapper = styled(Link)`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-decoration: none;

  @media ${devices.tablet} {
    width: 33.33%;
  }

  @media ${devices.desktop} {
    width: 25%;
  }
`

const DivInstructorWrapper = InstructorWrapper.withComponent('div')

const Instructor = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: solid 0.9px #d0d9f9;
  width: 160px;
  &:hover {
    -webkit-box-shadow: 18px 17px 29px -18px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 18px 17px 29px -18px rgba(0, 0, 0, 0.2);
    box-shadow: 18px 17px 29px -18px rgba(0, 0, 0, 0.2);
  }

  &:nth-child(odd) {
    margin: 20px 5px 0 0;
  }

  &:nth-child(even) {
    margin: 20px 0 0 5px;
  }

  @media ${devices.tablet} {
    width: 196px;
  }

  @media ${devices.laptop} {
    border: none;
  }
`

const PhotoWrapper = styled.div`
  width: 100%;
  height: 160px;

  img {
    width: 100%;
    max-height: 100%;
  }

  @media ${devices.tablet} {
    height: 196px;
  }
`

const Name = styled.span`
  width: 100%;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 20px 0 5px 10px;
  color: ${colors.dark};
`

const City = styled.span`
  width: 100%;
  margin: 5px 0 20px 10px;
  color: #aaa7ac;
  font-weight: 600;
  font-size: 0.9rem;
`

export const InstructorTile = props => {
  return props.currentUser.role === USER_ROLES.SCHOOL ||
    props.currentUser.role === USER_ROLES.ADMIN ? (
    <InstructorWrapper
      to={`/instructors?instId=${props.instructor._id}`}
      additional={props.additionalTablet}>
      <Instructor>
        <PhotoWrapper>
          <img src={`${API}${props.instructor.imageUrl}`} alt="instructor" />
        </PhotoWrapper>
        <Name>
          {props.instructor.firstName} {props.instructor.lastName}
        </Name>
        <City>{props.instructor.city}</City>
      </Instructor>
    </InstructorWrapper>
  ) : (
    <DivInstructorWrapper additional={props.additionalTablet}>
      <Instructor>
        <PhotoWrapper>
          <img src={`${API}${props.instructor.imageUrl}`} alt="instructor" />
        </PhotoWrapper>
        <Name>
          {props.instructor.firstName} {props.instructor.lastName}
        </Name>
        <City>{props.instructor.city}</City>
      </Instructor>
    </DivInstructorWrapper>
  )
}
