import * as React from 'react'
import styled from 'styled-components'
import { Grid } from './grid'
import { Tile } from './tile'
import moment from 'moment'
import 'moment/locale/pl'
import { TileDetails } from './tileDetails'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 90%;
  padding: 0 1rem 0 1rem;
  margin: 0 auto;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Header = styled.p`
  font-size: 3em;
  font-weight: 500;
  padding: 1em 0 2em 0em;
`

export function ContentDetailsPage({ header, content, postDate, imageUrl }) {
  return (
    <Container>
      <TileDetails header={header} content={content} postDate={new Date()} imageUrl={imageUrl} />
    </Container>
  )
}
