import React from 'react'
import { usePaginatedQuery, useQuery } from 'react-query'
import { Redirect, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ContentDetailsPage } from '../new-components/contentDetailsPage'
import { ContentPageAPI } from '../new-components/contentPageAPI'
import { Tile } from '../new-components/tile'
import { API, colors, devices, fonts } from '../utils/constants'
import Container from '../new-components/container'
import ReadMore from '../new-components/readMore'
import { ReadMoreGrid, HideOnMobile } from '../new-components/readMoreGrid'
import { More } from '../new-components/more'

export default function TravelAgency() {
  const [allData, setAllData] = React.useState(null)
  const [page, setPage] = React.useState(1)

  const fetchContents = React.useCallback(async (key, page = 1) => {
    const req = await fetch(
      `${API}/content?quantity=3&column=creationDate&descending=true&page=${page}&contentType=TRAVEL_AGENCY`
    )
    return await req.json()
  }, [])

  const { status, resolvedData, latestData, error, refetch } = usePaginatedQuery(
    ['travel-agency', page],
    fetchContents,
    {}
  )

  React.useEffect(() => {
    if (resolvedData && resolvedData.data) {
      let data = allData || []
      data[page] = resolvedData.data
      setAllData(data)
    }
    refetch()
  }, [resolvedData, allData, refetch, page])

  if (status === 'loading' || !allData) return <p>Loading</p>

  if (status.error) return <p>Error: {error.message}</p>
  return (
    <>
      <ContentPageAPI
        header="Biura podróży"
        contents={allData.flat(1)}
        titleKey="title"
        postDateKey="creationDate"
        imageKey="backgroundUrl"
        showText="false"
        urlBase="travel-agency"
        showMore={latestData && latestData.totalItems !== page && latestData.totalItems !== 0}
        more={() => {
          return (
            <More
              onClick={() =>
                setPage((old) => (!latestData || latestData.totalItems === old ? old : old + 1))
              }>
              Pokaż więcej
            </More>
          )
        }}
      />
    </>
  )
}

async function getContentById(key, id) {
  const res = await fetch(`${API}/content/${id}`)
  let data = null
  if (res.ok) {
    data = await res.json()
  }
  return data
}

async function fetchReadMore(key) {
  const req = await fetch(
    `${API}/content?quantity=4&column=creationDate&descending=true&contentType=TRAVEL_AGENCY`
  )
  return await req.json()
}

function TravelAgencyDetailsFn({
  match: {
    params: { id },
  },
}) {
  const { status, data, error, isFetching } = useQuery(id && ['contents', id], getContentById)
  const { data: readMoreList } = useQuery('readMoreContents', fetchReadMore)
  if (status === 'loading') return <p>Loading</p>
  if (status.error) return <p>Error: {error.message}</p>
  if (!data) return <Redirect to="/travel-agency" />
  return (
    <>
      <ContentDetailsPage
        header={data.title}
        content={data.content}
        postDate={data.creationDate}
        imageUrl={data.backgroundUrl}
      />
      <Container>
        <ReadMore>Zobacz również</ReadMore>
        <ReadMoreGrid>
          {readMoreList &&
            readMoreList.data &&
            readMoreList.data.length &&
            readMoreList.data
              .filter((n) => n._id !== data._id)
              .slice(0, 3)
              .map((content, index, arr) => {
                const TravelAgencyTile = (
                  <Tile
                    disableZigzag
                    to={`/travel-agency/${content._id}`}
                    header={content.title}
                    imageUrl={content.backgroundUrl}
                    postDate={content.creationDate}
                  />
                )
                if (index === arr.length - 1) return <HideOnMobile>{TravelAgencyTile}</HideOnMobile>
                return TravelAgencyTile
              })}
        </ReadMoreGrid>
      </Container>
    </>
  )
}

export const TravelAgencyDetails = withRouter(TravelAgencyDetailsFn)
