import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { callApi } from '../utils/api'
import { API, devices, fonts, colors } from '../utils/constants'
import { ClipLoader } from 'react-spinners'

const MaterialsWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: block;
  width: 80%;
  margin: 0 10%;
`
const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;

  div {
    font-size: 1.5rem;
    line-height: 2;
    font-weight: 700;
    margin: 0;

    @media ${devices.tablet} {
      font-size: 2rem;
      line-height: normal;
    }

    @media ${devices.laptop} {
      margin: 0 20px;
    }

    @media ${devices.desktop} {
      font-size: 3rem;
      line-height: 1.52;
    }
  }
`

const MaterialsList = styled.ul`
    margin: 20px 20px 40px;
    list-style-type: disc;
    color: #4e72ff;

`

const Material = styled.li`
    margin: 10px 0;
    a {
        color: #4e72ff;
        font-size: 1.5rem;
        padding: 10px 0;
        text-decoration: none;
    }

`

class Materials extends Component {
  state = {
    windowWidth: null,
    materials: [],
    loader: true,
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    this.getMaterials()
  }

  getMaterials = (search = '') => {
    callApi
      .url(`${API}/materials/website`)
      .get()
      .json(res => {
        this.setState({
            materials: res,
            loader: false,
        })
      })
      .catch(e => {
        this.setState({
            loader: false,
        })
        console.error(e)
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  render() {
    return (
      <MaterialsWrapper>
        <Header>
            {this.state.materials.length === 0 ? <div style={{textAlign: "center", margin: "10px 0 40px"}}>Brak materiałów</div> : <div>Lista materiałów</div>}
        </Header>
        {this.state.loader ? (
        <div style={{ margin: '0 auto' }}>
            <ClipLoader sizeUnit={'px'} size={45} color={`${colors.blue}`} />
        </div>
        ) : (
        this.state.materials.length !== 0 && (
        <Fragment>
            <MaterialsList>
                {this.state.materials.map((material, index) => {
                    return <Material key={index}>
                        <a target="_blank" href={`${API}${material.fileUrl}/${material.fileName}`}>{material.name}</a> 
                    </Material>
                })}
            </MaterialsList>
        </Fragment>
        )
        )}
      </MaterialsWrapper>
    )
  }
}

export default Materials
