import React from 'react'
import { usePaginatedQuery } from 'react-query'
import styled from 'styled-components'
import Container from './discounts/container'
import { More } from '../new-components/more'
import { API } from '../utils/constants'
import { DiscountTile } from './discounts/tile'
import { Grid } from './discounts/grid'

const Header = styled.p`
  font-size: 3em;
  font-weight: 500;
  padding: 1em 0 2em 0em;
`
const Error = styled.p`
  font-size: 2em;
  font-weight: 500;
  padding: 1em 0 2em 0em;
  text-align: center;
`

function DiscountsPage({
  header,
  contents,
  titleKey,
  imageKey,
  showText,
  textKey,
  websiteKey,
  phoneKey,
  showMore,
  more,
}) {
  return (
    <Container>
      <Header>{header}</Header>
      {(!contents || contents.length === 0) && <Error>Brak zniżek</Error>}
      <Grid>
        {contents.map((content) => (
          <DiscountTile
            key={content._id}
            header={content[titleKey]}
            imageUrl={content[imageKey]}
            content={showText ? content[textKey] : undefined}
            phoneNumber={content[phoneKey]}
            website={content[websiteKey]}
          />
        ))}
      </Grid>
      {showMore && more()}
    </Container>
  )
}

const fetchDiscounts = async (key, page = 1) => {
  const req = await fetch(`${API}/discount?quantity=2&column=addDate&descending=true&page=${page}`)
  return await req.json()
}

export default function Discounts() {
  const [allData, setAllData] = React.useState(null)
  const [page, setPage] = React.useState(1)

  const { status, latestData, error, refetch } = usePaginatedQuery(
    ['discounts', page],
    fetchDiscounts,
    { cacheTime: 0, refetchOnMount: false, refetchOnWindowFocus: false }
  )

  React.useEffect(() => {
    if (latestData && latestData.data) {
      let data = allData || []
      data[page] = latestData.data
      setAllData(data)
    }
    refetch()
  }, [page, allData, latestData, refetch])

  if (status === 'loading' || !allData) return <p>Loading</p>

  if (status.error) return <p>Error: {error.message}</p>

  return (
    <>
      <DiscountsPage
        header="Zniżki"
        contents={allData.flat(1)}
        titleKey="header"
        postDateKey="addDate"
        imageKey="imageUrl"
        showText="true"
        phoneKey="phoneNumber"
        websiteKey="websiteUrl"
        textKey="content"
        showMore={latestData && latestData.totalItems !== page && latestData.totalItems !== 0}
        more={() => {
          return (
            <More
              onClick={async () => {
                setPage((old) => (!latestData || latestData.totalItems === old ? old : old + 1))
              }}>
              Pokaż więcej
            </More>
          )
        }}
      />
    </>
  )
}
