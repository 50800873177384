import React, { Component } from 'react'
import styled from 'styled-components'
import { callApi } from '../../utils/api'
import { API, devices, fonts, colors } from '../../utils/constants'
import bookletDots from './../../images/bookletDots.svg'

const BookletStyled = styled.section`
  grid-area: booklet;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.mainFont};
  background: #ffffff url(${`${API}/booklet/homeimg`}) no-repeat right 50px;
  background-size: contain;
  margin-top: 100px;
  position: relative;

  @media ${devices.tablet} {
    background: #ffffff url(${`${API}/booklet/homeimg`}) no-repeat center 50px;
    background-size: contain;
    margin-bottom: 0;
  }

  @media ${devices.laptop} {
    background: #ffffff url(${`${API}/booklet/homeimg`}) no-repeat left 100px;
    margin-bottom: 0;
    background-size: 60%;
    min-height: 50vw;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: none;
  margin: 0;

  @media ${devices.tablet} {
    align-self: flex-end;
  }

  @media ${devices.desktop} {
    margin-right: 10%;
  }
`

const SectionTitleMobile = styled.span`
  font-size: 1.1rem;
  color: ${colors.dark};
  margin-left: 34px;
  align-self: flex-start;
  font-weight: 600;

  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 12px;
    background: ${colors.blue};
    position: relative;
    left: -20px;
    top: 12px;
  }

  @media ${devices.tablet} {
    font-size: 1.55rem;
    &::before {
      height: 2px;
      width: 24px;
      left: -30px;
      top: 17px;
    }
  }

  @media ${devices.laptop} {
    display: none;
  }
`

const SectionTitleDesktop = styled.span`
  font-size: 1.55rem;
  color: ${colors.dark};
  margin-left: 84px;
  align-self: flex-start;
  font-weight: 600;
  display: none;

  &::before {
    display: block;
    content: '';

    background: ${colors.blue};
    position: relative;
    height: 2px;
    width: 24px;
    left: -30px;
    top: 17px;
  }

  @media ${devices.laptop} {
    display: block;
  }
`

const SectionHeader = styled.h2`
  font-weight: 600;
  margin-left: 0;
  font-size: 1.3em;
  line-height: 1.52;
  position: relative;

  @media ${devices.tablet} {
    margin-left: 0;
    font-size: 3.55rem;
    line-height: 1.52;
    position: relative;
    top: 450px;
    max-width: 450px;
  }

  @media ${devices.laptop} {
    margin-left: 50px;
    font-size: 3.8rem;
    line-height: 1.25;
    position: static;
    max-width: 650px;
  }

  @media ${devices.desktop} {
    font-size: 4.55rem;
    line-height: 1.33;
  }
`

const BookletLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.dark};
  border-radius: 8px;
  border: solid 1px #e4eaff;
  background: #fff;
  margin: 6px 0;
  height: 80px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  padding: 0 10px;

  @media ${devices.tablet} {
    font-weight: 400;
    font-size: 1.1rem;
    width: 225px;
    margin: 16px;
  }

  @media ${devices.laptop} {
    font-weight: 600;
    font-size: 1.5rem;
    width: 480px;
    height: 134px;
  }
`

const ReadMore = styled.a`
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  position: relative;
  margin: 0 0 30px 0;
  &::before {
    display: block;
    content: '';
    height: 10px;
    background: ${colors.blue};
    position: absolute;
    top: 47px;
    width: 133px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &:visited {
    color: ${colors.dark};
  }

  @media ${devices.tablet} {
    width: 100%;
    bottom: -50px;
    top: unset;

    &::before {
      width: 133px;
    }
  }

  @media ${devices.laptop} {
    width: 40%;
    bottom: 100px;
    top: 50vw;
    position: absolute;

    &::before {
      width: 229px;
    }
  }

  @media ${devices.laptopL} {
    width: 60%;
  }
`

const StyledDots = styled.img`
  display: none;
  position: absolute;

  @media ${devices.laptop} {
    display: block;
    top: 10%;
    right: 0;
  }
`

const BookletLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60vw;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ${devices.tablet} {
    flex-direction: row;
    margin-top: 390px;
  }

  @media ${devices.laptop} {
    flex-direction: column;
    margin-top: 10px;
  }
`

const BookletLinks = props => {
  return props.bookletLinks.map(l => (
    <BookletLink key={l._id} href={`${API}/booklet/file#page=${l.page}`} target="_blank">
      {l.title}
    </BookletLink>
  ))
}
export class Booklet extends Component {
  state = {
    bookletLinks: [],
  }

  componentDidMount = () => {
    callApi
      .url(`${API}/booklet`)
      .get()
      .json(res => {
        this.setState({ bookletLinks: res })
      })
  }

  render() {
    return (
      <BookletStyled>
        <SectionTitleMobile>Informator SITS</SectionTitleMobile>
        <ContentWrapper>
          <SectionTitleDesktop>Informator SITS</SectionTitleDesktop>
          <SectionHeader>Znajdź istotne informacje</SectionHeader>
          <BookletLinksWrapper>
            <BookletLinks bookletLinks={this.state.bookletLinks} />
          </BookletLinksWrapper>
        </ContentWrapper>
        <StyledDots src={bookletDots} alt="dots" />
        <ReadMore href={`${API}/booklet/file`} target="_blank">
          Czytaj Informator
        </ReadMore>
      </BookletStyled>
    )
  }
}
