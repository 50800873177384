import React from 'react'
import styled, { css } from 'styled-components'
import { API, colors } from '../../utils/constants'
import { ButtonLink } from '../../new-components/button'

const zigzag = css`
  @media screen and (min-width: 970px) {
    &:nth-child(1) {
      margin-bottom: 7.5em;
    }
    &:nth-child(2) {
      margin-top: 7.5em;
    }
    &:nth-child(2n + 3) {
      margin-top: -7.5em;
      margin-bottom: 7.5em;
    }
  }
`

export const TileBase = styled.div`
  box-shadow: 2px 6px 30px 0 rgba(78, 114, 255, 0.2);
  padding: 2em;
  text-decoration: none;
  color: ${colors.dark};
  @media only screen and (max-width: 1366px) {
    padding: 1em;
  }

  @media only screen and (max-width: 970px) {
    padding: 8px;
    margin-bottom: 3em;
  }

  @media only screen and (max-width: 600px) {
    padding: 8px;
    margin-bottom: 1em;
  }

  ${props => !props.disableZigzag && zigzag}
`

export const TileImage = styled.div`
  background-image: ${props =>
    /https?:\/\//i.test(props.imageUrl)
      ? `url("${props.imageUrl}")`
      : `url("${API}${props.imageUrl}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 32px;
  width: 100%;
  height: 120px;

  @media only screen and (max-width: 769px) {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 375px) {
    margin-bottom: 16px;
  }
`

export const TileHeader = styled.p`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.34;
  margin-left: ${props => (props.centered ? '0' : '54px')};
  margin-right: ${props => (props.centered ? '0' : '54px')};
  text-align: ${props => (props.centered ? 'center' : 'left')};

  @media screen and (max-width: 1280px) {
    font-size: 20px;
    margin-left: ${props => (props.centered ? '0' : '32px')};
    margin-right: ${props => (props.centered ? '0' : '54px')};
    margin-bottom: 16px;
  }

  @media screen and (max-width: 769px) {
    font-size: 18px;
    margin-left: ${props => (props.centered ? '0' : '8px')};
    margin-right: ${props => (props.centered ? '0' : '8px')};

    margin-bottom: 8px;
  }

  @media only screen and (max-width: 426px) {
    font-size: 16px;
    margin-left: ${props => (props.centered ? '0' : '8px')};
    margin-right: ${props => (props.centered ? '0' : '8px')};
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 14px;
    margin-left: ${props => (props.centered ? '0' : '8px')};
    margin-right: ${props => (props.centered ? '0' : '8px')};
    margin-bottom: 8px;
  }
`

export const TileContent = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-left: 54px;
  margin-right: 54px;
  text-align: left;

  @media screen and (max-width: 1280px) {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 12px;
  }

  @media screen and (max-width: 769px) {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 426px) {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`

export const TileFooter = styled.p`
  margin-left: 54px;
  margin-right: 54px;
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 12px;
    margin-top: 1.5em;
  }

  @media screen and (max-width: 769px) {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: 1em;
  }

  @media only screen and (max-width: 426px) {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > button:last-child {
      margin-top: 12px;
    }
  }
`

export function DiscountTile({
  header,
  content,
  website,
  phoneNumber,
  imageUrl,
  to,
  className,
  disableZigzag = false,
}) {
  if (website && !/^https?:\/\//i.test(website)) {
    website = 'http://' + website
  }

  return (
    <TileBase className={className} to={to || '/'} disableZigzag={disableZigzag}>
      <TileImage imageUrl={imageUrl} />
      <TileHeader centered>{header}</TileHeader>
      <TileContent>{content}</TileContent>
      <TileFooter>
        {phoneNumber && (
          <ButtonLink
            to={{ pathname: `tel:${phoneNumber}` }}
            target="_blank"
            rel="noopener"
            textColor="#fff"
            bgColor={colors.blue}
            borderColor={colors.blue}
            width="140px">
            {phoneNumber}
          </ButtonLink>
        )}
        {website && (
          <ButtonLink
            to={{ pathname: website }}
            target="_blank"
            rel="noopener"
            textColor={colors.blue}
            bgColor="#fff"
            borderColor={colors.blue}
            width="140px">
            {(website || '').replace(/https?:\/\//, '')}
          </ButtonLink>
        )}
      </TileFooter>
    </TileBase>
  )
}
