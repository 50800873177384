import React, { Component } from 'react'
import styled from 'styled-components'
import { callApi } from '../../utils/api'
import { API, devices, fonts, colors } from '../../utils/constants'
import sponsorsBackDots from './../../images/sponsorsBackDots.svg'

const SponsorsStyled = styled.section`
  grid-area: sponsors;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  position: relative;
  font-family: ${fonts.mainFont};

  @media ${devices.tablet} {
    margin-top: 200px;
  }

  @media ${devices.laptop} {
    margin-top: 100px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`

const SectionTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 34px;
  align-self: flex-start;

  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 12px;
    background: ${colors.blue};
    position: relative;
    left: -20px;
    top: 12px;
  }

  @media ${devices.tablet} {
    font-size: 1.55rem;
    &::before {
      height: 2px;
      width: 24px;
      left: -30px;
      top: 17px;
    }
  }
`
const SectionHeader = styled.h2`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.33;
  margin: 40px 0 0 0;
  max-width: 60%;

  @media ${devices.tablet} {
    margin: 20px 0;
    font-size: 3.2rem;
    line-height: 1.52;
    max-width: 60%;
  }

  @media ${devices.laptop} {
    font-size: 3.8rem;
    line-height: 1.25;
    margin: 0 50px 50px 50px;
  }

  @media ${devices.desktop} {
    font-size: 4.55rem;
    line-height: 1.33;
  }
`

const ImageWrapper = styled.a`
  text-align: center;
  width: 90%;
  margin: 30px auto;

  img {
    width: 160px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @media ${devices.tablet} {
    width: 20%;
    margin: 30px;
  }

  @media ${devices.laptop} {
    width: 160px;
    margin: 20px 0;
  }
`

const SponsorsList = props => {
  if (!props.sponsorsList[0]) return null
  return props.sponsorsList.map(s => <SponsorLogo sponsorLogoUrl={s} key={s._id} />)
}

const SponsorLogo = props => {
  return (
    <ImageWrapper href={props.sponsorLogoUrl.websiteUrl}>
      <img src={`${API}${props.sponsorLogoUrl.logoUrl}`} alt="sponsor" />
    </ImageWrapper>
  )
}

const SponsorsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  flex-wrap: wrap;

  @media ${devices.tablet} {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${devices.laptop} {
    margin-top: 0;
    margin-left: 4%;
    flex-grow: 1;
  }
`

const StyledDots = styled.div`
  position: absolute;
  top: 40px;
  z-index: -1;
  width: 180px;
  height: 140px;
  right: -20px;
  overflow: hidden;

  @media ${devices.tablet} {
    width: 320px;
    height: 220px;
  }

  @media ${devices.laptop} {
    top: 40px;
    right: unset;
    left: 25%;
    width: 388px;
    height: 388px;
  }

  @media ${devices.laptopL} {
    left: 30%;
  }
`

export class Sponsors extends Component {
  state = { sponsorsList: [] }

  componentDidMount = () => {
    callApi
      .url(`${API}/sponsor?quantity=6`)
      .get()
      .json(res => {
        this.setState({ sponsorsList: res.data })
      })
      .catch(e => console.error(e))
  }

  render() {
    return (
      <SponsorsStyled>
        <SectionTitle>Sponsorzy</SectionTitle>
        <ContentWrapper>
          <SectionHeader>Współpracujemy z najlepszymi</SectionHeader>
          <SponsorsListWrapper>
            <SponsorsList sponsorsList={this.state.sponsorsList} />
          </SponsorsListWrapper>
        </ContentWrapper>
        <StyledDots>
          <img src={sponsorsBackDots} alt="coursesDots" />
        </StyledDots>
      </SponsorsStyled>
    )
  }
}
