import React from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'moment/locale/pl'
import moment from 'moment'
import { getCurrentUserToken } from './../../utils/auth'
import CreatableSelect from 'react-select/lib/Creatable'
import { Formik } from 'formik'
import { devices, fonts, colors } from './../../utils/constants'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

registerPlugin(FilePondPluginImagePreview)

const PersonalInfoStyled = styled.form`
  grid-area: content;
  display: flex;
  flex-wrap: wrap;
`
const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: #f6f8f9;
  border: none;
  margin: 2px 0;
  padding: 12px;
  font-family: ${fonts.secondaryFont};
  font-size: 0.9rem;
  color: #aaa7ac;
  border-radius: 5.6px;
  border-right: ${props => (props.borderRight ? '10px solid #fff' : 'none')};

  @media ${devices.tablet} {
    width: ${props => (props.resize ? '50%' : '100%')};
  }
`
const ErrorMessage = styled.span`
  margin: 0 0 0 12px;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  color: ${props => (props.color === 'true' ? '#b33a3a' : '#aaa7ac')};
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  box-sizing: border-box;
  background: #f6f8f9;
  border: none;
  margin: 2px 0;
  padding: 12px;
  font-family: ${fonts.secondaryFont};
  font-size: 0.9rem;
  color: #aaa7ac;
  border-radius: 5.6px;
`

const Fieldset = styled.fieldset`
  width: 100%;
  padding: 20px 0;
  border: none;

  .react-datepicker-wrapper {
    width: 100%;
    div {
      width: 100%;
    }
  }
`

const Legend = styled.legend`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.dark};
`

const SubmitButton = styled.button`
  margin: 0 auto 20px auto;
  background: ${colors.blue};
  border: none;
  color: #fff;
  font-family: ${fonts.mainFont};
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
`
const modelCertificates = certificates => {
  if (!certificates) return ''
  return certificates.map(c => {
    return { value: c, label: c }
  })
}

export const PersonalInfo = props => (
  <Formik
    onSubmit={values => {
      props.updatePersonalInfo(values)
    }}
    validate={values => {
      let errors = {}

      const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

      if (values.website !== '' && !urlRegex.test(values.website)) {
        errors.website = true
      }

      return errors
    }}
    initialValues={{
      firstName: props.personalInfo.firstName,
      lastName: props.personalInfo.lastName,
      pesel: props.personalInfo.pesel ? props.personalInfo.pesel : '',
      about: props.personalInfo.about ? props.personalInfo.about : '',
      street: props.personalInfo.street ? props.personalInfo.street : '',
      postCode: props.personalInfo.postCode ? props.personalInfo.postCode : '',
      city: props.personalInfo.city ? props.personalInfo.city : '',
      phone: props.personalInfo.phone ? props.personalInfo.phone : '',
      website: props.personalInfo.website ? props.personalInfo.website : '',
      birthDate: props.personalInfo.birthDate ? moment(props.personalInfo.birthDate) : undefined,
      birthPlace: props.personalInfo.birthplace ? props.personalInfo.birthplace : '',
      certificates: modelCertificates(props.personalInfo.certificates),
    }}>
    {props => {
      const { values, errors, handleChange, handleSubmit, setFieldValue } = props
      return (
        <PersonalInfoStyled onSubmit={handleSubmit}>
          <Fieldset>
            <Legend>Zdjęcie profilowe</Legend>
            <FilePond
              allowMultiple={false}
              allowRevert={true}
              server={{
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                  if (!['image/jpeg', 'image/png'].some(type => type === file.type)) {
                    error('')
                    return null
                  }
                  progress(true, 0, 100)
                  setFieldValue('file', file)
                  progress(true, 100, 100)
                  load()
                },
                revert: (undef, load) => {
                  setFieldValue('file', null)
                  load()
                },
                headers: {
                  Authorization: `Bearer ${getCurrentUserToken()}`,
                },
              }}
              acceptedFileTypes={['image/jpeg', 'image/png']}
              labelIdle={
                'Przeciągnij plik lub <span class="filepond--label-action">Przeglądaj</span> aby dodać główny obrazek w formacie JPEG lub PNG. Dla najlepszego rezultatu wybierz plik o rozmiarze 350px x 350px.'
              }
              labelFileloading={'Ładuje plik'}
              labelFileLoadError={'Błąd podczas wgrywania pliku'}
              labelFileProcessing={'Wgrywam plik'}
              labelFileProcessingComplete={'Plik wgrany'}
              labelFileProcessingAborted={'Ładowanie pliku przerwane'}
              labelFileProcessingError={
                'Błąd podczas wgrywania pliku, czy plik ma dozwolony format'
              }
              labelTapToCancel={'Naciśnij aby przerwać'}
              labelTapToRetry={'Naciśnij aby spróbować ponownie'}
            />
          </Fieldset>
          <Fieldset>
            <Legend>Podstawowe informacje</Legend>
            <Input
              id="firstName"
              onChange={handleChange}
              type="text"
              placeholder="Imie"
              value={values.firstName}
              resize={true}
              borderRight={true}
              required
            />
            <Input
              id="lastName"
              type="text"
              placeholder="Nazwisko"
              value={values.lastName}
              onChange={handleChange}
              resize={true}
              required
            />
            <Input
              id="pesel"
              type="number"
              placeholder="Pesel"
              min="0"
              value={values.pesel}
              onChange={handleChange}
            />
          </Fieldset>
          <Fieldset>
            <Legend>O sobie</Legend>
            <TextArea
              rows="4"
              id="about"
              placeholder="Napisz o sobie w kilku zdaniach "
              value={values.about}
              onChange={handleChange}
            />
          </Fieldset>
          <Fieldset>
            <Legend>Certyfikaty</Legend>
            <CreatableSelect
              isMulti
              onChange={value => setFieldValue('certificates', value)}
              value={values.certificates}
              noOptionsMessage={() => null}
              placeholder="Dodaj certyfikat..."
              formatCreateLabel={inputValue => 'Dodaj ' + inputValue}
            />
          </Fieldset>
          <Fieldset>
            <Legend>Adres zameldowania</Legend>
            <Input
              id="street"
              type="text"
              placeholder="Ulica"
              value={values.street}
              onChange={handleChange}
            />
            <Input
              id="postCode"
              type="text"
              placeholder="Kod pocztowy"
              value={values.postCode}
              onChange={handleChange}
              style={{ width: '30%' }}
              borderRight={true}
            />
            <Input
              id="city"
              type="text"
              placeholder="Miasto"
              value={values.city}
              onChange={handleChange}
              style={{ width: '70%' }}
            />
          </Fieldset>
          <Fieldset>
            <Legend>Dane kontaktowe</Legend>
            <Input
              id="phone"
              type="text"
              placeholder="Telefon"
              value={values.phone}
              onChange={handleChange}
            />
            <Input
              id="website"
              type="text"
              placeholder="Strona www"
              value={values.website}
              onChange={handleChange}
            />
            <ErrorMessage color={errors.website ? errors.website.toString() : 'false'}>
              Wprowadź adres strony www zaczynając od 'http' lub 'https'
            </ErrorMessage>
          </Fieldset>
          <Fieldset>
            <Legend>Dane dodatkowe</Legend>
            <DatePicker
              name="birthDate"
              placeholderText="Data urodzenia"
              onChange={date => {
                const correctDate = moment(date)
                console.log(correctDate)
                setFieldValue('birthDate', correctDate.add(1, "d"))
              }}
              selected={(() => {
                console.log(values.birthDate)
                const dateToShow = moment(values.birthDate)
                return dateToShow.add(-1, 'd')
              })()}
              scrollableYearDropdown={true}
              showYearDropdown={true}
              yearDropdownItemNumber={50}
              dateFormat="DD/MM/YYYY"
              customInput={<Input />}
            />
            <Input
              id="birthPlace"
              type="text"
              placeholder="Miejsce urodzienia"
              value={values.birthPlace}
              onChange={handleChange}
            />
          </Fieldset>
          <SubmitButton type="submit">Zapisz</SubmitButton>
        </PersonalInfoStyled>
      )
    }}
  </Formik>
)
