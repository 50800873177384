import produce from 'immer'
import {
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserRole,
  getCurrentUserToken,
  setCurrentUserId,
  setCurrentUserEmail,
  setCurrentUserRole,
  setCurrentUserToken,
  removeUserFromLocalStorage,
} from '../../../utils/auth'
import { callApi } from '../../../utils/api'
import { API, USER_ROLES } from '../../../utils/constants'
import { ADMIN_URL, SCHOOL_URL } from '../../../env'

export const currentUser = {
  state: {
    id: getCurrentUserId(),
    email: getCurrentUserEmail(),
    role: getCurrentUserRole(),
    token: getCurrentUserToken(),
  },
  reducers: {
    setCurrentUser(state, payload) {
      return produce(state, draft => {
        draft.id = payload.userId
        draft.email = payload.email
        draft.role = payload.role
        draft.token = payload.token
      })
    },
    clearUser(state) {
      return produce(state, draft => {
        draft.id = null
        draft.email = null
        draft.role = null
        draft.token = null
      })
    },
  },
  effects: {
    async logIn({ email, password }) {
      await callApi
        .url(`${API}/token`)
        .post({ email, password })
        .json(res => {
          setCurrentUserId(res.userId)
          setCurrentUserEmail(res.email)
          setCurrentUserRole(res.role)
          setCurrentUserToken(res.token)

          this.setCurrentUser(res)

          if (res.role === USER_ROLES.SCHOOL) {
            window.location.href = `${SCHOOL_URL}?jwt=${res.token}&email=${res.email}`
          }
          if (res.role === USER_ROLES.ADMIN) {
            window.location.href = `${ADMIN_URL}?jwt=${res.token}&email=${res.email}`
          }
        })
        .catch(() => {
          throw new Error('Cannot log in')
        })
    },

    logOut() {
      removeUserFromLocalStorage()
      this.clearUser()
    },
  },
}
