import * as React from 'react'
import styled from 'styled-components'
import { Grid } from './grid'
import { Tile } from './tile'
import moment from 'moment'
import 'moment/locale/pl'
import { fonts, colors } from '../utils/constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 90%;
  padding: 0 1rem 0 1rem;
  margin: 0 auto;
  padding-top: 70px;
`
const Header = styled.p`
  font-size: 3em;
  font-weight: 500;
  padding: 1em 0 2em 0em;
`
const Error = styled.p`
  font-size: 2em;
  font-weight: 500;
  padding: 1em 0 2em 0em;
  text-align: center;
`

export function ContentPageAPI({
  header,
  contents,
  titleKey,
  postDateKey,
  imageKey,
  showText,
  textKey,
  urlBase,
  showMore,
  more,
}) {
  return (
    <Container>
      <Header>{header}</Header>
      {!contents || contents.length === 0 ? <Error>Brak artykułów</Error> : null}
      <Grid>
        {contents.map((content) => (
          <Tile
            to={`${urlBase}/${content._id}`}
            key={content._id}
            header={content[titleKey]}
            postDate={content[postDateKey]}
            imageUrl={content[imageKey]}
            content={showText ? content[textKey] : undefined}
          />
        ))}
      </Grid>
      {showMore && more()}
    </Container>
  )
}
