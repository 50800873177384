import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { callApiWithAuth, callApi } from '../utils/api'
import { API, USER_ROLES, devices, fonts, colors } from '../utils/constants'
import { InstructorTile } from './instructors/InstructorTile'
import { InstructorDetails } from './instructors/InstructorDetails'
import { SearchForm } from './../components/SearchForm'
import { ClipLoader } from 'react-spinners'
import qs from 'querystringify'

const InstructorsWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 123px auto auto auto;
  grid-template-areas:
    '. header .'
    ' . instructors .'
    ' . instructors-tablet .'
    ' . more .';

  @media ${devices.tablet} {
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: 113px auto auto auto;
    grid-template-areas:
      '. header  .'
      ' . instructors .'
      ' . instructors-tablet .'
      ' . more .';
  }

  @media ${devices.laptop} {
    grid-template-columns: 40px 1fr 3fr 40px;
    grid-template-rows: 123px auto auto auto;
    grid-template-areas:
      '. side-image header .'
      ' . side-image instructors .'
      ' . more more .';
  }

  @media ${devices.desktop} {
    grid-template-columns: 168px 1fr 3fr 168px;
    grid-template-rows: 143px auto auto auto;
  }
`

const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.5rem;
    line-height: 2;
    font-weight: 700;
    margin: 0;

    @media ${devices.tablet} {
      font-size: 2rem;
      line-height: normal;
    }

    @media ${devices.laptop} {
      margin: 0 20px;
    }

    @media ${devices.desktop} {
      font-size: 3rem;
      line-height: 1.52;
    }
  }
`
const StickyImageContainer = styled.div`
  grid-area: side-image;
  display: none;
  margin-top: 10px;
  @media ${devices.laptop} {
    display: block;
  }
`
const InstructorsList = styled.div`
  grid-area: instructors;
  display: flex;
  flex-wrap: wrap;
`
const InstructorsListTablet = styled.div`
  display: none;
  grid-area: instructors-tablet;

  @media ${devices.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`
const NoResults = styled.span`
  font-size: 1.1rem;
  line-height: 2;
  font-weight: 600;
  margin: 0;

  @media ${devices.tablet} {
    font-size: 2rem;
    line-height: normal;
  }

  @media ${devices.laptop} {
    margin: 0 20px;
  }

  @media ${devices.desktop} {
    font-size: 3rem;
    line-height: 1.52;
  }
`

const More = styled.button`
  grid-area: more;
  margin: 50px auto;
  width: 100%;
  text-align: center;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1.6rem;
  position: relative;
  background: none;
  border: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  &::before {
    display: block;
    content: '';
    background: ${colors.blue};
    position: absolute;
    height: 10px;
    width: 166px;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`

const InstructorsTileWrapper = props => {
  if (props.windowWidth < 1024 && props.windowWidth >= 768) {
    return (
      <Fragment>
        {props.instructorsList.length > 0 && (
          <InstructorTile instructor={props.instructorsList[0]} currentUser={props.currentUser} />
        )}
        {props.instructorsList.length > 1 && (
          <InstructorTile instructor={props.instructorsList[1]} currentUser={props.currentUser} />
        )}
        {props.instructorsList.length > 2 && (
          <InstructorTile instructor={props.instructorsList[2]} currentUser={props.currentUser} />
        )}
        {props.instructorsList.length > 3 && (
          <InstructorTile instructor={props.instructorsList[3]} currentUser={props.currentUser} />
        )}
        {props.instructorsList.length > 4 && (
          <InstructorTile instructor={props.instructorsList[4]} currentUser={props.currentUser} />
        )}
        {props.instructorsList.length > 5 && (
          <InstructorTile instructor={props.instructorsList[5]} currentUser={props.currentUser} />
        )}
      </Fragment>
    )
  }

  return props.instructorsList.map(i => {
    return <InstructorTile instructor={i} key={i._id} currentUser={props.currentUser} />
  })
}

const InstuctorsListTabletContent = props => {
  if (props.windowWidth < 768 || props.windowWidth > 1024) return null

  return props.instructorsList.map((ins, i) => {
    if (i < 6) return null
    return (
      <InstructorTile
        instructor={ins}
        key={ins._id}
        additionalTablet={true}
        currentUser={props.currentUser}
      />
    )
  })
}

class Instructors extends Component {
  state = {
    instructorsList: [],
    instructorDetails: null,
    readMorePage: 2,
    windowWidth: null,
    disableReadMore: false,
    searchPhrase: '',
    loader: true,
  }

  componentDidMount() {
    if (window.location.search) {
      this.checkForInstructorIdInQuery()
    }

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    this.getInstructorsList()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.location.search !== this.props.location.search) {
      this.checkForInstructorIdInQuery()
    }
  }

  getInstructorsList = (search = '') => {
    let instructorsQuantity = 4
    const windowWidth = window.innerWidth

    if (windowWidth >= 768 && windowWidth < 1920) instructorsQuantity = 6
    if (windowWidth >= 1920) instructorsQuantity = 8

    callApi
      .url(`${API}/instructor/website?quantity=${instructorsQuantity}&search=${search}`)
      .get()
      .json(res => {
        this.setState({
          instructorsList: res.data,
          searchPhrase: search,
          disableReadMore: false,
          readMorePage: 2,
          loader: false,
        })
      })
      .catch(e => {
        this.setState({
          loader: false,
        })
        console.error(e)
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  checkForInstructorIdInQuery = () => {
    if (
      this.props.currentUser.role === USER_ROLES.SCHOOL ||
      this.props.currentUser.role === USER_ROLES.ADMIN
    ) {
      const query = qs.parse(window.location.search)
      if (query.instId) {
        callApiWithAuth
          .url(`${API}/instructor/${query.instId}`)
          .get()
          .json(res => {
            this.setState({
              instructorDetails: res,
            })
          })
          .catch(e => console.error(e))
      } else {
        this.setState({
          instructorDetails: null,
        })
      }
    } else {
      return null
    }
  }

  LoadMoreInstructors = () => {
    let instructorsQuantity = 4
    const windowWidth = this.state.windowWidth

    if (windowWidth >= 768 && windowWidth < 1920) instructorsQuantity = 6
    if (windowWidth >= 1920) instructorsQuantity = 8

    callApi
      .url(
        `${API}/instructor/website?quantity=${instructorsQuantity}&page=${this.state.readMorePage}&search=${this.state.searchPhrase}`
      )
      .get()
      .json(res => {
        this.setState(prevState => {
          return {
            instructorsList: [...prevState.instructorsList, ...res.data],
            readMorePage: prevState.readMorePage + 1,
            disableReadMore: res.data.length === 0 ? true : false,
          }
        })
      })
      .catch(e => console.error(e))
  }

  render() {
    if (this.state.instructorDetails)
      return <InstructorDetails instructorDetails={this.state.instructorDetails} />

    return (
      <InstructorsWrapper>
        <Header>
          <span>Lista instruktorów</span>
          <SearchForm getList={this.getInstructorsList} placeholder={'Wyszukaj instruktorów'} />
        </Header>
        <StickyImageContainer>
          <p style={{ position: 'sticky', top: '32px' }}>
            <img src="https://api.sits.org.pl/instructor/image" alt="instructor" />
          </p>
        </StickyImageContainer>
        <InstructorsList>
          {this.state.loader ? (
            <div style={{ margin: '0 auto' }}>
              <ClipLoader sizeUnit={'px'} size={45} color={`${colors.blue}`} />
            </div>
          ) : (
            <Fragment>
              {this.state.instructorsList.length === 0 && (
                <NoResults>Brak wyników wyszukiwania</NoResults>
              )}
              <InstructorsTileWrapper
                currentUser={this.props.currentUser}
                instructorsList={this.state.instructorsList}
                windowWidth={this.state.windowWidth}
              />
            </Fragment>
          )}
        </InstructorsList>
        <InstructorsListTablet>
          <InstuctorsListTabletContent
            instructorsList={this.state.instructorsList}
            windowWidth={this.state.windowWidth}
            currentUser={this.props.currentUser}
          />
        </InstructorsListTablet>
        {this.state.instructorsList.length > 0 && (
          <More onClick={this.LoadMoreInstructors} disabled={this.state.disableReadMore}>
            Pokaż więcej
          </More>
        )}
      </InstructorsWrapper>
    )
  }
}

export default Instructors
