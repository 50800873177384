import styled from 'styled-components'
import { colors, fonts } from '../utils/constants'

export const More = styled.button`
  margin: 50px auto;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1.6rem;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? '.4' : '1')};
  background: transparent;
  border: none;
  &::before {
    display: block;
    content: '';
    background: ${colors.blue};
    position: absolute;
    height: 10px;
    width: 166px;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`
