import React, { Component } from 'react'
import styled from 'styled-components'
import { callApi } from '../../utils/api'
import { API, devices, fonts, colors } from '../../utils/constants'
import { Link } from 'react-router-dom'
import newsDots from './../../images/newsDots.svg'
import { Tile } from '../../new-components/tile'

const NewsStyled = styled.section`
  grid-area: news;
  font-family: ${fonts.mainFont};
  font-weight: 700;
  color: ${colors.dark};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const NewsContainer = styled.div`
  display: flex;
  margin: 50px 0 0 0;
  background: #fff;
  align-items: flex-start;
`
const SectionTitle = styled.span`
  margin-top: 40px;
  font-size: 1.1rem;
  margin-left: 34px;
  font-weight: 600;

  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 12px;
    background: ${colors.blue};
    position: relative;
    left: -20px;
    top: 12px;
  }

  @media ${devices.tablet} {
    font-size: 1.55rem;

    &::before {
      height: 2px;
      width: 24px;
      left: -30px;
      top: 17px;
    }
  }

  @media ${devices.laptop} {
    font-size: 1.55rem;
  }
`
const ReadMore = styled(Link)`
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  margin: 0 0 30px 0;
  color: ${colors.dark};
  &::before {
    display: block;
    content: '';
    height: 10px;
    width: 166px;
    background: ${colors.blue};
    position: relative;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media ${devices.tablet} {
    width: 100%;
    bottom: 0;

    &::before {
      width: 133px;
    }
  }

  @media ${devices.laptop} {
    width: 100%;
    bottom: -10px;

    &::before {
      width: 229px;
    }
  }
`

const StyledDots = styled.img`
  position: absolute;
  top: 124px;
  z-index: -1;

  @media ${devices.mobileM} {
    right: -90px;
    transform: rotate(90deg);
  }

  @media ${devices.laptop} {
    right: 10px;
  }
`

const NewsGrid = styled.div`
  display: grid;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  gap: 12px;

  @media ${devices.tablet} {
    padding-right: 50px;
    padding-left: 50px;
    gap: 32px;
    grid-template-columns: 66% 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`

const BigTile = styled(Tile)`
  @media ${devices.tablet} {
    margin: 50px 0;
    grid-area: 1 / 1 / 3 / 2;
  }
`

const SmallTileOne = styled(Tile)`
  @media ${devices.tablet} {
    grid-area: 1 / 2 / 2 / 3;
  }
`
const SmallTileTwo = styled(Tile)`
  @media ${devices.tablet} {
    grid-area: 2 / 2 / 3 / 3;
  }
`

export class News extends Component {
  state = { newsList: [] }

  componentDidMount = () => {
    callApi
      .url(`${API}/news/website?quantity=3&column=creationDate&descending=true`)
      .get()
      .json(res => {
        this.setState({ newsList: res.data })
      })
      .catch(e => console.error(e))
  }

  formatDate = date => {
    return new Date(date).toLocaleDateString('en-GB')
  }

  render() {
    if (this.state.newsList.length === 0) return null
    return (
      <NewsStyled>
        <SectionTitle>Aktualności</SectionTitle>
        <NewsContainer>
          <NewsGrid>
            <BigTile
              disableZigzag
              to={`/news/${this.state.newsList[0]._id}`}
              header={this.state.newsList[0].title}
              imageUrl={this.state.newsList[0].backgroundUrl}
              postDate={this.state.newsList[0].creationDate}
            />
            {this.state.newsList[1] && (
              <SmallTileOne
                disableZigzag
                to={`/news/${this.state.newsList[1]._id}`}
                header={this.state.newsList[1].title}
                imageUrl={this.state.newsList[1].backgroundUrl}
                postDate={this.state.newsList[1].creationDate}
              />
            )}
            {this.state.newsList[2] && (
              <SmallTileTwo
                disableZigzag
                to={`/news/${this.state.newsList[2]._id}`}
                header={this.state.newsList[2].title}
                imageUrl={this.state.newsList[2].backgroundUrl}
                postDate={this.state.newsList[2].creationDate}
              />
            )}
          </NewsGrid>
        </NewsContainer>
        <ReadMore to="/news">Pokaż starsze artykuły</ReadMore>
        <StyledDots src={newsDots} alt="coursesDots" />
      </NewsStyled>
    )
  }
}
