import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { callApi } from '../utils/api'
import { API, devices, fonts, colors } from '../utils/constants'
import { SchoolTile } from './schools/SchoolTile'
import { SearchForm } from './../components/SearchForm'
import { ClipLoader } from 'react-spinners'

const SchoolsWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 123px auto auto auto;
  grid-template-areas:
    '. header .'
    ' . schools .'
    ' . schools-minor .'
    ' . more .';

  @media ${devices.tablet} {
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: 113px auto auto auto;
    grid-template-areas:
      '. header .'
      '. schools .'
      '. schools-minor .'
      '. more.';
  }

  @media ${devices.laptop} {
    grid-template-columns: 40px 1fr 3fr 40px;
    grid-template-rows: 123px auto auto auto;
    grid-template-areas:
      '. picture header .'
      '. picture schools .'
      '. schools-minor schools-minor .'
      ' . more more .';
  }

  @media ${devices.desktop} {
    grid-template-columns: 168px 1fr 3fr 168px;
    grid-template-rows: 143px auto auto auto;
  }
`
const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.5rem;
    line-height: 2;
    font-weight: 700;
    margin: 0;

    @media ${devices.tablet} {
      font-size: 2rem;
      line-height: normal;
    }

    @media ${devices.laptop} {
      margin: 0 20px;
    }

    @media ${devices.desktop} {
      font-size: 3rem;
      line-height: 1.52;
    }
  }
`
const StickyImageContainer = styled.div`
  grid-area: picture;
  display: none;
  margin-top: 10px;
  @media ${devices.laptop} {
    display: block;
  }
`

const SchoolsList = styled.div`
  grid-area: schools;
  display: flex;
  flex-wrap: wrap;

  @media ${devices.laptop} {
    margin: 0 5%;

    & > div {
      display: flex;
      justify-content: flex-start;
    }

    & > div:nth-child(even) {
      justify-content: flex-end;
    }
  }
`
const NoResults = styled.span`
  font-size: 1.1rem;
  line-height: 2;
  font-weight: 600;
  margin: 0;

  @media ${devices.tablet} {
    font-size: 2rem;
    line-height: normal;
  }

  @media ${devices.laptop} {
    margin: 0 20px;
  }

  @media ${devices.desktop} {
    font-size: 3rem;
    line-height: 1.52;
  }
`

const SchoolsListMinor = styled.div`
  grid-area: schools-minor;
  display: none;
  @media ${devices.tablet} {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${devices.laptop} {
    display: none;
  }
`

const More = styled.button`
  grid-area: more;
  margin: 50px auto;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1.6rem;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? '.4' : '1')};
  background: transparent;
  border: none;
  &::before {
    display: block;
    content: '';
    background: ${colors.blue};
    position: absolute;
    height: 10px;
    width: 166px;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`
const SchoolsTileWrapper = props => {
  if (props.windowWidth < 1024 && props.windowWidth >= 768) {
    return (
      <Fragment>
        {props.schoolList.length > 0 && (
          <SchoolTile
            additional={true}
            school={props.schoolList[0]}
            key={props.schoolList[0]._id}
          />
        )}
        {props.schoolList.length > 1 && (
          <SchoolTile
            additional={true}
            school={props.schoolList[1]}
            key={props.schoolList[1]._id}
          />
        )}
        {props.schoolList.length > 2 && (
          <SchoolTile
            additional={true}
            school={props.schoolList[2]}
            key={props.schoolList[2]._id}
          />
        )}
        {props.schoolList.length > 3 && (
          <SchoolTile
            additional={true}
            school={props.schoolList[3]}
            key={props.schoolList[3]._id}
          />
        )}
      </Fragment>
    )
  }
  return props.schoolList.map(s => {
    return <SchoolTile school={s} key={s._id} />
  })
}

const SchoolsListMinorTileWrapper = props => {
  if (props.windowWidth < 768 || props.windowWidth > 1024) return null

  return props.schoolList.map((s, i) => {
    if (i < 4) return null
    return <SchoolTile school={s} key={s._id} additional={true} />
  })
}

class Schools extends Component {
  state = {
    windowWidth: null,
    schoolList: [],
    readMorePage: 2,
    searchPhrase: '',
    disableReadMore: false,
    loader: true,
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    this.getSchoolList()
  }

  getSchoolList = (search = '') => {
    callApi
      .url(`${API}/school/website?quantity=4&search=${search}&column=fullName`)
      .get()
      .json(res => {
        this.setState({
          schoolList: res.data,
          searchPhrase: search,
          disableReadMore: false,
          readMorePage: 2,
          loader: false,
        })
      })
      .catch(e => {
        this.setState({
          loader: false,
        })
        console.error(e)
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  LoadMoreSchools = () => {
    callApi
      .url(
        `${API}/school/website?quantity=4&page=${this.state.readMorePage}&search=${this.state.searchPhrase}&column=fullName`
      )
      .get()
      .json(res => {
        this.setState(prevState => {
          return {
            schoolList: [...prevState.schoolList, ...res.data],
            readMorePage: prevState.readMorePage + 1,
            disableReadMore: res.data.length === 0 ? true : false,
          }
        })
      })
      .catch(e => console.error(e))
  }

  render() {
    return (
      <SchoolsWrapper>
        <Header>
          <span>Lista szkół</span>
          <SearchForm getList={this.getSchoolList} placeholder={'Wyszukaj szkoły'} />
        </Header>
        <StickyImageContainer>
          <p style={{ position: 'sticky', top: '32px' }}>
            <img src="https://api.sits.org.pl/school/image" alt="school" />
          </p>
        </StickyImageContainer>
        <SchoolsList>
          {this.state.loader ? (
            <div style={{ margin: '0 auto' }}>
              <ClipLoader sizeUnit={'px'} size={45} color={`${colors.blue}`} />
            </div>
          ) : (
            <Fragment>
              {this.state.schoolList.length === 0 && (
                <NoResults>Brak wyników wyszukiwania</NoResults>
              )}
              {this.state.schoolList.length !== 0 && (
                <SchoolsTileWrapper
                  schoolList={this.state.schoolList}
                  windowWidth={this.state.windowWidth}
                />
              )}
            </Fragment>
          )}
        </SchoolsList>
        <SchoolsListMinor>
          <SchoolsListMinorTileWrapper
            schoolList={this.state.schoolList}
            windowWidth={this.state.windowWidth}
          />
        </SchoolsListMinor>
        <More onClick={this.LoadMoreSchools} disabled={this.state.disableReadMore}>
          Pokaż więcej szkół
        </More>
      </SchoolsWrapper>
    )
  }
}

export default Schools
