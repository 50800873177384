import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors, API } from './../../../utils/constants'
import SchoolPhone from './../../../images/school-phone.svg'

const AvatarWrapper = styled.div`
  width: 100%;

  img {
    width: calc(100% - 60px);
    padding: 30px;
  }

  @media ${devices.tablet} {
    width: 144px;
    order: 3;
    img {
      width: 100%;
      padding: 0;
    }
  }

  @media ${devices.laptop} {
    width: 100%;
    order: 1;
  }
`

const Details = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${devices.laptop} {
    flex-direction: column;
  }
`
const Level = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-family: ${fonts.mainFont};
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.dark};
  background: #fad29e;
  padding: 10px 5px;
  min-width: 100px;

  @media ${devices.tablet} {
    top: 41px;
  }

  @media ${devices.laptop} {
    top: 0;
  }
`

const PhotoAndName = styled.div`
  border: solid 1px #d0d9f9;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    width: 60%;
    box-sizing: border-box;
    order: 1;
    flex-direction: row;
    flex-wrap: wrap;
    border: none;
  }
  @media ${devices.laptop} {
    width: 80%;
    flex-direction: column;
    margin: 0 0 0 20%;
  }
`
const PhoneAndWebsite = styled.div`
  display: flex;
  margin: 20px 0;
  justify-content: center;
  @media ${devices.tablet} {
    width: 100%;
    order: 3;
  }

  @media ${devices.laptop} {
    order: 2;
  }
`
const CertificatesWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  @media ${devices.tablet} {
    width: 40%;
    order: 2;
  }
  @media ${devices.laptop} {
    width: 100%;
    order: 3;
  }
`

const NameAndCity = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media ${devices.tablet} {
    order: 2;
  }

  @media ${devices.laptop} {
    width: 100%;
    order: 3;
  }
`
const Name = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: ${colors.dark};
  font-family: ${fonts.mainFont};
  margin: 0;

  @media ${devices.tablet} {
    width: unset;
    font-size: 1.6rem;
    text-align: left;
    margin: 0 10px 10px 0;
  }

  @media ${devices.laptop} {
    width: 100%;
    text-align: center;
    margin: 0 0 10px 0;
  }
`

const City = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  opacity: 0.4;
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.dark};
  margin: 0;

  @media ${devices.tablet} {
    width: unset;
    font-size: 0.95rem;
    text-align: left;
    align-self: center;
    margin: 0 10px 10px 0;
  }

  @media ${devices.laptop} {
    width: 100%;
    text-align: center;
    margin: 0 0 10px 0;
  }
`
const About = styled.span`
  font-family: ${fonts.secondaryFont};
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.71;
  text-align: center;
  color: ${colors.dark};
  width: 90%;
  margin: 10px auto;
  display: block;

  @media ${devices.tablet} {
    width: calc(100% - 164px);
    order: 4;
    text-align: left;
    margin: 10px;
  }

  @media ${devices.laptop} {
    width: 100%;
    margin: 0;
    text-align: center;
  }
`
const Certificate = styled.span`
  opacity: 0.4;
  border-radius: 4px;
  border: solid 1px ${colors.dark};
  font-family: ${fonts.mainFont};
  font-size: 0.9rem;
  font-weight: 600;
  color: ${colors.dark};
  padding: 4px;
  margin: 5px;
  word-break: break-all;
`

const CertsHeader = styled.div`
  font-family: ${fonts.mainFont};
  font-size: 1.4rem;
  font-weight: 600;
  color: ${colors.dark};
  margin: 0 0 20px 0;
  width: 100%;
`

const Phone = styled.div`
  border-radius: 30px;
  background-color: ${colors.blue};
  color: #fff;
  padding: 0 20px;
  text-overflow: ellipsis;
  height: 33px;
  text-align: center;
  margin: 0 10px;
  font-size: 0.9rem;
  font-weight: 600;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 2px 0 0;
  }

  @media ${devices.tablet} {
    flex: 0;
    min-width: 30%;
  }

  @media ${devices.laptop} {
    flex: 1;
    min-width: unset;
  }
`

const Website = styled.a`
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid ${colors.blue};
  color: ${colors.blue};
  padding: 0 20px;
  margin: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 33px;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devices.tablet} {
    flex: 0;
    min-width: 30%;
  }

  @media ${devices.laptop} {
    flex: 1;
    min-width: unset;
  }
`

const RenderCerts = props => {
  if (!props.certs) return null
  return props.certs.map((c, i) => (
    <Certificate key={i}>
      <span>{c}</span>
    </Certificate>
  ))
}

export const InstructorDetailsPersonalInfo = props => (
  <Details>
    <PhotoAndName>
      <Level>{props.instructorDetails.level}</Level>
      <AvatarWrapper>
        <img src={`${API}${props.instructorDetails.imageUrl}`} alt="Instructor avatar" />
      </AvatarWrapper>
      <NameAndCity>
        <Name>
          {props.instructorDetails.firstName} {props.instructorDetails.lastName}
        </Name>
        <City>{props.instructorDetails.city}</City>
      </NameAndCity>
      <About>{props.instructorDetails.about}</About>
    </PhotoAndName>
    <PhoneAndWebsite>
      {props.instructorDetails.phone && (
        <Phone>
          <img src={SchoolPhone} alt="phone" />
          <span>{props.instructorDetails.phone}</span>
        </Phone>
      )}
      {props.instructorDetails.website && (
        <Website href={props.instructorDetails.website} target="_blank">
          {props.instructorDetails.website}
        </Website>
      )}
    </PhoneAndWebsite>
    <CertificatesWrapper>
      <CertsHeader>Certyfikaty</CertsHeader>
      <RenderCerts certs={props.instructorDetails.certificates} />
    </CertificatesWrapper>
  </Details>
)
