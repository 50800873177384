import React from 'react'
import styled from 'styled-components'
import { withFormik } from 'formik'
import { callApi } from '../../utils/api'
import { API } from '../../utils/constants'

const Form = styled.form`
  display: flex;
  width: 30%;
  flex-direction: column;
  margin: 0 auto;
  button {
    height: 30px;
  }
`

let RecoverPassForm = props => {
  const { values, handleSubmit, handleChange } = props

  return (
    <Form onSubmit={handleSubmit}>
      <h2>RECOVER PASS</h2>
      <input
        name="email"
        type="email"
        placeholder="Email"
        value={values.email}
        onChange={handleChange}
      />
      <button type="submit">SUBMIT</button>
    </Form>
  )
}

RecoverPassForm = withFormik({
  mapPropsToValues: () => ({ email: `` }),
  handleSubmit: (values, { props }) => {
    callApi
      .url(`${API}/user/password-recovery/send`)
      .post({ email: values.email })
      .res(res => console.log(res))
      .catch(e => {
        console.error(e)
      })
  },
})(RecoverPassForm)

export default RecoverPassForm
