import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { devices, colors } from './../../utils/constants'
import fbIcon from './../../images/fb-button.svg'

const IFrameWrapperForMobile = styled.div`
  display: flex;
  justify-content: center;
  grid-area: mobileFbfeed;
  margin: 40px 0;
  overflow: scroll;
  @media ${devices.tablet} {
    display: none;
  }
`
const DesktopFeed = styled.div`
  position: absolute;
  right: 0;
  top: 20%;
  transform: ${props => (props.isOpen ? 'translateX(363px)' : 'translateX(0)')};
  transition: 0.5s ease-in-out;
  display: none;
  z-index: 2;

  @media ${devices.tablet} {
    display: flex;
  }

  @media ${devices.desktop} {
    top: 25%;
  }
`

const FbFeedIcon = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  background: ${colors.blue};
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  align-self: center;
  border: none;
`
const IFrameWrapper = styled.div`
  display: block;
  width: 335x;
  height: 500px;
  padding: 10px;
  border: 8px solid ${colors.blue};
  border-right: none;
  background: #fff;
`

const FbIframe = () => (
  <iframe
    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSITSsnow&tabs=timeline&width=335&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=375683522511434"
    width="335"
    height="500"
    style={{ border: 'none', overflow: 'hidden' }}
    scrolling="no"
    frameBorder="0"
    allow="encrypted-media"
    title="Facebook Feed"
  />
)

export class FbFeed extends Component {
  state = { isDesktopFeedClosed: true }

  toggleDesktopFeed = () => {
    this.setState({ isDesktopFeedClosed: !this.state.isDesktopFeedClosed })
  }

  render() {
    return (
      <Fragment>
        <IFrameWrapperForMobile>
          <FbIframe />
        </IFrameWrapperForMobile>
        <DesktopFeed isOpen={this.state.isDesktopFeedClosed}>
          <FbFeedIcon onClick={this.toggleDesktopFeed} src={fbIcon} />
          <IFrameWrapper>
            <FbIframe />
          </IFrameWrapper>
        </DesktopFeed>
      </Fragment>
    )
  }
}
