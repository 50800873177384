import React, { Fragment } from 'react'
import styled from 'styled-components'
import { withFormik } from 'formik'
import { devices, API, fonts, colors, userModalTypes } from '../../utils/constants'
import modalSideImg from './../../images/loginModalBackground.jpg'
import { callApi } from './../../utils/api'

const FormWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 70vh;

  @media ${devices.tablet} {
    width: 480px;
  }

  @media ${devices.laptop} {
    min-height: 0;
  }
`

const Form = styled.form`
  display: flex;
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  flex-direction: column;
`

const InputLabel = styled.label`
  font-size: 1.14rem;
  font-weight: 600;
  margin: 0 0 10px 48px;
`

const Input = styled.input`
  margin: 0 48px 28px 48px;
  background: #f6f8f9;
  border: none;
  padding: 15px;
  font-size: 0.9rem;
`

const FormHeader = styled.div`
  font-weight: 700;
  font-size: 1.9rem;
  width: 100%;
  text-align: center;
  margin: 20px auto;
`

const LoginButton = styled.button`
  background: ${colors.blue};
  min-width: 60%;
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;
  border-radius: 30px;
  border: none;
  height: 47px;
  cursor: pointer;
  margin: 20px auto;
`
const SideImage = styled.div`
  display: none;
  width: 480px;
  max-height: 660px;
  overflow: hidden;
  position: relative;

  img {
    height: 660px;
  }

  @media ${devices.laptop} {
    display: block;
  }
`

const ErrorMessage = styled.span`
  margin: 0 0 0 48px;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  color: #b33a3a;
  display: block;
  height: 13.5px;
  transform: translateY(-20px);
`

const UserTypeRadioInuptWrapper = styled.div`
  display: flex;
  justify-content: center;
  label {
    margin: 3px 23px 0 5px;
    font-family: ${fonts.mainFont};
    font-size: 0.9rem;
    font-weight: 700;
    color: ${colors.dark};
  }
`
const GoToRegister = styled.button`
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translate(-50%, 0);
  background: none;
  border: none;
  color: ${colors.blue};
  font-size: 1rem;
  font-weight: 700;
  font-family: ${fonts.mainFont};
  cursor: pointer;
  text-decoration: underline;
`

const RegisterForm = props => {
  const { values, errors, handleSubmit, handleChange, setFieldValue } = props
  return (
    <Fragment>
      <FormWrapper>
        <FormHeader>
          <span>Stwórz konto</span>
        </FormHeader>
        <Form onSubmit={handleSubmit} id="registerForm">
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            required
          />
          <ErrorMessage>{!values.emailBool && <span>{values.emailError}</span>}</ErrorMessage>
          <InputLabel htmlFor="password">Hasło</InputLabel>
          <Input
            name="password"
            type="password"
            placeholder="Hasło"
            value={values.password}
            onChange={handleChange}
            required
          />
          <InputLabel htmlFor="passwordRepeat">Powtórz hasło</InputLabel>
          <Input
            name="passwordRepeat"
            type="password"
            placeholder="Hasło"
            value={values.passwordRepeat}
            onChange={handleChange}
            required
          />
          <ErrorMessage>{errors && <span>{errors.password}</span>}</ErrorMessage>
          <UserTypeRadioInuptWrapper>
            <input
              type="radio"
              name="userType"
              value={values.userType}
              checked={values.userType === 'basic'}
              onChange={() => {
                setFieldValue('userType', 'basic')
              }}
            />
            <label htmlFor="userType">Zwykłe</label>
            <input
              type="radio"
              name="userType"
              value={values.userType}
              checked={values.userType === 'school'}
              onChange={() => {
                setFieldValue('userType', 'school')
              }}
            />
            <label htmlFor="userType">Szkoła</label>
          </UserTypeRadioInuptWrapper>
        </Form>
        <LoginButton type="submit" form="registerForm">
          Zarejestruj się
        </LoginButton>
      </FormWrapper>
      <SideImage>
        <img src={modalSideImg} alt="loginSideImage" />
        <GoToRegister onClick={() => props.setUserModalType(userModalTypes.login)}>
          Masz już konto?
        </GoToRegister>
      </SideImage>
    </Fragment>
  )
}

let RegisterFormHigherOrderComponent = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: ``,
    password: '',
    passwordRepeat: '',
    userType: 'basic',
    emailBool: true,
    emailError: 'Konto z podanym adresem email już istnieje!',
  }),

  validate: values => {
    let errors = {}
    if (values.password !== values.passwordRepeat) {
      errors.password = 'Podane hasła nie są jednakowe!'
    }
    return errors
  },

  handleSubmit: (values, { props, setFieldValue }) => {
    callApi
      .url(`${API}/user/check/${values.email}`)
      .get()
      .json(res => {
        if (res.res) {
          props.setUserModalType(values)
        } else {
          setFieldValue('emailBool', false)
        }
      })
  },
})(RegisterForm)

export default RegisterFormHigherOrderComponent
