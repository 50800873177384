import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors } from './../../utils/constants'
import { MobileTile } from './../../components/courses/MobileTile'
import { DesktopTile } from './../../components/courses/DesktopTile'

const CourseHistoryStyled = styled.div`
  grid-area: content;
`
const Header = styled.span`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.dark};
`
const MobileList = styled.div`
  display: block;
  @media ${devices.tablet} {
    display: none;
  }
`
const DesktopList = styled.table`
  display: none;
  @media ${devices.tablet} {
    display: table;
    width: 100%;
  }
`
const DesktopTableHeader = styled.thead`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: #aaa7ac;
  margin: 0 0 20px 0;

  th {
    text-align: center !important;
    padding: 0 0 20px 0;
  }
`

const RenderListDesktop = props => {
  return props.coursesList.filter(c => c.passed === true).map((c, i) => {
    return <DesktopTile course={c} key={i} profile="true" />
  })
}

const RenderMobileList = props => {
  return props.coursesList.filter(c => c.passed === true).map((c, i) => {
    return <MobileTile course={c} key={i} profile="true" />
  })
}

export const CourseHistory = props => (
  <CourseHistoryStyled>
    <Header>Twoje kursy</Header>
    <MobileList>
      <RenderMobileList coursesList={props.courseHistory} />
    </MobileList>
    <DesktopList>
      <DesktopTableHeader>
        <tr>
          <th>Czas trwania</th>
          <th>Stopień</th>
          <th>Rola</th>
          <th>Miejsce kursu</th>
          <th>Szkoła</th>
        </tr>
      </DesktopTableHeader>
      <tbody>
        <RenderListDesktop coursesList={props.courseHistory} />
      </tbody>
    </DesktopList>
  </CourseHistoryStyled>
)
