import React from 'react'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'

const Checkmark = styled.div`
  border-radius: 50%;
  min-width: 33px;
  max-width: 33px;
  height: 33px;
  border: 2px solid #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`

const Input = styled.div`
  border-radius: 31px;
  padding: 10px;
  background: ${props => props.isCorrect && `#12D381`};
  background: ${props => props.isUsers && `#FF4E84`};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 18px;
  max-width: 600px;
`

const Content = styled.span`
  padding-left: 35px;
  color: ${props => (props.isCorrect || props.isUsers ? `#FFFFFF` : `#707070`)};
`

function AnswerRadio({ content, isCorrect, isUsers, onClick }) {
  return (
    <Input isCorrect={isCorrect} isUsers={isUsers} onClick={onClick}>
      <Checkmark isCorrect={isCorrect} isUsers={isUsers}>
        {isCorrect && <CorrectMark />}
        {isUsers && <XMark />}
      </Checkmark>
      <Content isCorrect={isCorrect} isUsers={isUsers}>
        {content}
      </Content>
    </Input>
  )
}

AnswerRadio = React.memo(AnswerRadio)

const XMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.121"
    height="16.121"
    viewBox="0 0 16.121 16.121">
    <g transform="translate(-138.939 -493.569)">
      <g transform="translate(110.689 141)">
        <path
          fill="none"
          stroke="#ff4e84"
          strokeWidth={3}
          d="M14755.311,722.667l14,14"
          transform="translate(-14725.998 -369.037)"
        />
        <path
          fill="none"
          stroke="#ff4e84"
          strokeWidth={3}
          d="M14769.31,722.667l-14,14"
          transform="translate(-14725.999 -369.037)"
        />
      </g>
    </g>
  </svg>
)

const CorrectMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.121"
    height="15.235"
    viewBox="0 0 20.121 15.235">
    <path
      fill="none"
      stroke="#2dcb82"
      strokeWidth={3}
      d="M2326.5,774.773l5.947,5.947,12.053-12.053"
      transform="translate(-2325.443 -767.606)"
    />
  </svg>
)

const Category = styled.p`
  font-size: 20px;
  color: #707070;
  margin-bottom: 33px;
  margin-top: 65px;
`

const QuestionContent = styled.p`
  font-size: 30px;
  color: #707070;
  margin-bottom: 66px;
`

export function ResultQuestion({ question: q }) {
  const { correctAnswerId, answerId, question } = q
  return (
    <div>
      <QuestionContent>{question.content}</QuestionContent>
      <Row>
        <Col xs={10}>
          {question.answers.filter((ans, index) => answerId === ans.id).map((ans, index) => (
            <AnswerRadio
              key={ans.id}
              isUsers={answerId === ans.id}
              isCorrect={ans.isCorrect}
              content={ans.content}
            />
          ))}
        </Col>
      </Row>
    </div>
  )
}
