import produce from 'immer'

export const userModal = {
  state: {
    isUserModalOpen: false,
    userModalType: null,
  },
  reducers: {
    toggleUserModal(state, payload = null) {
      return produce(state, draft => {
        draft.isUserModalOpen = !draft.isUserModalOpen
        draft.userModalType = payload
      })
    },
    setUserModalType(state, payload) {
      return produce(state, draft => {
        draft.userModalType = payload
      })
    },
  },
}
