import React from 'react'
import styled from 'styled-components'
import { fonts, colors } from '../../utils/constants'
import sitsLogo from '../../images/sitsLogo.svg'
import start from '../../images/start.png'

const SummaryWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: grid;
`

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 2rem 0 2rem;
`

const Header = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 1.1rem;
  color: #707070;
  margin-bottom: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Content = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 1.1rem;
  color: #707070;
  justify-content: flex-start;
`

const Image = styled.img`
  max-width: 80%;
  height: auto;
  align-self: center;
  justify-self: flex-end;
  margin-top: 5rem;
`

const Subheader = styled.div`
  font-weight: 600 !important;
`

const SmallText = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 0.8rem;
  color: #707070;
  justify-content: flex-start;
`

const StartButton = styled.button`
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1.1rem;
  height: 65px;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  padding: 5px 75px 5px 75px;
  cursor: pointer;
  background: ${colors.blue};
  color: #fff;
  border: none;
  text-decoration: none;
  &:focus {
    outline: 0 0 0 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
`

export default function StartMobile({ startExam, level }) {
  return (
    <>
      <SummaryWrapper>
        <SummaryContainer>
          <Header>
            <img
              src={sitsLogo}
              alt="sits-logo"
              height="105"
              width="105"
              style={{ marginRight: 39 }}
            />
                        Test mobile {level}
          </Header>
          <Content>
            <Subheader>Ważne informacje</Subheader>
            <br />
                        Test jest podzielony na 5 kategorii tematycznych: Bezpieczeństwo, Sprzęt, Technika
                        Jazdy, Metodyka Nauczania, Sport.
                        <br />
            <br />
                        Na każde pytanie jest tylko jedna prawidłowa odpowiedz.
                        <br />
            <br />
                        Nie można wrócić do pytania na które dało się odpowiedz
                        <br />
            <br />
                        Całkowity czas trwania testu to 40 minut.
                        <SmallText>
              Na każde pytanie masz 1 minutę. Po upływie tego czasu automatycznie wczyta się kolejne
              pytanie, jeśli nie zdążyłeś odpowiedzieć będzie ono traktowana jako błędna odpowiedz.
                        </SmallText>
          </Content>
        </SummaryContainer>
        <Image src={start} alt="start" />
      </SummaryWrapper>
      <ButtonContainer>
        <StartButton onClick={() => startExam()}>Rozpocznij test</StartButton>
      </ButtonContainer>
    </>
  )
}
