import { getCurrentUserToken } from '../../utils/auth'

const applyToken = () => {
  return next => (url, options) => {
    const headers = options.headers
    headers.Authorization = `Bearer ${getCurrentUserToken()}`
    options.headers = headers
    return next(url, options)
  }
}

export default applyToken
