import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { devices, fonts } from './../utils/constants'
import searchIcon from './../images/searchIcon.svg'

const Form = styled.form`
  background-color: #f7f9fc;
  border: none;
  margin: 10px 0;
  padding: 10px 0 10px 10px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #aaa7ac;
  display: flex;

  @media ${devices.laptop} {
    margin: 10px 20px;
  }
`

const InputSearch = styled.input`
  flex: 1 0;
  background: transparent;
  border: none;
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: #aaa7ac;
`

const ButtonSubmit = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

export const SearchForm = props => (
  <Formik
    onSubmit={values => {
      props.getList(values.search)
    }}
    initialValues={{
      search: '',
    }}
    render={formikProps => (
      <Form onSubmit={formikProps.handleSubmit}>
        <InputSearch
          type="text"
          placeholder={`${props.placeholder}`}
          name="search"
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
        />
        <ButtonSubmit type="submit">
          <img src={searchIcon} alt="search" />
        </ButtonSubmit>
      </Form>
    )}
  />
)
