import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: auto auto;
  max-width: 100%;
  gap: 2em;
  margin-bottom: 50px;

  @media only screen and (max-width: 769px) {
    grid-template-columns: 0.5fr 0.5fr;
    gap: 1em;
  }

  @media only screen and (max-width: 970px) {
    display: flex;
    flex-direction: column;
  }
`
