import React from 'react'
import styled from 'styled-components'
import 'moment/locale/pl'
import moment from 'moment'
import { fonts, colors } from '../../utils/constants'
import failed_icon from '../../images/failed.svg'
import passed_icon from '../../images/passed.svg'

const ExamsStyled = styled.div`
  grid-area: content;
`
const Start = styled.button`
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 0.8rem;
  height: 48px;
  min-width: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 0 23px 23px;
  cursor: pointer;
  background: ${colors.blue};
  color: #fff;
  border: none;
  text-decoration: none;
`

const FailedStart = styled(Start)`
  margin: 0 0 0 10px;
  color: ${(props) => (!props.active ? '#aaa7ac' : '#fff')};
  background-color: ${(props) => (!props.active ? '#fff' : colors.blue)};
`

const Title = styled.h1`
  font-family: ${fonts.mainFont};
  font-size: 1rem;
  font-weight: 700;
  color: ${colors.dark};
  width: 100%;
  margin: 0 0 23px 0;
`

const SubTitle = styled.legend`
  font-family: ${fonts.mainFont};
  font-size: 1rem;
  font-weight: 700;
  color: #aaa7ac;
  /* width: 100%; */
  margin: 23px;
`
const ResultWhite = styled.div`
  width: 100%;
  border-style: solid;
  border-width: thin;
  border-color: ${colors.blue};
  align-items: center;
`

const ResultColored = styled.div`
  width: 100%;
  background-color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
  flex-direction: row;
  font-family: ${fonts.mainFont};
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 20px 40px 20px 40px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ResultHeader = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 0.8rem;
  color: #2a242e;
  padding: 20px 40px 20px 40px;
  justify-content: center;
  flex-direction: row;
`

const Section = styled.td`
  font-family: ${fonts.mainFont};
  font-weight: 500;
  font-size: 1rem;
  padding: 5px;
`

const SectionRow = styled.tr`
  color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
`

const SectionTable = styled.table`
  display: table;
  width: 100%;
`

const TableContent = styled.div`
  margin-left: 35px;
  margin-top: 7px;
  margin-bottom: 30px;
  width: 40%;
`

const Icon = styled.img`
  height: 17px;
  width: 17px;
  margin-left: 14px;
  position: absolute;
`

const InfoText = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 0.8rem;
  color: #2a242e;
  margin-bottom: 30px;
  justify-content: center;
  flex-direction: row;
`

function checkCategory(category) {
  switch (category) {
    case 0:
      return 'Bezpieczeństwo'
    case 1:
      return 'Sprzęt'
    case 2:
      return 'Technika jazdy'
    case 3:
      return 'Metodyka nauczania'
    case 4:
      return 'Sport'
  }
}

export const Exams = ({ exams, courses }) => {
  const recentExamToStartAsystent = courses.find(
    (ex) =>
      ex.levels === 'Asystent Instruktora' &&
      ex.currentExam &&
      !ex.currentExam.approachDate &&
      !ex.lastExam
  )

  const recentExamToStartInstruktor = courses.find(
    (ex) =>
      ex.levels === 'Instruktor SITS' &&
      ex.currentExam &&
      !ex.currentExam.approachDate &&
      !ex.lastExam
  )

  const examWithResultAsystent = courses.find(
    (ex) =>
      ex.levels === 'Asystent Instruktora' &&
      ((ex.currentExam && ex.currentExam.result) || (ex.lastExam && ex.lastExam.result))
  )

  const examWithResultInstruktor = courses.find(
    (ex) =>
      ex.levels === 'Instruktor SITS' &&
      ((ex.currentExam && ex.currentExam.result) || (ex.lastExam && ex.lastExam.result))
  )

  let passedExamAsystent = true
  let passedExamInstruktor = true

  if (examWithResultAsystent) {
    if (examWithResultAsystent.currentExam && examWithResultAsystent.currentExam.result) {
      for (let item of examWithResultAsystent.currentExam.result.categories) {
        if (!item.isCategoryPassed) {
          passedExamAsystent = false
        }
      }
    } else {
      for (let item of examWithResultAsystent.lastExam.result.categories) {
        if (!item.isCategoryPassed) {
          passedExamAsystent = false
        }
      }
    }
  }

  if (examWithResultInstruktor) {
    if (examWithResultInstruktor.currentExam && examWithResultInstruktor.currentExam.result) {
      for (let item of examWithResultInstruktor.currentExam.result.categories) {
        if (!item.isCategoryPassed) {
          passedExamInstruktor = false
        }
      }
    } else {
      for (let item of examWithResultInstruktor.lastExam.result.categories) {
        if (!item.isCategoryPassed) {
          passedExamInstruktor = false
        }
      }
    }
  }

  const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)

  return (
    <ExamsStyled>
      <Title>Twoje egzaminy</Title>
      <i> {isSafari ? 'Test niedostępny na tej przeglądarce. Użyj innej przeglądarki, aby uruchomić test.' : 'Powodzenia!'} </i>
      {recentExamToStartAsystent && (
        <>
          <SubTitle>{recentExamToStartAsystent.levels}</SubTitle>
          <Start
            disabled={isSafari}
            onClick={() => window.open(`/exam?examId=${recentExamToStartAsystent.currentExam._id}`)}
            type="submit">
            {isSafari ? 'Użyj innej przeglądarki' : 'Rozpocznij test'}
          </Start>
        </>
      )}
      {recentExamToStartInstruktor && (
        <>
          <SubTitle>{recentExamToStartInstruktor.levels}</SubTitle>
          <Start
            disabled={isSafari}
            onClick={() =>
              window.open(`/exam?examId=${recentExamToStartInstruktor.currentExam._id}`)
            }
            type="submit">
            {isSafari ? 'Użyj innej przeglądarki' : 'Rozpocznij test'}
          </Start>
        </>
      )}
      {examWithResultInstruktor && (
        <>
          <SubTitle>{examWithResultInstruktor.levels}</SubTitle>
          <ResultWhite>
            <ResultHeader>
              {passedExamInstruktor ? 'Zaliczony' : 'Niezaliczony'}
              <Icon src={passedExamInstruktor ? passed_icon : failed_icon} alt="result" />
            </ResultHeader>
          </ResultWhite>
          <ResultColored passed={passedExamInstruktor}>
            Ukończyłeś egzamin z wynikiem {passedExamInstruktor ? 'pozytywnym' : 'negatywnym'}{' '}
            {examWithResultInstruktor.currentExam && examWithResultInstruktor.currentExam.result
              ? examWithResultInstruktor.currentExam.result.percentage &&
                examWithResultInstruktor.currentExam.result.percentage.toFixed(2)
              : examWithResultInstruktor.lastExam.result.percentage &&
                examWithResultInstruktor.lastExam.result.percentage.toFixed(2)}
            %
            {examWithResultInstruktor.currentExam && !examWithResultInstruktor.currentExam.result && (
              <div>
                <FailedStart
                  active={moment(examWithResultInstruktor.currentExam.canStartAfter).isBefore(
                    new Date()
                  )}
                  disabled={
                    isSafari ||
                    !moment(examWithResultInstruktor.currentExam.canStartAfter).isBefore(new Date())
                  }
                  onClick={() =>
                    window.open(`/exam?examId=${examWithResultInstruktor.currentExam._id}`)
                  }
                  type="submit">
                  {isSafari ? 'Użyj innej przeglądarki' : 'Rozpocznij test'}
                </FailedStart>
              </div>
            )}
          </ResultColored>
          <TableContent>
            <SectionTable>
              <tbody>
                {examWithResultInstruktor.currentExam && examWithResultInstruktor.currentExam.result
                  ? examWithResultInstruktor.currentExam.result.categories.map((item) => (
                      <SectionRow passed={item.isCategoryPassed}>
                        <Section>{checkCategory(item.categoryType)}</Section>
                        <Section>{item.passedQuestions}/8</Section>
                      </SectionRow>
                    ))
                  : examWithResultInstruktor.lastExam.result.categories.map((item) => (
                      <SectionRow passed={item.isCategoryPassed}>
                        <Section>{checkCategory(item.categoryType)}</Section>
                        <Section>{item.passedQuestions}/8</Section>
                      </SectionRow>
                    ))}
              </tbody>
            </SectionTable>
          </TableContent>
          {examWithResultInstruktor.lastExam &&
            examWithResultInstruktor.lastExam &&
            examWithResultInstruktor.currentExam &&
            !examWithResultInstruktor.currentExam.result && (
              <InfoText>
                Info - Możliwość przystąpienia do egzaminu zostanie umożliwiona po upływie 7 dni.
                Umów się już dziś ze swoim instruktorem na test poprawkowy
              </InfoText>
            )}
        </>
      )}
      {examWithResultAsystent && (
        <>
          <SubTitle>{examWithResultAsystent.levels}</SubTitle>
          <ResultWhite>
            <ResultHeader>
              {passedExamAsystent ? 'Zaliczony' : 'Niezaliczony'}
              <Icon src={passedExamAsystent ? passed_icon : failed_icon} alt="result" />
            </ResultHeader>
          </ResultWhite>
          <ResultColored passed={passedExamAsystent}>
            Ukończyłeś egzamin z wynikiem {passedExamAsystent ? 'pozytywnym' : 'negatywnym'}{' '}
            {examWithResultAsystent.currentExam && examWithResultAsystent.currentExam.result
              ? examWithResultAsystent.currentExam.result.percentage &&
                examWithResultAsystent.currentExam.result.percentage.toFixed(2)
              : examWithResultAsystent.lastExam.result.percentage &&
                examWithResultAsystent.lastExam.result.percentage.toFixed(2)}
            %
            {examWithResultAsystent.currentExam && !examWithResultAsystent.currentExam.result && (
              <div>
                <FailedStart
                  active={moment(examWithResultAsystent.currentExam.canStartAfter).isBefore(
                    new Date()
                  )}
                  disabled={
                    isSafari ||
                    !moment(examWithResultAsystent.currentExam.canStartAfter).isBefore(new Date())
                  }
                  onClick={() =>
                    window.open(`/exam?examId=${examWithResultAsystent.currentExam._id}`)
                  }
                  type="submit">
                  {isSafari ? 'Użyj innej przeglądarki' : 'Rozpocznij test'}
                </FailedStart>
              </div>
            )}
          </ResultColored>
          <TableContent>
            <SectionTable>
              <tbody>
                {examWithResultAsystent.currentExam && examWithResultAsystent.currentExam.result
                  ? examWithResultAsystent.currentExam.result.categories.map((item) => (
                      <SectionRow passed={item.isCategoryPassed}>
                        <Section>{checkCategory(item.categoryType)}</Section>
                        <Section>{item.passedQuestions}/8</Section>
                      </SectionRow>
                    ))
                  : examWithResultAsystent.lastExam.result.categories.map((item) => (
                      <SectionRow passed={item.isCategoryPassed}>
                        <Section>{checkCategory(item.categoryType)}</Section>
                        <Section>{item.passedQuestions}/8</Section>
                      </SectionRow>
                    ))}
              </tbody>
            </SectionTable>
          </TableContent>
          {examWithResultAsystent.lastExam &&
            examWithResultAsystent.lastExam &&
            examWithResultAsystent.currentExam &&
            !examWithResultAsystent.currentExam.result && (
              <InfoText>
                Info - Możliwość przystąpienia do egzaminu zostanie umożliwiona po upływie 7 dni.
                Umów się już dziś ze swoim instruktorem na test poprawkowy
              </InfoText>
            )}
        </>
      )}
    </ExamsStyled>
  )
}
