import React from 'react'
import styled from 'styled-components'
import { Form } from './ContactForm/Form'
import { devices, fonts, colors } from './../../utils/constants'

const ContactFormStyled = styled.section`
  display: flex;
  grid-area: contactForm;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  font-family: ${fonts.mainFont};

  @media ${devices.laptop} {
    flex-direction: column;
    margin-top: 200px;
  }
`
const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.mainFont};
  margin-left: 0;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin: 20px 0;

  @media ${devices.tablet} {
    margin: 20px 0;
  }
  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: center;
  }
`

const SectionTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 34px;
  align-self: flex-start;

  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 12px;
    background: ${colors.blue};
    position: relative;
    left: -20px;
    top: 12px;
  }

  @media ${devices.tablet} {
    font-size: 1.55rem;
    &::before {
      height: 2px;
      width: 24px;
      left: -30px;
      top: 17px;
    }
  }

  @media ${devices.laptop} {
  }
`
const SectionHeader = styled.h2`
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.33;
  margin: 20px 0;
  max-width: 50%;

  @media ${devices.tablet} {
    font-size: 3.2rem;
    line-height: 1.52;
    margin: 0;
    max-width: 50%;
  }

  @media ${devices.laptop} {
    font-size: 3rem;
    line-height: 1.33;
    max-width: 100%;
    margin-left: 100px;
  }

  @media ${devices.desktop} {
    font-size: 4.55rem;
  }
`

const AddressLine = styled.span`
  font-size: 1.1rem;
  margin: 0 0 10px 30%;
  line-height: 1.5;
  font-weight: 600;
  font-family: ${fonts.secondaryFont};

  @media ${devices.tablet} {
    font-size: 1.4rem;
    margin: 0 0 10px 50%;
  }

  @media ${devices.laptop} {
    font-size: 1rem;
    line-height: 2;
    margin: 0 0 10px 100px;
  }

  @media ${devices.desktop} {
    font-size: 1.4rem;
  }
`

export const ContactForm = () => (
  <ContactFormStyled>
    <SectionTitle>Kontakt</SectionTitle>
    <ContentWrapper>
      <Form />
      <ContactInfoWrapper>
        <SectionHeader>Bądźmy w kontakcie</SectionHeader>
        <AddressLine>ul. Wysockiego 6</AddressLine>
        <AddressLine>51-692 Wrocław, Polska</AddressLine>
        <AddressLine>biuro@sits.org.pl</AddressLine>
        <AddressLine>+48 604 615 856</AddressLine>
      </ContactInfoWrapper>
    </ContentWrapper>
  </ContactFormStyled>
)
