import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ctaBackground from '../../images/ctaBackground.jpg'
import { devices, userModalTypes, fonts, USER_ROLES } from './../../utils/constants'
import { SCHOOL_URL, ADMIN_URL } from './../../env'

const CallToActionStyled = styled.div`
  height: 420px;
  background: #ffffff url(${ctaBackground}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.mainFont};
`

const SectionHeader = styled.h2`
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
  max-width: 60%;
  text-align: center;

  @media ${devices.tablet} {
    line-height: 1.13;
    font-size: 4rem;
  }

  @media ${devices.laptop} {
    max-width: 100%;
    line-height: 1.75;
    font-size: 4rem;
    text-align: left;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    flex-direction: row;
  }
`

const Button = styled.span`
  align-self: center;
  font-family: ${fonts.mainFont};
  font-weight: 700;
  font-size: 16px;
  min-width: 188px;
  height: 58px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => (props.create ? '#fff' : 'transparent')};
  color: ${props => (props.create ? '#000' : '#fff')};
  border: ${props => (props.create ? 'none' : '1px solid #fff')};
  margin: 10px 0;

  @media ${devices.tablet} {
    margin: 0 10px;
  }
`

const LinkButton = styled(Link)`
  align-self: center;
  font-family: ${fonts.mainFont};
  font-weight: 700;
  min-width: 188px;
  height: 58px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  background: #fff;
  color: #000;
  border: none;
  margin: 10px 0;
  text-decoration: none;

  @media ${devices.tablet} {
    margin: 0 10px;
  }
`
const HrefButton = styled.a`
  align-self: center;
  font-family: ${fonts.mainFont};
  font-size: 1rem;
  font-weight: 700;
  min-width: 188px;
  height: 58px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  background: #fff;
  color: #000;
  border: none;
  margin: 10px 0;
  text-decoration: none;

  @media ${devices.tablet} {
    margin: 0 10px;
  }
`
export const CallToAction = props => (
  <CallToActionStyled>
    <SectionHeader>Rozpocznij swoją przygodę</SectionHeader>
    <ButtonContainer>
      {!props.currentUser.token && (
        <Fragment>
          <Button onClick={() => props.toggleUserModal(userModalTypes.login)}>Zaloguj się</Button>
          <Button create onClick={() => props.toggleUserModal(userModalTypes.register)}>
            Stwórz konto
          </Button>
        </Fragment>
      )}
      {props.currentUser.token && (
        <Fragment>
          <Button onClick={props.logOut}>Wyloguj się</Button>
          {props.currentUser.role === USER_ROLES.BASIC && (
            <LinkButton to="/profile">Profil - {props.currentUser.email}</LinkButton>
          )}
          {props.currentUser.role === USER_ROLES.SCHOOL && (
            <HrefButton href={SCHOOL_URL}>Panel szkoły</HrefButton>
          )}
          {props.currentUser.role === USER_ROLES.ADMIN && (
            <HrefButton href={ADMIN_URL}>Panel admina</HrefButton>
          )}
        </Fragment>
      )}
    </ButtonContainer>
  </CallToActionStyled>
)
