import {
  CURRENT_USER_EMAIL,
  CURRENT_USER_ROLE,
  CURRENT_USER_TOKEN,
  CURRENT_USER_ID,
} from './constants'
import { DOMAIN_URL } from './../env'
import Cookies from 'js-cookie'

export const setCurrentUserId = userId => {
  Cookies.set(CURRENT_USER_ID, userId, { domain: DOMAIN_URL })
}

export const setCurrentUserEmail = email => {
  Cookies.set(CURRENT_USER_EMAIL, email, { domain: DOMAIN_URL })
}
export const setCurrentUserRole = role => {
  Cookies.set(CURRENT_USER_ROLE, role, { domain: DOMAIN_URL })
}
export const setCurrentUserToken = token => {
  Cookies.set(CURRENT_USER_TOKEN, token, { domain: DOMAIN_URL })
}

export const getCurrentUserId = () => {
  return Cookies.get(CURRENT_USER_ID, { domain: DOMAIN_URL })
}

export const getCurrentUserEmail = () => {
  return Cookies.get(CURRENT_USER_EMAIL, { domain: DOMAIN_URL })
}
export const getCurrentUserRole = () => {
  return Cookies.get(CURRENT_USER_ROLE, { domain: DOMAIN_URL })
}
export const getCurrentUserToken = () => {
  return Cookies.get(CURRENT_USER_TOKEN, { domain: DOMAIN_URL })
}

export const removeUserFromLocalStorage = () => {
  Cookies.remove(CURRENT_USER_ID, { domain: DOMAIN_URL })
  Cookies.remove(CURRENT_USER_EMAIL, { domain: DOMAIN_URL })
  Cookies.remove(CURRENT_USER_ROLE, { domain: DOMAIN_URL })
  Cookies.remove(CURRENT_USER_TOKEN, { domain: DOMAIN_URL })
}
