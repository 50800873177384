import React from 'react'
import styled from 'styled-components'
import jumboDots from './../../images/jumboDots.svg'
import { devices, fonts } from './../../utils/constants'
import { API } from '../../utils/constants'

const JumbotronStyled = styled.section`
  background: #ffffff url(${`${API}/home/image`}) no-repeat right top;
  position: relative;
  grid-area: jumbotron;
  background-size: auto 356px;
  padding-bottom: 140px;

  @media ${devices.tablet} {
    background-size: auto 508px;
    padding-bottom: 220px;
  }

  @media ${devices.laptop} {
    background-size: auto 800px;
    padding-bottom: 180px;
  }

  @media ${devices.desktop} {
    background-size: auto 1080px;
    padding-bottom: 280px;
  }
`
const MainHeader = styled.h1`
  position: relative;
  z-index: 1;
  font-size: 2rem;
  width: 65%;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  margin-left: 5%;
  margin-top: 120px;

  @media ${devices.tablet} {
    font-size: 4.4rem;
    width: 70%;
    margin-left: 5%;
    margin-top: 58.4333px;
  }

  @media ${devices.laptop} {
    font-size: 4.4rem;
    width: 60%;
    margin: 80px 0 0 0;
  }

  @media ${devices.laptopL} {
    font-size: 4.5rem;
    margin: 100px 0 0 0;
  }

  @media ${devices.desktop} {
    font-size: 5.4rem;
    margin: 120px 0 0 0;
  }

  @media ${devices.desktopL} {
    font-size: 6.8rem;
    margin: 150px 0 0 0;
  }
`

const SecondaryHeader = styled.h3`
  font-family: ${fonts.secondaryFont};
  font-weight: 600;
  position: relative;
  z-index: 1;
  font-size: 0.9rem;
  width: 90%;
  line-height: 1.71;
  bottom: -150px;
  margin: 0 auto;
  text-align: center;

  @media ${devices.tablet} {
    font-size: 1.1rem;
    width: 58%;
    line-height: 2.11;
    bottom: -230px;
    margin: 0 auto;
    text-align: center;
  }

  @media ${devices.laptop} {
    font-size: 1rem;
    width: 30%;
    line-height: 2;
    bottom: 0;
    margin: 30px 0;
    text-align: left;
  }

  @media ${devices.laptopL} {
    font-size: 1.1rem;
    margin: 40px 0;
  }

  @media ${devices.desktop} {
    font-size: 1.3rem;
  }

  @media ${devices.desktopL} {
    font-size: 1.6rem;
  }
`

const StyledDots = styled.img`
  position: absolute;
  bottom: 0;
  z-index: 1;

  @media ${devices.mobileS} {
    display: none;
  }

  @media ${devices.tablet} {
    display: none;
  }

  @media ${devices.laptop} {
    display: block;
    height: 20%;
  }

  @media ${devices.desktop} {
    height: auto;
  }
`

const BackgroundCover = styled.div`
  display: block;
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  background: #fff;

  @media ${devices.mobileS} {
    width: 22%;
  }

  @media ${devices.tablet} {
    width: 22%;
  }

  @media ${devices.laptop} {
    width: 40%;
    background: #fff;
  }
`

export const Jumbotron = () => (
  <JumbotronStyled>
    <MainHeader>Instruktor SITS - zawsze właściwą drogą</MainHeader>
    <SecondaryHeader>
      Jesteśmy nauczycielami i naszym zadaniem jest stworzenie takich warunków, w których nasi
      uczniowie będą się uczyli bezpiecznie, w najbardziej dopasowany do każdego z nich sposób, w
      optymalnym tempie i co nie mniej ważne – z największym możliwym fun-em!
    </SecondaryHeader>
    <StyledDots src={jumboDots} alt="dots" />
    <BackgroundCover />
  </JumbotronStyled>
)
