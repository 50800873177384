import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'
import { userModalTypes, fonts, colors, USER_ROLES } from './../../utils/constants'
import { SCHOOL_URL, ADMIN_URL } from './../../env'

const Login = styled.span`
  color: ${colors.blue} !important;
  padding-top: 20px;
  padding-bottom: 15px;
  cursor: pointer;
  font-size: 2rem;
`
const SignUp = styled.span`
  color: ${colors.blue} !important;
  cursor: pointer;
  font-size: 2rem;
`

const Profile = styled(Link)`
  color: ${colors.blue} !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
`

const StyledLink = styled(Link)`
  padding-bottom: 6px;
  padding-top: 6px;
`

const StyledSublink = styled(Link)`
  padding-left: 15px;
  font-size: 8px;
  border-left: 2px solid black;
  padding-bottom: 6px;
  padding-top: 6px;
`

const StypedP = styled.p`
  padding-bottom: 6px;
  padding-top: 6px;
`

const Panel = styled.a`
  color: ${colors.blue} !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
`

const styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenu: {
    background: '#f7f9fc',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  bmItem: {
    display: 'inline-block',
    fontSize: '2rem',
    color: `${colors.dark}`,
    textDecoration: 'none',
    fontFamily: `${fonts.mainFont}`,
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}

export class MobileNavigation extends Component {
  state = { menuOpen: false }

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  render() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        styles={styles}
        width={'340px'}
        right>
        <StyledLink to="/" onClick={() => this.closeMenu()}>
          Home
        </StyledLink>
        <StyledLink to="/news" onClick={() => this.closeMenu()}>
          Aktualności
        </StyledLink>
        <StyledLink to="/schools" onClick={() => this.closeMenu()}>
          Szkoły
        </StyledLink>
        <StyledLink to="/courses" onClick={() => this.closeMenu()}>
          Kursy
        </StyledLink>
        <StypedP>Instruktorzy:</StypedP>
        <StyledSublink
          style={{ paddingLeft: '15px', fontSize: '24px', borderLeft: '2px solid black' }}
          to="/for-instructors"
          onClick={() => this.closeMenu()}>
          Dla instruktorów
        </StyledSublink>
        <StyledSublink
          style={{ paddingLeft: '15px', fontSize: '24px', borderLeft: '2px solid black' }}
          to="/instructors"
          onClick={() => this.closeMenu()}>
          Lista instruktorów
        </StyledSublink>
        <StyledSublink
          style={{ paddingLeft: '15px', fontSize: '24px', borderLeft: '2px solid black' }}
          to="/job-offers"
          onClick={() => this.closeMenu()}>
          Oferty pracy
        </StyledSublink>
        <StyledSublink
          style={{ paddingLeft: '15px', fontSize: '24px', borderLeft: '2px solid black' }}
          to="/discounts"
          onClick={() => this.closeMenu()}>
          Zniżki
        </StyledSublink>
        <StyledLink to="/for-parents" onClick={() => this.closeMenu()}>
          Dla rodziców
        </StyledLink>
        <StyledLink to="/travel-agency" onClick={() => this.closeMenu()}>
          Biura podróży
        </StyledLink>
        <StyledLink to="/products" onClick={() => this.closeMenu()}>
          Produkty
        </StyledLink>

        {!this.props.currentUser.token && (
          <Fragment>
            <Login
              onClick={() => {
                this.props.toggleUserModal(userModalTypes.login)
                this.closeMenu()
              }}>
              Zaloguj
            </Login>
            <SignUp
              onClick={() => {
                this.props.toggleUserModal(userModalTypes.register)
                this.closeMenu()
              }}>
              Stwórz konto
            </SignUp>
          </Fragment>
        )}
        {this.props.currentUser.token && (
          <Fragment>
            <Login
              onClick={() => {
                this.props.logOut()
                this.closeMenu()
              }}>
              Wyloguj
            </Login>
            {this.props.currentUser.role === USER_ROLES.BASIC && (
              <Profile
                to="/profile"
                onClick={() => {
                  this.closeMenu()
                }}>
                {this.props.currentUser.email}
              </Profile>
            )}
            {this.props.currentUser.role === USER_ROLES.SCHOOL && (
              <Panel
                href={SCHOOL_URL}
                onClick={() => {
                  this.closeMenu()
                }}>
                Panel szkoły
              </Panel>
            )}
            {this.props.currentUser.role === USER_ROLES.ADMIN && (
              <Panel
                href={ADMIN_URL}
                onClick={() => {
                  this.closeMenu()
                }}>
                Panel admina
              </Panel>
            )}
          </Fragment>
        )}
      </Menu>
    )
  }
}
