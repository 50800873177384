import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { callApi } from '../utils/api'
import { API, colors, devices, fonts } from '../utils/constants'
import { DesktopTile } from './../components/courses/DesktopTile'
import { MobileTile } from './../components/courses/MobileTile'
import { produce } from 'immer'
import DatePicker from 'react-datepicker'
import moment from 'moment'

function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const CoursesList = styled.div`
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-areas:
    '. header .'
    '. main .'
    '. more .';
`

const Header = styled.div`
  grid-area: header;

  h2 {
    font-family: ${fonts.mainFont};
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2;
    color: ${colors.dark};
  }
`
const MainMobile = styled.div`
  grid-area: main;
  display: block;

  @media ${devices.tablet} {
    display: none;
  }
`

const DesktopLayout = styled.div`
  grid-area: main;
  display: none;

  @media ${devices.tablet} {
    display: block;
    width: 100%;
  }
`

const MainDesktop = styled.table`
  display: none;

  @media ${devices.tablet} {
    display: table;
    width: 100%;
  }
`

const DesktopTableHeader = styled.thead`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: #aaa7ac;
  margin: 0 0 20px 0;

  th {
    text-align: center !important;
    padding: 0 0 20px 0;
  }
`
const NoResults = styled.span`
  font-size: 1.1rem;
  line-height: 2;
  font-weight: 600;
  margin: 0;
  text-align: center;
  grid-area: main;
  @media ${devices.tablet} {
    font-size: 2rem;
    line-height: normal;
  }

  @media ${devices.laptop} {
    margin: 0 20px;
  }

  @media ${devices.desktop} {
    font-size: 3rem;
    line-height: 1.52;
  }
`

const More = styled.button`
  grid-area: more;
  margin: 50px auto;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1.6rem;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '.4' : '1')};
  background: transparent;
  border: none;
  &::before {
    display: block;
    content: '';
    background: ${colors.blue};
    position: absolute;
    height: 10px;
    width: 166px;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`

const RenderListMobile = (props) => {
  return props.coursesList.map((c) => {
    return <MobileTile course={c} key={c._id} />
  })
}

const RenderListDesktop = (props) => {
  return props.coursesList.map((c) => {
    return <DesktopTile course={c} key={c._id} />
  })
}

const FiltersWrapper = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  font-family: ${fonts.mainFont};
  margin-bottom: 3em;

  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const FiltersInputWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: #aaa7ac;
  width: 100%;

  @media ${devices.tablet} {
    &:not(:last-of-type) {
      margin-right: 12px;
    }
  }

  input {
    height: 32px;
    font-family: ${fonts.mainFont};
    width: 100%;
  }

  select {
    height: 32px;
    font-family: ${fonts.mainFont};
  }

  div.react-datepicker__input-container {
    width: 100%;
  }
`

function FiltersDesktop({ setFilters }) {
  const [levels, setLevels] = React.useState([])
  const [showFilters, setShowFilters] = React.useState(true)
  const [{ from, to }, setFromTo] = React.useState({
    from: moment(new Date()).format('YYYY-MM-DD'),
    to: moment(new Date().setFullYear(new Date().getFullYear() + 1)).format('YYYY-MM-DD'),
  })

  React.useEffect(() => {
    callApi
      .url(`${API}/coursetype/website`)
      .get()
      .json((res) => {
        setLevels(res)
      })
  }, [])

  const setSingleFilter = React.useCallback((name, value) => {
    setFilters(
      produce((draft) => {
        draft.filters[name] = value
      })
    )
  })

  console.log(from, to)

  return (
    <>
      <div style={{ margin: '1em 0', color: colors.blue }}>
        <span
          style={{ cursor: 'pointer', fontFamily: fonts.mainFont }}
          onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? 'Ukryj' : 'Pokaż'} filtry
        </span>
      </div>
      <FiltersWrapper hidden={!showFilters}>
        <FiltersInputWrapper>
          <label style={{ marginBottom: '5px' }} htmlFor="start">
            Od:
          </label>
          <DatePicker
            id="start"
            name="courseStart"
            placeholderText="Od"
            onChange={(date) => {
              setFromTo({ from: moment(date).format('YYYY-MM-DD'), to })
              setSingleFilter('courseStart', moment(date).format('YYYY-MM-DD'))
            }}
            selected={moment(from)}
            value={from}
          />
        </FiltersInputWrapper>
        <FiltersInputWrapper>
          <label style={{ marginBottom: '5px' }} htmlFor="end">
            Do:
          </label>
          <DatePicker
            id="end"
            name="courseEnd"
            placeholderText="Do"
            onChange={(date) => {
              setFromTo({ from, to: moment(date).format('YYYY-MM-DD') })
              setSingleFilter('courseEnd', moment(date).format('YYYY-MM-DD'))
            }}
            value={to}
            selected={moment(to)}
          />
        </FiltersInputWrapper>
        <FiltersInputWrapper>
          <label style={{ marginBottom: '5px' }} htmlFor="level">
            Stopień:
          </label>
          <select
            id="level"
            name="courseLevel"
            onChange={(e) => setSingleFilter(e.target.name, e.target.value)}>
            <option value={''}>Wszystkie</option>
            {levels.map((level) => (
              <option value={level.title} key={level._id}>
                {level.title}
              </option>
            ))}
          </select>
        </FiltersInputWrapper>
        <FiltersInputWrapper>
          <label style={{ marginBottom: '5px' }} htmlFor="school">
            Szkoła:
          </label>
          <input
            type="text"
            id="school"
            name="courseSchool"
            onChange={(e) => setSingleFilter(e.target.name, e.target.value)}
          />
        </FiltersInputWrapper>
        <FiltersInputWrapper>
          <label style={{ marginBottom: '5px' }} htmlFor="manager">
            Osoba kontakotowa:
          </label>
          <input
            type="text"
            id="manager"
            name="courseManager"
            onChange={(e) => setSingleFilter(e.target.name, e.target.value)}
          />
        </FiltersInputWrapper>
      </FiltersWrapper>
    </>
  )
}

class Courses extends Component {
  state = {
    coursesList: [],
    readMorePage: 2,
    disableReadMore: false,
    filters: {
      courseManager: '',
      courseSchool: '',
      courseLevel: '',
      courseStart: '',
      courseEnd: '',
    },
  }

  componentDidMount = () => {
    const filters = this.state.filters
    filters.courseStart = moment(new Date()).format('YYYY-MM-DD')
    filters.courseEnd = moment(new Date().setFullYear(new Date().getFullYear() + 1)).format('YYYY-MM-DD')
    const params = new URLSearchParams(filters).toString()
    callApi
      .url(`${API}/course/website?quantity=5${params}`)
      .get()
      .json((res) => {
        this.setState({ coursesList: res.data, disableReadMore: false })
      })
      .catch((e) => console.error(e))
  }

  componentDidUpdate(_, prevState) {
    const params = new URLSearchParams(this.state.filters).toString()
    if (params !== new URLSearchParams(prevState.filters).toString()) {
      callApi
        .url(`${API}/course/website?quantity=5&${params}`)
        .get()
        .json((res) => {
          this.setState({ coursesList: res.data, disableReadMore: false, readMorePage: 2 })
        })
        .catch((e) => console.error(e))
    }
  }

  LoadMoreCourses = () => {
    const params = new URLSearchParams(this.state.filters).toString()
    callApi
      .url(`${API}/course/website?quantity=5&${params}&page=${this.state.readMorePage}`)
      .get()
      .json((res) => {
        this.setState((prevState) => {
          return {
            coursesList: [...prevState.coursesList, ...res.data],
            readMorePage: prevState.readMorePage + 1,
            disableReadMore: res.data.length === 0 ? true : false,
          }
        })
      })
      .catch((e) => console.error(e))
  }

  render() {
    return (
      <CoursesList>
        <Header>
          <h2>Nadchodzące kursy</h2>
          <FiltersDesktop setFilters={this.setState.bind(this)} />
        </Header>
        {this.state.coursesList.length === 0 && <NoResults>Brak kursów</NoResults>}
        {this.state.coursesList.length !== 0 && (
          <Fragment>
            <MainMobile>
              <RenderListMobile coursesList={this.state.coursesList} />
            </MainMobile>
            <DesktopLayout>
              <MainDesktop>
                <DesktopTableHeader>
                  <tr>
                    <th>Czas trwania</th>
                    <th>Stopień</th>
                    <th>Miejsce kursu</th>
                    <th>Szkoła</th>
                    <th>Osoba kontaktowa</th>
                  </tr>
                </DesktopTableHeader>
                <tbody>
                  <RenderListDesktop coursesList={this.state.coursesList} />
                </tbody>
              </MainDesktop>
            </DesktopLayout>
            <More onClick={this.LoadMoreCourses} disabled={this.state.disableReadMore}>
              Pokaż więcej kursów
            </More>
          </Fragment>
        )}
      </CoursesList>
    )
  }
}

export default Courses
