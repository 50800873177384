import React, { useState } from 'react'
import styled from 'styled-components'
import { fonts } from '../../utils/constants'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { colors } from '../../utils/constants'
import { ResultQuestion } from './ResultQuestion'
import sitsLogo from '../../images/sitsLogo.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 90%;
  padding: 0 1rem 0 1rem;
  margin: 0 auto;
  padding-top: 70px;
`

const Wrapper = styled.div`
  padding-top: 70px;
`

const Header = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 1.1rem;
  color: #707070;
  margin-bottom: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ResultColored = styled.div`
  width: 100%;
  background-color: ${props => (props.passed ? `#12d381` : `#ff4e84`)};
  flex-direction: row;
  font-family: ${fonts.mainFont};
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 20px 40px 20px 40px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Section = styled.td`
  font-family: ${fonts.mainFont};
  font-weight: 500;
  font-size: 1rem;
  padding: 5px;
  width: 33.33%;
  float: ${props => (props.right ? `right` : `left`)};
`

const SectionRow = styled.tr`
  color: ${props => !props.selected && (props.passed ? `#12d381` : `#ff4e84`)};
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const SectionTable = styled.table`
  display: table;
  width: 100%;
`

const TableContent = styled.div`
  margin-bottom: 40px;
  width: 100%;
`
const Dropdown = styled.button`
  width: 100%;
  border-radius: 22px;
  background-color: ${props => (props.selected ? (props.passed ? `#12d381` : `#ff4e84`) : `#fff`)};
  color: ${props => (!props.selected ? (props.passed ? `#12d381` : `#ff4e84`) : `#fff`)};
  border: none;
  cursor: pointer;
`

const Question = styled.div`
  color: ${props => (props.passed ? `#12d381` : `#ff4e84`)};
  font-family: ${fonts.mainFont};
  font-weight: 500;
  font-size: 1rem;
  padding-left: 25px;
  margin-top: 16px;
  margin-bottom: -4px;
  cursor: pointer;
`
const Arrow = styled.i`
  border-style: solid;
  color: ${props => (!props.selected ? `#ff4e84` : `#fff`)};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 5px;
  margin-left: 85%;
`

const ReturnButton = styled.button`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 1.1rem;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  padding: 15px 75px 15px 75px;
  cursor: pointer;
  background: ${colors.blue};
  color: #fff;
  border: none;
  text-decoration: none;
  &:focus {
    outline: 0 0 0 0;
  }
  margin-top: 80px;
`

const QuestionRow = styled.tr``

function checkCategory(category) {
  switch (category) {
    case 0:
      return 'Bezpieczeństwo'
    case 1:
      return 'Sprzęt'
    case 2:
      return 'Technika jazdy'
    case 3:
      return 'Metodyka nauczania'
    case 4:
      return 'Sport'
  }
}

export default function ResultList({ result, shortResult, level }) {
  const [selected, setSelected] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  })

  const [questionDetails, setQuestionDetails] = useState(null)

  return (
    <Grid>
      <Row>
        <Col xs={12} md={6}>
          <Container>
            <Header>
              <img
                src={sitsLogo}
                alt="sits-logo"
                height="105"
                width="105"
                style={{ marginRight: 39 }}
              />
              Test {level}
            </Header>
            <ResultColored passed={shortResult.passed}>
              Ukończyłeś egzamin z wynikiem {shortResult.passed ? 'pozytywnym' : 'negatywnym'}{' '}
              {shortResult.percentage && shortResult.percentage.toFixed(2)}%
            </ResultColored>
            <TableContent>
              <SectionTable>
                <tbody>
                  <SectionRow passed={result.result.categories[0].isCategoryPassed}>
                    <Dropdown
                      onClick={() => {
                        setSelected({
                          first: !selected.first,
                          second: selected.second,
                          third: selected.third,
                          fourth: selected.fourth,
                          fifth: selected.fifth,
                        })
                      }}
                      passed={result.result.categories[0].isCategoryPassed}
                      selected={selected.first}
                      disabled={result.result.categories[0].isCategoryPassed}>
                      <Section>{checkCategory(result.result.categories[0].categoryType)}</Section>
                      <Section>{result.result.categories[0].passedQuestions}/8</Section>
                      {!result.result.categories[0].isCategoryPassed && (
                        <Section>
                          <Arrow right selected={selected.first} />
                        </Section>
                      )}
                    </Dropdown>
                  </SectionRow>
                  {selected.first &&
                    result.result.categories[0].questions.map(q => {
                      return q.answerId === q.correctAnswerId ? null : (
                        <QuestionRow>
                          <Question
                            style={{ fontWeight: questionDetails === q ? '700' : '500' }}
                            onClick={() => setQuestionDetails(q)}>
                            {q.question && q.question.content}
                          </Question>
                        </QuestionRow>
                      )
                    })}
                  <SectionRow passed={result.result.categories[1].isCategoryPassed}>
                    <Dropdown
                      onClick={() => {
                        setSelected({
                          first: selected.first,
                          second: !selected.second,
                          third: selected.third,
                          fourth: selected.fourth,
                          fifth: selected.fifth,
                        })
                      }}
                      passed={result.result.categories[1].isCategoryPassed}
                      selected={selected.second}
                      disabled={result.result.categories[1].isCategoryPassed}>
                      <Section>{checkCategory(result.result.categories[1].categoryType)}</Section>
                      <Section>{result.result.categories[1].passedQuestions}/8</Section>
                      {!result.result.categories[0].isCategoryPassed && (
                        <Section>
                          <Arrow right selected={selected.second} />
                        </Section>
                      )}
                    </Dropdown>
                  </SectionRow>
                  {selected.second &&
                    result.result.categories[1].questions.map(q => {
                      return q.answerId === q.correctAnswerId ? null : (
                        <QuestionRow>
                          <Question
                            style={{ fontWeight: questionDetails === q ? '700' : '500' }}
                            onClick={() => setQuestionDetails(q)}>
                            {q.question && q.question.content}
                          </Question>
                        </QuestionRow>
                      )
                    })}
                  <SectionRow passed={result.result.categories[2].isCategoryPassed}>
                    <Dropdown
                      onClick={() => {
                        setSelected({
                          first: selected.first,
                          second: selected.second,
                          third: !selected.third,
                          fourth: selected.fourth,
                          fifth: selected.fifth,
                        })
                      }}
                      passed={result.result.categories[2].isCategoryPassed}
                      selected={selected.third}
                      disabled={result.result.categories[2].isCategoryPassed}>
                      <Section>{checkCategory(result.result.categories[2].categoryType)}</Section>
                      <Section>{result.result.categories[2].passedQuestions}/8</Section>
                      {!result.result.categories[0].isCategoryPassed && (
                        <Section>
                          <Arrow right selected={selected.third} />
                        </Section>
                      )}
                    </Dropdown>
                  </SectionRow>
                  {selected.third &&
                    result.result.categories[2].questions.map(q => {
                      return q.answerId === q.correctAnswerId ? null : (
                        <QuestionRow>
                          <Question
                            style={{ fontWeight: questionDetails === q ? '700' : '500' }}
                            onClick={() => setQuestionDetails(q)}>
                            {q.question && q.question.content}
                          </Question>
                        </QuestionRow>
                      )
                    })}
                  <SectionRow passed={result.result.categories[3].isCategoryPassed}>
                    <Dropdown
                      onClick={() => {
                        setSelected({
                          first: selected.first,
                          second: selected.second,
                          third: selected.third,
                          fourth: !selected.fourth,
                          fifth: selected.fifth,
                        })
                      }}
                      passed={result.result.categories[3].isCategoryPassed}
                      selected={selected.fourth}
                      disabled={result.result.categories[3].isCategoryPassed}>
                      <Section>{checkCategory(result.result.categories[3].categoryType)}</Section>
                      <Section>{result.result.categories[3].passedQuestions}/8</Section>
                      {!result.result.categories[0].isCategoryPassed && (
                        <Section>
                          <Arrow right selected={selected.fourth} />
                        </Section>
                      )}
                    </Dropdown>
                  </SectionRow>
                  {selected.fourth &&
                    result.result.categories[3].questions.map(q => {
                      return q.answerId === q.correctAnswerId ? null : (
                        <QuestionRow>
                          <Question
                            style={{ fontWeight: questionDetails === q ? '700' : '500' }}
                            onClick={() => setQuestionDetails(q)}>
                            {q.question && q.question.content}
                          </Question>
                        </QuestionRow>
                      )
                    })}
                  <SectionRow passed={result.result.categories[4].isCategoryPassed}>
                    <Dropdown
                      onClick={() => {
                        setSelected({
                          first: selected.first,
                          second: selected.second,
                          third: selected.third,
                          fourth: selected.fourth,
                          fifth: !selected.fifth,
                        })
                      }}
                      passed={result.result.categories[4].isCategoryPassed}
                      selected={selected.fifth}
                      disabled={result.result.categories[4].isCategoryPassed}>
                      <Section>{checkCategory(result.result.categories[4].categoryType)}</Section>
                      <Section>{result.result.categories[4].passedQuestions}/8</Section>
                      {!result.result.categories[0].isCategoryPassed && (
                        <Section>
                          <Arrow right selected={selected.fifth} />
                        </Section>
                      )}
                    </Dropdown>
                  </SectionRow>
                  {selected.fifth &&
                    result.result.categories[4].questions.map(q => {
                      return q.answerId === q.correctAnswerId ? null : (
                        <QuestionRow>
                          <Question
                            style={{ fontWeight: questionDetails === q ? '700' : '500' }}
                            onClick={() => setQuestionDetails(q)}>
                            {q.question && q.question.content}
                          </Question>
                        </QuestionRow>
                      )
                    })}
                </tbody>
              </SectionTable>
            </TableContent>
          </Container>
        </Col>
        <Col xs={12} md={6}>
          <Wrapper>{questionDetails && <ResultQuestion question={questionDetails} />}</Wrapper>
          <ReturnButton onClick={() => window.open('/')}>Przejdź do strony głównej</ReturnButton>
        </Col>
      </Row>
    </Grid>
  )
}
