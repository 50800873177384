import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'

import sitsLogo from '../../images/sitsLogo.svg'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Line, Circle } from 'rc-progress'
import { fonts, colors } from '../../utils/constants'
import { mapCategoryEnumToText } from './Question'
import ReactPlayer from 'react-player'

const SelectMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <circle id="Ellipse_60" cx="9" cy="9" r="9" fill="#4e72ff" data-name="Ellipse 60" />
  </svg>
)

const NextQuestion = styled.button`
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 0.8rem;
  width: 50%;
  height: 48px;
  min-width: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  background: ${(props) => (props.disabled ? '#e0e0e0' : colors.blue)};
  color: #fff;
  border: none;
  text-decoration: none;
`

const Checkmark = styled.div`
  border-radius: 50%;
  min-width: 33px;
  max-width: 33px;
  height: 33px;
  border: 2px solid #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`

const Input = styled.div`
  border-radius: 31px;
  padding: 10px;
  background: ${(props) => props.checked && `#4e72ff`};
  &:hover {
    background: ${(props) => !props.checked && `#d9e1ff`};
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 18px;
  max-width: 600px;
`

const Content = styled.span`
  color: #707070;
  padding-left: 35px;
  color: ${(props) => (props.checked ? `#FFFFFF` : `#707070`)};
`

function AnswerRadio({ content, checked, onClick }) {
  return (
    <Input checked={checked} onClick={onClick}>
      <Checkmark checked={checked}>{checked && <SelectMark />}</Checkmark>
      <Content checked={checked}>{content}</Content>
    </Input>
  )
}

AnswerRadio = React.memo(AnswerRadio)

const Category = styled.p`
  font-size: 20px;
  color: #707070;
  margin-bottom: 33px;
  margin-top: 65px;
`
const HeaderContainer = styled.div`
  font-size: 14px;
  float: left;
`
const Top = styled.div`
  height: 101px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-left: -5%;
  margin-right: -5%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 30px 0 30px;
`

const Bottom = styled.div`
  height: 89px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-left: -5%;
  margin-right: -5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

const MediaContainer = styled.p`
  margin-top: 27px;
`

const QuestionContent = styled.p`
  font-size: 30px;
  color: #707070;
  margin-bottom: 66px;
`

const Medium = ({ media, startMeasure }) => {
  const [isImage, setIsImage] = useState(undefined)
  const link = media
  const image = useRef(null)
  useEffect(() => {
    const img = new Image()
    img.src = link
    img.onload = () => {
      setIsImage(true)
      startMeasure()
    }
    img.onerror = (e) => {
      console.log(e)
      setIsImage(false)
    }
    image.current = img
  }, [media])

  useEffect(() => {
    if (isImage) startMeasure()
  }, [isImage])

  if (!media) return null
  if (isImage === undefined) return <p>Loading...</p>

  if (isImage)
    return (
      <div className="embed-responsive-16by9">
        <img style={{ maxWidth: '100%', height: 'auto' }} src={image.current.src} />
      </div>
    )
  else
    return (
      <div style={{ maxWidth: '100%' }}>
        <ReactPlayer
          url={media}
          playing={true}
          loop={true}
          onDuration={(duration) => {
            setTimeout(() => startMeasure(), duration * 1000)
          }}
          width="100%"
          height="100%"
        />
      </div>
    )
}
export function QuestionMobile({
  content,
  category,
  answers,
  goToNext,
  _id,
  level,
  currentQuestionNumber,
  totalQuestions,
  answer,
  media,
}) {
  const [remainingTime, setRemainingTime] = useState(60)
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [shouldMeasureTime, setShouldMeasureTime] = useState(false)

  const startMeasure = useCallback(() => {
    setShouldMeasureTime(true)
  }, [])

  useEffect(() => {
    setRemainingTime(60)
    setShouldMeasureTime(false)
    setSelectedAnswer(null)
  }, [_id])

  useEffect(() => {
    if (!media && _id) startMeasure()
  }, [media, _id, startMeasure])

  useEffect(() => {
    let timeInterval
    if (shouldMeasureTime) {
      timeInterval = setInterval(() => {
        setRemainingTime((before) => before - 1)
      }, 1000)
    }
    return () => clearInterval(timeInterval)
  }, [shouldMeasureTime])

  useEffect(() => {
    if (remainingTime === 0) {
      setRemainingTime(60)
      setShouldMeasureTime(false)
      answer(_id, selectedAnswer)
      goToNext()
    }
  }, [remainingTime])

  return (
    <React.Fragment>
      <Top>
        <div style={{ clear: 'both' }}>
          <HeaderContainer
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: '100%',
              paddingRight: '0px',
              paddingLeft: '12px',
            }}>
            <img src={sitsLogo} style={{ width: '53px', height: '53px', marginRight: '20px' }} />
            <span style={{ fontSize: '14px', color: '#707070' }}>{level}</span>
          </HeaderContainer>
          <div style={{ minWidth: '50px', float: 'right', paddingRight: '12px' }}>
            <CountdownCircleTimer
              key={_id}
              isPlaying={shouldMeasureTime}
              size={50}
              duration={60}
              strokeWidth={4}
              colors={[['#4e72ff']]}
              onComplete={() => [false, 0]}>
              {(rt) => remainingTime}
            </CountdownCircleTimer>
          </div>
        </div>
      </Top>
      <Grid
        style={{ paddingRight: '0px', paddingLeft: '0px', paddingTop: '130px', width: '100%', marginBottom: '89px' }}>
        <Row>
          <Col xs={12}>
            <div
              style={{
                display: 'flex',
              }}>
              <p style={{ minWidth: '100px' }}>
                {currentQuestionNumber} / {totalQuestions}
              </p>
              <div style={{ width: '100%' }}>
                <Line
                  percent={(currentQuestionNumber / totalQuestions) * 100}
                  strokeWidth="1"
                  strokeColor="#d9e1ff"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {media && (
            <Col xs={12} sm={6}>
              <MediaContainer>
                <Medium startMeasure={startMeasure} media={media} />
              </MediaContainer>
            </Col>
          )}
          <Col xs={12} sm={media ? 6 : 12}>
            <Category>{mapCategoryEnumToText(category)}</Category>
            <QuestionContent>{content}</QuestionContent>
            {answers.map((ans, index) => (
              <AnswerRadio
                key={ans.id}
                checked={selectedAnswer === ans.id}
                onClick={() => setSelectedAnswer(ans.id)}
                content={ans.content}
              />
            ))}
          </Col>
        </Row>
      </Grid>
      <Bottom>
        <div style={{ margin: 'auto' }}>
          <NextQuestion
            disabled={selectedAnswer === null}
            onClick={async () => {
              await answer(_id, selectedAnswer)
              goToNext()
            }}>
            Następne pytanie
          </NextQuestion>
        </div>
      </Bottom>
    </React.Fragment>
  )
}
