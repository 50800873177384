import React, { Component } from 'react'
import styled from 'styled-components'
import { Jumbotron } from './home/Jumbotron'
import { News } from './home/News'
import { FbFeed } from './home/FbFeed'
import { Courses } from './home/Courses'
import { Booklet } from './home/Booklet'
import { Sponsors } from './home/Sponsors'
import { ContactForm } from './home/ContactForm'
import { devices } from '../utils/constants'

const HomeGrid = styled.main`
  display: grid;
  position: relative;
  overflow: hidden;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: auto auto auto minmax(450px, auto) auto auto;
  grid-template-areas:
    ' jumbotron jumbotron jumbotron'
    '. news .'
    '. mobileFbfeed .'
    '. courses .'
    '. booklet .'
    '. sponsors .'
    '. contactForm .';

  @media ${devices.laptop} {
    grid-template-columns: 146px auto 80px;
    grid-template-rows: auto auto auto minmax(850px, auto) auto auto;
    grid-template-areas:
      ' . jumbotron jumbotron'
      '. news .'
      '. courses .'
      'booklet booklet booklet'
      '. sponsors .'
      '. contactForm .';
  }

  @media ${devices.desktop} {
    grid-template-columns: 246px auto 80px;
  }
`

class Home extends Component {
  render() {
    return (
      <HomeGrid>
        <Jumbotron />
        <News />
        <FbFeed />
        <Courses />
        <Booklet />
        <Sponsors />
        <ContactForm />
      </HomeGrid>
    )
  }
}

export default Home
