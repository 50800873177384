import React from 'react'
import styled, { css } from 'styled-components'
import { withFormik } from 'formik'
import { callApi } from '../utils/api'
import { API } from '../utils/constants'

const Form = styled.form`
  display: flex;
  width: 30%;
  flex-direction: column;
  margin: 0 auto;
  button {
    height: 30px;
  }
`

const Input = styled.input`
  margin: 10px 0;

  ${props =>
    props.invalid &&
    css`
      border: 2px solid red;
    `};
`

let ChangePassword = props => {
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        name="token"
        type="text"
        placeholder="Wpisz token"
        value={values.token}
        onChange={handleChange}
        required
      />
      <Input
        name="newPassword"
        type="password"
        placeholder="Wpisz nowe hasło"
        value={values.newPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        minLength={6}
        invalid={touched && errors.password}
        required
      />
      <Input
        name="newPasswordRepeat"
        type="password"
        placeholder="Powtórz nowe hasło"
        value={values.newPasswordRepeat}
        onChange={handleChange}
        onBlur={handleBlur}
        minLength={6}
        invalid={touched && errors.password}
        required
      />
      <button type="submit">SUBMIT</button>
    </Form>
  )
}

ChangePassword = withFormik({
  mapPropsToValues: () => ({ token: ``, newPassword: '', newPasswordRepeat: '' }),
  validate: values => {
    let errors = {}
    if (values.newPassword !== values.newPasswordRepeat) {
      errors.password = true
    }
    return errors
  },
  handleSubmit: values => {
    callApi
      .url(`${API}/user/password-recovery/recover`)
      .post({ token: values.token, newPassword: values.newPassword })
      .res(res => console.log(res))
      .catch(e => {
        console.error(e)
      })
  },
})(ChangePassword)

export default ChangePassword
