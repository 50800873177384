import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { API, fonts, colors } from './../../utils/constants'
import { getCurrentUserToken } from './../../utils/auth'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

registerPlugin(FilePondPluginImagePreview)

const CardStyled = styled.form`
  grid-area: content;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: baseline;

  .filepond--wrapper {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
    div {
      width: 100%;
    }
  }

  .react-datepicker-popper {
    z-index: 11;
  }
`
const CardDataWrapper = styled.div`
  margin: 0 0 20px 0;
`

const CardData = styled.div`
  width: 100%;
  margin: 0 0 0 2px;
  font-size: 1.1rem;
`

const Legend = styled.legend`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.dark};
  width: 100%;
  margin: 0 0 20px 0;
`
const ImgPreview = styled.div`
  width: 100%;
  text-align: center;

  img {
    width: 40%;
    max-height: 250px;
  }
`

export class Card extends Component {
  state = {
    imgPreviewError: false,
  }

  setImgPreviewError = () => {
    this.setState({ imgPreviewError: true })
  }

  render() {
    const cardNumber = this.props.cardInfo.cardNumber ? this.props.cardInfo.cardNumber : 'Brak'
    const cardExpirationDate = this.props.cardInfo.cardExpirationDate
      ? moment(this.props.cardInfo.cardExpirationDate).format('DD/MM/YYYY')
      : 'Brak'

    return (
      <CardStyled>
        <Legend>Zdjęcie legitymacyjne</Legend>
        {!this.state.imgPreviewError && (
          <ImgPreview>
            <img
              src={`${API}/instructor/card/image/${this.props.userId}?t=${Date.now()}`}
              alt="preview"
              onError={this.setImgPreviewError}
            />
          </ImgPreview>
        )}
        <FilePond
          allowMultiple={false}
          allowRevert={false}
          server={{
            process: {
              url: `${API}/instructor/card`,
              headers: { Authorization: `Bearer ${getCurrentUserToken()}` },
            },
          }}
          acceptedFileTypes={['image/jpeg', 'image/png']}
          labelIdle={
            'Przeciągnij plik lub <span class="filepond--label-action">Przeglądaj</span> aby dodać główny obrazek w formacie JPEG lub PNG'
          }
          labelFileloading={'Ładuje plik'}
          labelFileLoadError={'Błąd podczas wgrywania pliku'}
          labelFileProcessing={'Wgrywam plik'}
          labelFileProcessingComplete={'Plik wgrany'}
          labelFileProcessingAborted={'Ładowanie pliku przerwane'}
          labelFileProcessingError={'Błąd podczas wgrywania pliku'}
          labelTapToCancel={'Naciśnij aby przerwać'}
          labelTapToRetry={'Naciśnij aby spróbować ponownie'}
        />
        <Legend>Dane</Legend>
        <CardDataWrapper>
          <CardData>Numer - {cardNumber}</CardData>
          <CardData>Data ważności - {cardExpirationDate}</CardData>
        </CardDataWrapper>
      </CardStyled>
    )
  }
}
