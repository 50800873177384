import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  max-width: 100%;
  gap: 2em;
  margin-bottom: 50px;

  @media only screen and (max-width: 769px) {
    gap: 1em;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`
