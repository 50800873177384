import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { callApi } from './../../utils/api'
import submitNewsletterIcon from './../../images/submitNewsletterIcon.svg'
import { API, devices, fonts, colors } from './../../utils/constants'

const FormWrapper = styled.span`
  order: 1;
  width: 100%;
  @media ${devices.laptop} {
    order: 4;
    width: 600px;
  }
`

const FormHeader = styled.h4`
  font-family: ${fonts.mainFont};
  font-size: 1.45rem;
  font-weight: 700;
  line-height: 1.58;
  max-width: 100%;
  margin: 20px auto;
  text-align: center;

  @media ${devices.tablet} {
    max-width: 70%;
    text-align: center;
  }

  @media ${devices.laptop} {
    margin: 20px 50px;
    max-width: 60%;
    text-align: left;
  }
`
const FormText = styled.p`
  max-width: 60%;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.78;
  display: none;

  @media ${devices.tablet} {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  @media ${devices.laptop} {
    text-align: left;
    margin: 20px 50px;
  }
`

const SignToNewsletterFrom = styled.form`
  display: flex;
  justify-content: center;
  position: relative;

  @media ${devices.laptop} {
    justify-content: flex-start;
    margin: 20px 50px;
  }
`
const InputMail = styled.input`
  background: #f7f9fc;
  height: 80px;
  border: none;
  width: calc(60% + 48px);
  padding: 0 0 0 20px;
  font-family: ${fonts.mainFont};
  font-weight: 700;
  font-size: 1rem;

  @media ${devices.tablet} {
    width: calc(30% + 48px);
  }

  @media ${devices.laptop} {
    width: calc(60% + 48px);
  }
`

const ButtonSubmit = styled.button`
  background: #f7f9fc;
  height: 80px;
  border: none;
  transform: translateX(-48px);
`

const StatusMessage = styled.div`
  margin: 0 48px;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  display: block;
  height: 13.5px;
  transform: translateY(18px);
  position: absolute;
  bottom: 0;
  color: ${colors.blue};
`

export class NewsletterForm extends Component {
  state = {
    isFormSubmited: false,
  }

  render() {
    return (
      <Formik
        onSubmit={values => {
          callApi
            .url(`${API}/newsletter/signup`)
            .post({ email: values.email })
            .res(() => {
              this.setState({
                isFormSubmited: true,
              })
            })
            .catch(e => console.error(e))
        }}
        initialValues={{
          email: '',
        }}>
        {props => {
          const { values, handleChange, handleBlur, handleSubmit } = props

          return (
            <FormWrapper>
              <FormHeader>Dołącz do naszego newslettera aby być z nami w kontakcie</FormHeader>
              <FormText>
                Najważniejsze informacje dla obecnych i przyszłych instruktorów snowboardu
              </FormText>
              <SignToNewsletterFrom onSubmit={handleSubmit}>
                <InputMail
                  type="email"
                  placeholder="Wpisz email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <ButtonSubmit type="submit" disabled={this.state.isFormSubmited}>
                  <img src={submitNewsletterIcon} alt="submit icon" />
                </ButtonSubmit>
                <StatusMessage>
                  {this.state.isFormSubmited && (
                    <span>Twój email został dodany do naszego newslettera</span>
                  )}
                </StatusMessage>
              </SignToNewsletterFrom>
            </FormWrapper>
          )
        }}
      </Formik>
    )
  }
}
