import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Button = styled.button`
  border-radius: 30px;
  background-color: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.textColor};
  padding: 7px ${(props) => props.paddingX || 7}px;
  margin: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 33px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.36;
  text-decoration: none;
  cursor: pointer;
  width: ${(props) => props.width};
`
export default Button

export const ButtonLink = styled(Link)`
  border-radius: 30px;
  background-color: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.textColor};
  padding: 7px ${(props) => props.paddingX || 7}px;
  margin: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 33px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.36;
  text-decoration: none;
  cursor: pointer;
  width: ${(props) => props.width};
`
