import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors } from './../../utils/constants'
import { MobileTile } from './../../components/courses/MobileTile'
import { DesktopTile } from './../../components/courses/DesktopTile'
import { InstructorDetailsPersonalInfo } from './instructorDetailsPersonalInfo/InstructorDetailsPersonalInfo'

const InstructorDetailsStyled = styled.div`
  display: grid;
  color: ${colors.dark};
  grid-template-columns: 20px auto 20px;
  grid-template-rows: auto auto;
  grid-template-areas:
    ' . details .'
    ' . courses .';

  @media ${devices.tablet} {
    grid-template-columns: 40px auto 40px;
  }

  @media ${devices.laptop} {
    grid-template-columns: 0.2fr 1fr 3fr 0.2fr;
    grid-template-rows: minmax(500px, auto);
    grid-template-areas: '. details courses .';
    grid-column-gap: 80px;
  }

  @media ${devices.desktop} {
    grid-template-columns: 1fr 1.2fr 3fr 1fr;
  }
`

const Courses = styled.div`
  grid-area: courses;
`
const Header = styled.h2`
  grid-area: header;
  font-family: ${fonts.mainFont};
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 20px 0;
  color: ${colors.dark};
`

const MobileList = styled.div`
  display: block;
  @media ${devices.tablet} {
    display: none;
  }
`
const DesktopList = styled.table`
  display: none;
  @media ${devices.tablet} {
    display: table;
    width: 100%;
  }
`
const DesktopTableHeader = styled.thead`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  color: #aaa7ac;
  margin: 0 0 20px 0;

  th {
    text-align: center !important;
    padding: 0 0 20px 0;
  }
`

const RenderMobileList = props => {
  return props.coursesList.map(c => {
    return <MobileTile course={c} key={c._id} profile="true" />
  })
}

const RenderListDesktop = props => {
  return props.coursesList.map(c => {
    return <DesktopTile course={c} key={c._id} profile="true" />
  })
}

export const InstructorDetails = props => (
  <InstructorDetailsStyled>
    <InstructorDetailsPersonalInfo instructorDetails={props.instructorDetails} />
    <Courses>
      <Header>Ukończone kursy</Header>
      <MobileList>
        <RenderMobileList coursesList={props.instructorDetails.courses} />
      </MobileList>
      <DesktopList>
        <DesktopTableHeader>
          <tr>
            <th>Czas trwania</th>
            <th>Stopień</th>
            <th>Rola</th>
            <th>Miejsce kursu</th>
            <th>Szkoła</th>
          </tr>
        </DesktopTableHeader>
        <tbody>
          <RenderListDesktop coursesList={props.instructorDetails.courses} />
        </tbody>
      </DesktopList>
    </Courses>
  </InstructorDetailsStyled>
)
