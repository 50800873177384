import { API_URL } from './../env'

export const USER_TOKEN_KEY = 'USER_TOKEN'
export const CURRENT_USER_ID = 'CURRENT_USER_ID'
export const CURRENT_USER_EMAIL = 'CURRENT_USER_EMAIL'
export const CURRENT_USER_ROLE = 'CURRENT_USER_ROLE'
export const CURRENT_USER_TOKEN = 'CURRENT_USER_TOKEN'
export const API = API_URL

export const USER_ROLES = {
  BASIC: 'basic',
  SCHOOL: 'school',
  ADMIN: 'admin',
}

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '767px',
  laptop: '1024px',
  laptopPlus: '1100px',
  laptopL: '1440px',
  desktop: '1920px',
  desktopL: '2560',
}

export const devices = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopPlus: `(min-width: ${size.laptopPlus})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
}

export const userModalTypes = {
  login: 'login',
  register: 'register',
  changePass: 'changePass',
  basic: 'basic',
  school: 'school',
  active: 'active',
  newsletter: 'newsletter',
}

export const editProfilePageOptions = [
  { value: 'personalInfo', label: 'Twoje dane' },
  { value: 'card', label: 'Legitymacja' },
  { value: 'courseHistory', label: 'History kursów' },
  { value: 'exams', label: 'Egzaminy' }
]

export const fonts = {
  mainFont: 'Segoe',
  secondaryFont: 'Open Sans',
}

export const colors = {
  dark: '#2a242e',
  blue: '#4e72ff',
}
