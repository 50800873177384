import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { withFormik } from 'formik'
import { callApiFormData } from './../../utils/api'
import CreatableSelect from 'react-select/lib/Creatable'
import { devices, fonts, colors } from './../../utils/constants'
import DatePicker from 'react-datepicker'
import { API } from '../../utils/constants'
import PropTypes from 'prop-types'
import { AddFeaturedImage } from './imageUploadForm'
import MediaQuery from 'react-responsive'
import { ClipLoader } from 'react-spinners'
import moment from 'moment'

const Form = styled.form`
  height: 100%;
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  z-index: 100;
  padding: 0;

  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }

  @media ${devices.desktop} {
    width: 70vw;
  }
`

const DescriptionLabel = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  margin: 20px 0 10px 0;
`

const InputStyled = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 5.6px;
  background-color: #f6f8f9;
  margin: 0 0 10px 0;
  border: none;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  width: 100%;
  ::placeholder {
    font-size: 14px;
    color: #aaa7ac;
  }

  @media ${devices.tablet} {
    width: ${props => (props.width ? props.width : '100%')};
    margin: ${props => (props.customMargin ? props.customMargin : '0 0 10px 0')};
    border-right: ${props => (props.customBorder ? props.customBorder : 'none')};
  }
`

const TextAreaStyled = styled.textarea`
  width: 100%;
  border-radius: 5.6px;
  background-color: #f6f8f9;
  margin: 0 0 10px 0;
  padding: 20px;
  border: none;
  resize: none;
  ::placeholder {
    font-size: 14px;
    color: #aaa7ac;
  }
`

const StartDateInput = styled.div`
  height: 35px;
  vertical-align: middle;
  border-radius: 5.6px;
  background-color: #f6f8f9;
  margin: 0 0 8px 0px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  font-size: 14px;
`

const ImageUploader = styled.div`
  width: 100%;
  height: auto;
  border-radius: 5.6px;
  margin: 0 0 8px 0;
  border: none;
  ::placeholder {
    font-size: 14px;
    color: #aaa7ac;
  }
`

const InLineDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`

const FormHeader = styled.h3`
  margin: 40px auto 10px auto;
  font-weight: 700;
  font-size: 1.9rem;
  text-align: center;

  @media ${devices.tablet} {
    margin: 20px auto 10px auto;
  }
`
const FormDescription = styled.p`
  margin: 20px auto;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
`

const LoginButton = styled.button`
  background: ${props => (props.loading ? 'grey' : `${colors.blue}`)};
  margin: 70px auto 0 auto;
  min-width: 60%;
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;
  border-radius: 30px;
  border: none;
  height: 47px !important;
  cursor: pointer;
  display: block;
  margin-bottom: 15px;
`
const LeftSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 48px;

  @media ${devices.laptop} {
    width: 67%;
    margin: 0 40px 0 80px;
  }

  @media ${devices.desktop} {
    width: 67%;
    margin: 0 70px 0 150px;
  }
`

const RightSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 48px;

  @media ${devices.laptop} {
    width: 33%;
    margin: 0 80px 0 40px;
  }

  @media ${devices.desktop} {
    width: 33%;
    margin: 0 140px 0 70px;
  }
`

const StatusMessage = styled.div`
  font-size: 1rem;
  font-family: ${fonts.secondaryFont};
  display: block;
  text-align: center;
  color: green;
  max-width: 295px;
  width: 100%;
  margin: 0 auto;

  @media ${devices.tablet} {
    max-width: 548px;
  }

  @media ${devices.laptop} {
    max-width: 90%;
  }
`

const CertsCustomStyles = {
  container: () => ({
    width: `100%`,
  }),
}

const ErrorMessage = styled.span`
  margin: 0 0 0 0;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  color: #b33a3a;
  display: block;
  height: 13.5px;
  transform: translateY(-20px);
  margin-top: 10px;
`

class UserRegisterForm extends Component {
  componentDidMount() {
    this.props.setStatus({
      loader: false,
      isSubmitted: false,
    })
    this.setState({
      error: false,
    })
  }

  render() {
    const { values, status, handleSubmit, handleChange, setFieldValue } = this.props
    if (!status) return null
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <FormHeader>Konto użytkownika</FormHeader>
          <FormDescription>Uzupełnij dane w celu znalezienia się na stronie</FormDescription>
          <InLineDiv>
            <LeftSide>
              <MediaQuery maxWidth={1023}>
                <DescriptionLabel>Twoje zdjęcie</DescriptionLabel>
                <ImageUploader>
                  <AddFeaturedImage uploadFeaturedImage={file => setFieldValue('file', file)} />
                </ImageUploader>
              </MediaQuery>
              <DescriptionLabel>Podstawowe informacje</DescriptionLabel>
              <InputStyled
                name="firstName"
                type="text"
                placeholder="Imie"
                value={values.firstName}
                onChange={handleChange}
                width={'calc(50% - 10px)'}
                customMargin={'0 10px 10px 0'}
                required
              />
              <InputStyled
                name="lastName"
                type="text"
                placeholder="Nazwisko"
                value={values.lastName}
                onChange={handleChange}
                width={'50%'}
                required
              />
              <InputStyled
                name="pesel"
                type="text"
                placeholder="Pesel"
                value={values.pesel}
                onChange={event => {
                  let pesel = event.target.value.toString()
                  if (pesel.length === 11) {
                    let year = pesel.slice(0, 2)
                    let month = pesel.slice(2, 4)
                    if (parseInt(month) > 20) {
                      month = (parseInt(month) - 20).toString()
                      year = '20' + year
                    } else {
                      year = '19' + year
                    }
                    let day = pesel.slice(4, 6)

                    values.birthDate = new Date(year, (parseInt(month) - 1).toString(), day)
                    setFieldValue('birthDate', new moment(values.birthDate))
                  }
                  if (pesel.length !== 0) {
                    let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
                    let sum = 0
                    let controlNumber = parseInt(pesel.substring(10, 11))
                    for (let i = 0; i < weight.length; i++) {
                      sum += parseInt(pesel.substring(i, i + 1)) * weight[i]
                    }
                    sum = sum + controlNumber

                    if (sum % 10 !== 0 && !isNaN(controlNumber)) {
                      this.setState({
                        error: true,
                      })
                    } else {
                      this.setState({
                        error: false,
                      })
                    }
                  }
                  if (pesel.length === 0) {
                    this.setState({
                      error: false,
                    })
                  }
                  handleChange(event)
                }}
              />
              <ErrorMessage>
                {this.state.error && <span>Pesel jest nieprawidłowy</span>}
              </ErrorMessage>
              <DescriptionLabel>O sobie</DescriptionLabel>
              <TextAreaStyled
                type="textarea"
                name="about"
                placeholder="Napisz o sobie w kilku zdaniach"
                rows="5"
                value={values.about}
                onChange={handleChange}
              />
              <DescriptionLabel>Certyfikaty</DescriptionLabel>
              <CreatableSelect
                isMulti
                onChange={value => setFieldValue('certificates', value)}
                value={values.certificates}
                styles={CertsCustomStyles}
                noOptionsMessage={() => null}
                placeholder="Dodaj certyfikat..."
                formatCreateLabel={inputValue => 'Dodaj ' + inputValue}
              />
              <DescriptionLabel>Dane dodatkowe</DescriptionLabel>
              <DatePicker
                name="birthDate"
                placeholderText="Data urodzenia"
                onChange={date => setFieldValue('birthDate', date)}
                selected={values.birthDate}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={50}
                customInput={<CustomInputForDate />}
                required
              />
              <InputStyled
                name="birthplace"
                type="text"
                placeholder="Miejsce urodzenia"
                value={values.birthplace}
                onChange={handleChange}
              />
            </LeftSide>
            <RightSide>
              <MediaQuery minWidth={1024}>
                <DescriptionLabel>Twoje zdjęcie</DescriptionLabel>
                <ImageUploader>
                  <AddFeaturedImage uploadFeaturedImage={file => setFieldValue('file', file)} />
                </ImageUploader>
              </MediaQuery>
              <DescriptionLabel>Adres zameldowania</DescriptionLabel>
              <InputStyled
                name="street"
                type="text"
                placeholder="Ulica"
                value={values.street}
                onChange={handleChange}
              />
              <InputStyled
                name="postCode"
                type="text"
                placeholder="Kod pocztowy"
                value={values.postCode}
                onChange={handleChange}
                width={'calc(45% - 10px)'}
                customMargin={'0 10px 10px 0'}
              />
              <InputStyled
                name="city"
                type="text"
                placeholder="Miejscowość"
                value={values.city}
                onChange={handleChange}
                width={'55%'}
              />
              <DescriptionLabel>Dane kontaktowe</DescriptionLabel>
              <InputStyled
                name="phone"
                type="text"
                placeholder="Telefon"
                value={values.phone}
                onChange={handleChange}
              />
              <InputStyled
                name="website"
                type="text"
                placeholder="Strona WWW"
                value={values.website}
                onChange={handleChange}
              />
            </RightSide>
          </InLineDiv>
          {!status.isSubmitted ? (
            <LoginButton
              disabled={status.loader || this.state.error}
              loading={status.loader}
              type="submit">
              {!status.loader ? (
                'Utwórz profil użytkownika'
              ) : (
                <ClipLoader sizeUnit={'px'} size={15} color={'#ecf0ff'} loading={values.loader} />
              )}
            </LoginButton>
          ) : (
            <Fragment>
              <StatusMessage>
                Twoje konto zostało utworzone, aktywuj je za pomocą odnośnika we wiadomości email.
              </StatusMessage>
              <LoginButton onClick={this.props.closeSelf}>Zamknij</LoginButton>
            </Fragment>
          )}
        </Form>
      </React.Fragment>
    )
  }
}

let UserRegisterFormHigherOrderComponent = withFormik({
  mapPropsToValues: () => ({
    file: null,
    city: '',
    street: '',
    birthplace: '',
    birthDate: undefined,
    website: '',
    phone: '',
    postCode: '',
    about: '',
    certificates: [],
    firstName: '',
    lastName: '',
    pesel: '',
  }),
  handleSubmit: (values, { props, state, setStatus }) => {
    const doCer = val => {
      return val.map(c => {
        return c.value
      })
    }

    const model = {
      ...values,
      ...props.registerFormValues,
      certificates: doCer(values.certificates),
      cardDate: values.cardDate ? values.cardDate.toISOString() : null,
      birthDate: values.birthDate ? values.birthDate.toISOString() : null,
    }

    setStatus({ loader: true })
    callApiFormData
      .url(`${API}/user`)
      .formData(model)
      .post()
      .res(res => {
        setStatus({ loader: false, isSubmitted: true })
      })
      .catch(e => {
        setStatus({ loader: false })
        console.error(e)
      })
  },
})(UserRegisterForm)

export default UserRegisterFormHigherOrderComponent

class CustomInputForDate extends React.Component {
  render() {
    return (
      <StartDateInput onClick={this.props.onClick}>
        {this.props.value ? this.props.value : 'Data urodzenia'}
      </StartDateInput>
    )
  }
}

CustomInputForDate.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
}
