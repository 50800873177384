import React from 'react'
import styled from 'styled-components'
import { fonts, colors } from '../../utils/constants'
import { Link } from 'react-router-dom'
import sitsLogo from '../../images/sitsLogo.svg'
import failed_img from '../../images/failed.png'
import passed_img from '../../images/passed.png'

const SummaryWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: grid;
  grid-template-columns: 2.6fr 3.4fr;
  grid-template-areas: '. content .';
`

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 3rem 2rem 5rem;
  margin: 0;
`

const ResultColored = styled.div`
  width: 100%;
  background-color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
  flex-direction: row;
  font-family: ${fonts.mainFont};
  color: white;
  font-weight: 700;
  font-size: 2.4rem;
  padding: 13px 15px 13px 15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ResultSummary = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
  border: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 41px;
`

const Percentage = styled.div`
  font-size: 2rem;
  font-weight: 600;
  width: 30%;
  color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
  padding-left: 21px;
`

const CheckButton = styled.button`
  width: 70%;
  height: 65px;
  color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
  border-color: ${(props) => (props.passed ? `#12d381` : `#ff4e84`)};
  background-color: #fff;
  border: 2px solid;
  border-radius: 33px;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 131px;
  cursor: pointer;
  &:focus {
    outline: 0 0 0 0;
  }
`

const ReturnButton = styled.button`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 1.1rem;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  padding: 15px 75px 15px 75px;
  cursor: pointer;
  background: ${colors.blue};
  color: #fff;
  border: none;
  text-decoration: none;
  &:focus {
    outline: 0 0 0 0;
  }
`

const Header = styled.div`
  font-family: ${fonts.mainFont};
  font-weight: 400;
  font-size: 1.1rem;
  color: #707070;
  margin-bottom: 78px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Image = styled.img`
  max-width: 60%;
  height: auto;
  align-self: center;
  justify-self: flex-end;
`

export default function ShortSummary({ result, level, onReturn, onCheck }) {
  return (
    <SummaryWrapper>
      <SummaryContainer>
        <Header>
          <img
            src={sitsLogo}
            alt="sits-logo"
            height="105"
            width="105"
            style={{ marginRight: 39 }}
          />
          Test {level}
        </Header>
        <ResultColored passed={result.passed}>
          {result.passed ? 'Gratulujemy!!!' : 'Niestety'}
        </ResultColored>
        <ResultSummary passed={result.passed}>
          Ukończyłeś egzamin z wynikiem {result.passed ? 'pozytywnym' : 'negatywnym'}{' '}
          <Percentage passed={result.passed}>{Number(result.percentage).toFixed(2)}%</Percentage>
        </ResultSummary>
        <CheckButton onClick={() => onCheck()} passed={result.passed}>
          Sprawdź odpowiedzi
        </CheckButton>
        <ReturnButton onClick={() => onReturn()}>Przejdź do strony głównej</ReturnButton>
      </SummaryContainer>
      <Image src={result.passed ? passed_img : failed_img} alt="result" />
    </SummaryWrapper>
  )
}
