import React, { Component } from 'react'
import styled from 'styled-components'
import { callApiWithAuth, callApiWithAuthFormData } from '../utils/api'
import { API, devices, fonts, colors } from '../utils/constants'
import { Navbar } from './profile/Navbar'
import { PersonalInfo } from './profile/PersonalInfo'
import { Exams } from './profile/Exams'
import { Card } from './profile/Card'
import { CourseHistory } from './profile/CourseHistory'

const ProfileWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: auto auto;
  grid-template-areas:
    '. navbar .'
    ' . content .';

  @media ${devices.tablet} {
    grid-template-columns: 40px auto 40px;
    grid-template-rows: auto minmax(500px, auto);
  }

  @media ${devices.laptop} {
    grid-template-columns: 0.6fr 1.8fr 3fr 0.6fr;
    grid-template-rows: minmax(500px, auto);
    grid-template-areas: '. navbar content .';
    grid-column-gap: 80px;
  }

  @media ${devices.laptopL} {
    grid-template-columns: 1fr 1.2fr 3fr 1fr;
    grid-template-areas: '. navbar content .';
    grid-column-gap: 80px;
  }
`

const RenderContent = props => {
  const coursesWithExam = props.personalInfo.courses ? props.personalInfo.courses.filter(
    course => course.levels === 'Asystent Instruktora' || course.levels === 'Instruktor SITS'
  ) : []

  switch (props.profilePage.value) {
    case 'personalInfo':
      return (
        <PersonalInfo
          personalInfo={props.personalInfo}
          updatePersonalInfo={props.updatePersonalInfo}
        />
      )
    case 'card':
      return (
        <Card
          cardInfo={props.cardInfo}
          updateCardInfo={props.updateCardInfo}
          userId={props.personalInfo._id}
        />
      )
    case 'courseHistory':
      return <CourseHistory courseHistory={props.personalInfo.courses} />
    case 'exams':
      return <Exams exams={props.personalInfo.exams} courses={coursesWithExam} />
    default:
      return null
  }
}

class Profile extends Component {
  state = {
    profilePage: { value: 'personalInfo', label: 'Twoje dane' },
    personalInfo: {},
  }

  componentDidMount() {
    callApiWithAuth
      .url(`${API}/instructor/details/basic`)
      .get()
      .json(res => {
        this.setState({ personalInfo: res })
      })
      .catch(e => console.error(e))

    callApiWithAuth
      .url(`${API}/instructor/details/card`)
      .get()
      .json(res => {
        this.setState({ cardInfo: res })
      })
      .catch(e => console.error(e))
  }

  modelCertificatesForApi = certificates => {
    if (certificates.length === 0) return ''
    return certificates.map(c => {
      return c.value
    })
  }

  updatePersonalInfo = values => {
    const data = {
      ...values,
      birthDate: values.birthDate ? values.birthDate.toISOString() : '',
      certificates: this.modelCertificatesForApi(values.certificates),
    }

    callApiWithAuthFormData
      .url(`${API}/instructor/basic`)
      .formData(data)
      .put()
      .json(res => {
        this.setState({ personalInfo: res._doc })
      })
      .catch(e => console.error(e))
  }

  updateCardInfo = values => {
    const data = {
      ...values,
      cardDate: values.cardDate.toISOString(),
    }

    callApiWithAuthFormData
      .url(`${API}/instructor/card`)
      .formData(data)
      .put()
      .json(res => {
        this.setState({ cardInfo: res })
      })
      .catch(e => console.error(e))
  }

  changeProfilePage = page => {
    this.setState({
      profilePage: page,
    })
  }

  render() {
    if (!this.state.personalInfo.email) return null
    return (
      <ProfileWrapper>
        <Navbar
          changeProfilePage={this.changeProfilePage}
          profilePage={this.state.profilePage}
          personalInfo={this.state.personalInfo}
        />
        <RenderContent
          profilePage={this.state.profilePage}
          personalInfo={this.state.personalInfo}
          cardInfo={this.state.cardInfo}
          updatePersonalInfo={this.updatePersonalInfo}
          updateCardInfo={this.updateCardInfo}
        />
      </ProfileWrapper>
    )
  }
}

export default Profile
