import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors } from './../../utils/constants'
import { Link } from 'react-router-dom'
import coursesDots from './../../images/coursesDots.svg'
import coursesMock from './../../images/coursesMock.svg'

const CoursesStyled = styled.section`
  grid-area: courses;
  display: flex;
  color: ${colors.dark};
  font-family: ${fonts.mainFont};
  position: relative;
  overflow: hidden;
  flex-direction: column;
  margin-top: 0;

  @media ${devices.tablet} {
    margin-top: 100px;
  }
`

const TextWrapper = styled.div``

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
`

const SectionTitle = styled.span`
  font-size: 1.1rem;
  margin-left: 34px;
  font-weight: 600;

  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 12px;
    background: ${colors.blue};
    position: relative;
    left: -20px;
    top: 12px;
  }

  @media ${devices.tablet} {
    font-size: 1.55rem;

    &::before {
      height: 2px;
      width: 24px;
      left: -30px;
      top: 17px;
    }
  }
`

const SectionHeader = styled.h2`
  font-weight: 600;
  font-size: 1.3rem;
  margin: 0;

  @media ${devices.tablet} {
    font-size: 3.55rem;
    line-height: 1.52;
    width: 75%;
  }

  @media ${devices.laptop} {
    font-size: 3.8rem;
    line-height: 1.25;
    width: 100%;
  }

  @media ${devices.desktop} {
    font-size: 4.55rem;
    line-height: 1.33;
  }
`
const SectionParagraph = styled.p`
  font-weight: 600;
  font-family: ${fonts.secondaryFont};
  margin: 65vw 0 40px 0;
  font-size: 0.9rem;
  line-height: 1.71;
  text-align: center;

  @media ${devices.mobileL} {
    margin: 57vw 0 40px 0;
  }

  @media ${devices.tablet} {
    font-size: 1.1rem;
    line-height: 2.11;
    max-width: 60%;
    text-align: left;
    margin: 100px 0 40px 0;
  }

  @media ${devices.laptop} {
    font-size: 1.3rem;
    line-height: 2;
  }

  @media ${devices.desktop} {
    font-size: 1.6rem;
    line-height: 2;
    max-width: 70%;
  }
`

const ReadMore = styled(Link)`
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  position: relative;
  margin: 0 0 30px 0;
  color: ${colors.dark};
  &::before {
    display: block;
    content: '';
    background: ${colors.blue};
    position: absolute;
    height: 10px;
    width: 99px;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media ${devices.tablet} {
    font-size: 1.6rem;
    margin: 20px 0 30px 0;
  }

  @media ${devices.laptop} {
    font-size: 1.6rem;
    &::before {
      height: 10px;
      width: 166px;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 60px;

  @media ${devices.tablet} {
    width: 55%;
    position: absolute;
    right: -100px;
    top: 231px;
  }

  @media ${devices.laptop} {
    width: 100%;
    position: static;
  }
`

const StyledDots = styled.img`
  display: none;
  position: absolute;

  @media ${devices.laptop} {
    display: block;
    bottom: 0;
    left: 20%;
  }
`

export const Courses = () => (
  <CoursesStyled>
    <SectionTitle>Kursy</SectionTitle>
    <ContentWrapper>
      <TextWrapper>
        <SectionHeader>Skorzystaj z profesjonalnych kursów szkoleniowych</SectionHeader>
        <SectionParagraph>
          Każdy kurs prowadzony przez szkoły z licencją i patronatem SITS musi być zaakceptowany
          przez SITS. Sprawdź listę aktualnych szkoleń.
        </SectionParagraph>
      </TextWrapper>
      <ImageWrapper>
        <img src={coursesMock} alt="courses" width="100%" />
      </ImageWrapper>
    </ContentWrapper>
    <StyledDots src={coursesDots} alt="dots" />
    <ReadMore to="/courses">Zobacz kursy</ReadMore>
  </CoursesStyled>
)
