import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { API, fonts, colors } from './../utils/constants'
import { callApi } from './../utils/api'
import styled from 'styled-components'
import { CallToAction } from './footer/CallToAction'
import { NewsletterForm } from './footer/NewsletterForm'
import { devices } from './../utils/constants'
import sitsLogo from './../images/sitsLogo.svg'
import facebookIcon from './../images/fbIcon.svg'
import instagramIcon from './../images/instaIcon.svg'
import youtubeIcon from './../images/ytIcon.svg'

const FooterStyled = styled.footer`
  font-family: ${fonts.secondaryFont};
  color: ${colors.dark};
`
const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

const LogoContainter = styled.div`
  order: 2;
  img {
    margin: 23.4px 0 0 0;
  }

  @media ${devices.laptop} {
    order: 1;
  }
`

const ColumnHeader = styled.h4`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.58;
`
const SitemapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.78;
  order: 3;
  width: 100%;
  margin: 0 50px;

  a {
    font-size: 1rem;
    text-decoration: none;
    font-weight: 600;

    &:visited {
      color: ${colors.dark};
    }
  }

  @media ${devices.tablet} {
    margin: 0;
    order: 4;
    width: auto;
  }

  @media ${devices.laptop} {
    order: 3;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  order: 4;
  width: 100%;
  margin: 0 50px;

  @media ${devices.tablet} {
    margin: 0;
    order: 3;
    width: auto;
  }

  @media ${devices.laptop} {
    order: 2;
  }
`

const BankAccount = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 320px;
`
const NumberHeader = styled.span`
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.78;
  margin-right: 10px;
`

const Number = styled.span`
  font-size: 1rem;
  line-height: 1.75;
  font-weight: 600;
`

const Copywrite = styled.div`
  height: 99px;
  border-top: 2px solid #e5e7e9;
  display: flex;
  margin: 0 50px;

  @media ${devices.laptop} {
    margin: 0 100px;
  }
`

const CopywriteText = styled.span`
  font-family: ${fonts.mainFont};
  font-weight: 600;
  flex-grow: 1;
  align-self: center;
  margin: 0;
  font-size: 1rem;
  line-height: 2;
  cursor: pointer;
`
const SocialLink = styled.a`
  display: flex;
  margin: 0 10px;
  &:last-child {
    margin: 0 0 0 10px;
  }
`

export class Footer extends Component {
  state = {}

  componentDidMount() {
    callApi
      .url(`${API}/setting`)
      .get()
      .json()
      .then(res => {
        this.setState(...res)
      })
      .catch(e => {
        console.error(e)
      })
  }

  render() {
    return (
      <FooterStyled>
        <CallToAction
          toggleUserModal={this.props.toggleUserModal}
          currentUser={this.props.currentUser}
          logOut={this.props.logOut}
        />
        <FooterContent>
          <LogoContainter>
            <img src={sitsLogo} alt="sitsLogo" />
          </LogoContainter>
          <Info>
            <ColumnHeader>Rachunek Bankowy</ColumnHeader>
            <BankAccount>
              <span>{this.state.bankName}</span>
              <span>{this.state.bankAccount}</span>
              <span>{this.state.sitsAddress}</span>
            </BankAccount>
            <div>
              <NumberHeader>NIP</NumberHeader>
              <Number>8992672875</Number>
            </div>
            <div>
              <NumberHeader>REGON</NumberHeader>
              <Number>020991305</Number>
            </div>
          </Info>
          <SitemapWrapper>
            <ColumnHeader>Sitemap</ColumnHeader>
            <Link to="/news">Aktualności</Link>
            <Link to="/courses">Kursy</Link>
            <Link to="/instructors">Instruktorzy</Link>
            <Link to="/schools">Szkoły</Link>
            <Link to="/materials">Materiały</Link>
            <Link to="/about">O stowarzyszeniu</Link>
            <Link to="/privacy">Polityka prywatności</Link>
            <Link to="/terms">Warunki korzystania</Link>
          </SitemapWrapper>
          <NewsletterForm />
        </FooterContent>
        <Copywrite>
          <CopywriteText>©Copyright 2018 Sits</CopywriteText>
          <SocialLink href="https://www.facebook.com/SITSsnow" target="_blank">
            <img src={facebookIcon} alt="facebook" />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/sitssnowboard/" target="_blank">
            <img src={instagramIcon} alt="instagram" />
          </SocialLink>
          <SocialLink
            href="https://www.youtube.com/channel/UChtHsg4aX7U-nhjKgCJCOSA"
            target="_blank">
            <img src={youtubeIcon} alt="youtube" />
          </SocialLink>
        </Copywrite>
      </FooterStyled>
    )
  }
}
