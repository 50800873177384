import * as React from 'react'
import styled from 'styled-components'
import { usePaginatedQuery } from 'react-query'
import { API, colors, devices, fonts } from '../../utils/constants'
import Button, { ButtonLink } from '../../new-components/button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 90%;
  padding: 0 1rem 0 1rem;
  margin: 0 auto;
  padding-top: 70px;
`

const TileWrapper = styled.div`
  padding: 24px;

  &:hover {
    box-shadow: 4px 4px 12px 0 rgba(36, 72, 253, 0.12);
  }
`

const TileImageWrapper = styled.div`
  position: relative;
  flex: 1 0;
  margin-right: 24px;
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const TileHeaderWrapper = styled.div`
  flex: 2 0;
`

const TileHeader = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #2a242e;
`

const TileUpper = styled.div`
  display: flex;
  color: #6e7aac;
  font-size: 12px;
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  width: 100%;
  gap: 16px;

  @media ${devices.tablet} {
    display: grid;
  }
`

const StickyImageContainer = styled.div`
  max-width: 400px;
  margin-right: 64px;
  display: none;
  @media ${devices.laptopL} {
    display: block;
  }
`

const More = styled.button`
  margin: 50px auto;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 1.6rem;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '.4' : '1')};
  background: transparent;
  border: none;
  &::before {
    display: block;
    content: '';
    background: ${colors.blue};
    position: absolute;
    height: 10px;
    width: 166px;
    top: 47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`

const TileContentText = styled.p`
  font-size: 12px;
  line-height: 1.5;
`

const TileContentHighlight = styled(TileContentText)`
  color: ${colors.blue};
`
const Tile = ({ header, info, city, id, page, img }) => {
  if (!/^https?:\/\//i.test(page)) {
    page = 'http://' + page
  }
  return (
    <TileWrapper>
      <TileUpper>
        <TileImageWrapper>
          <img src={API + img} alt="img" />
        </TileImageWrapper>
        <TileHeaderWrapper>
          <TileHeader>{header}</TileHeader>
          <TileContentText>{info}</TileContentText>
        </TileHeaderWrapper>
      </TileUpper>
      <div
        style={{
          display: 'flex',
          placeContent: 'center',
          justifyContent: 'space-evenly',
          marginTop: '16px',
        }}>
        <ButtonLink
          to={`/job-offers?id=${id}`}
          textColor="#fff"
          bgColor={colors.blue}
          borderColor={colors.blue}
          width="140px">
          Zobacz więcej
        </ButtonLink>
        {page && (
          <ButtonLink
            to={{ pathname: page }}
            target="_blank"
            rel="noopener"
            paddingX={7}
            textColor={colors.blue}
            bgColor="#fff"
            borderColor={colors.blue}
            width="140px">
            {(page || '').replace(/https?:\/\//, '')}
          </ButtonLink>
        )}
      </div>
    </TileWrapper>
  )
}

const OfferDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  h1 {
    font-size: 56px;
    font-weight: bold;
    line-height: 1.29;
    text-align: center;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    color: #4e72ff;
    margin-top: 32px;
  }
`

const OfferDetailsImage = styled.img`
  margin-top: 32px;
  max-width: 900px;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
`

const OfferContent = styled.div`
  img {
    width: 100%;
  }

  margin-top: 56px;
  margin-bottom: 56px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  max-width: 900px;
  width: 100%;
  overflow-wrap: break-word;
`

const NoResults = styled.div`
  font-size: 1.1rem;
  line-height: 2;
  font-weight: 600;
  text-align: center;
`

const OfferSubject = styled.div`
  padding-top: 24px;
  width: 100%;
  max-width: 900px;
  padding-bottom: 24px;
  background-color: #f7f9fc;
  text-align: center;
  margin-bottom: 24px;
`

const OfferSubjectHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 12px;
`

const OfferSubjectContent = styled.p`
  margin-bottom: 20px;
`

function OfferDetails({ offer }) {
  let website = offer.subjectWebsite

  if (!/^https?:\/\//i.test(website)) {
    website = 'http://' + website
  }
  return (
    <OfferDetailsWrapper>
      <h1>{offer.header}</h1>
      <OfferDetailsImage src={API + offer.imageUrl} />
      <OfferContent dangerouslySetInnerHTML={{ __html: offer.content }} />
      <OfferSubject>
        <OfferSubjectHeader>Skontaktuj się z nami:</OfferSubjectHeader>
        <OfferSubjectContent>{offer.offerSubject}</OfferSubjectContent>
        {website && (
          <ButtonLink
            to={{ pathname: website }}
            target="_blank"
            rel="noopener"
            paddingX={27}
            textColor={colors.blue}
            bgColor="#fff"
            borderColor={colors.blue}
            width="140px">
            {(website || '').replace(/https?:\/\//, '')}
          </ButtonLink>
        )}
      </OfferSubject>
    </OfferDetailsWrapper>
  )
}

function OfferList({ allData, latestData, page, setPage }) {
  return (
    <>
      <StickyImageContainer>
        <p style={{ position: 'sticky', top: '32px' }}>
          <img src="https://api.sits.org.pl/school/image" alt="schools" />
        </p>
      </StickyImageContainer>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Grid>
          {allData.map((offer) => {
            return (
              <Tile
                img={offer.imageUrl}
                info={offer.offerSubject}
                key={offer._id}
                header={offer.header}
                id={offer._id}
                page={offer.subjectWebsite}
              />
            )
          })}
        </Grid>
        {latestData && latestData.totalItems !== page && latestData.totalItems !== 0 && (
          <More
            onClick={() =>
              setPage((old) => (!latestData || latestData.totalItems === old ? old : old + 1))
            }>
            Pokaż więcej
          </More>
        )}
        {latestData && latestData.totalItems === 0 && <NoResults>Brak ofert</NoResults>}
      </div>
    </>
  )
}

export default function JobOffers() {
  const [allData, setAllData] = React.useState(null)
  const [page, setPage] = React.useState(1)
  const [selectedOffer, setSelectedOffer] = React.useState(null)
  const [details, setDetails] = React.useState(null)

  const fetchOffers = React.useCallback(async (key, page = 1) => {
    const req = await fetch(
      `${API}/job-offer/website?quantity=4&column=addDate&descending=true&page=${page}`
    )
    return await req.json()
  }, [])

  const { status, resolvedData, latestData, error, refetch } = usePaginatedQuery(
    ['offers', page],
    fetchOffers,
    {}
  )

  const fetchOfferDetails = React.useCallback(async (id) => {
    if (!id) {
      setDetails(null)
    }
    const req = await fetch(`${API}/job-offer/${id}`)
    if (req.ok) {
      const details = await req.json()
      setDetails(details)
    } else {
      setDetails(null)
    }
  }, [])

  const getSearchParams = React.useCallback(() => {
    const params = new URL(document.location).searchParams
    const offerId = params.get('id')
    if (offerId) setSelectedOffer(offerId)
    else {
      setSelectedOffer(null)
      setDetails(null)
    }
  }, [])

  React.useEffect(() => {
    if (resolvedData && resolvedData.data) {
      let data = allData || []
      data[page] = resolvedData.data
      setAllData(data)
    }
    refetch()
  }, [resolvedData, allData, refetch, page])

  React.useEffect(() => {
    getSearchParams()
  })

  React.useEffect(() => {
    if (selectedOffer) {
      fetchOfferDetails(selectedOffer)
    }
  }, [selectedOffer])

  if (status === 'loading' || !allData) return <p>Loading</p>

  if (status.error) return <p>Error: {error.message}</p>

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {details ? (
          <OfferDetails offer={details} />
        ) : (
          <OfferList
            allData={allData.flat()}
            setPage={setPage}
            page={page}
            latestData={latestData}
          />
        )}
      </div>
    </Container>
  )
}
