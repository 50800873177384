import React, { Component } from 'react'
import styled from 'styled-components'
import qs from 'querystringify'
import { Formik } from 'formik'
import { callApi } from '../../utils/api'
import { devices, API, fonts, colors } from '../../utils/constants'

const Form = styled.form`
  display: flex;
  width: auto;
  height: 100%;
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  flex-direction: column;
  button {
    height: 30px;
  }

  @media ${devices.tablet} {
    width: 480px;
  }
`

const FormHeader = styled.span`
  margin: 69px auto;
  font-weight: 700;
  font-size: 1.9rem;
`
const InputLabel = styled.label`
  font-size: 1.14rem;
  font-weight: 600;
  margin: 0 0 10px 48px;
`

const Input = styled.input`
  margin: 0 48px 28px 48px;
  background: #f6f8f9;
  border: none;
  padding: 15px;
  font-size: 0.9rem;
`

const SubmitButton = styled.button`
  background: ${colors.blue};
  margin: 70px auto 20px auto;
  min-width: 60%;
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  border: none;
  height: 47px !important;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
const StatusMessage = styled.div`
  margin: 0 48px;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  display: block;
  height: 13.5px;
  transform: translateY(-22px);
  color: ${props => (props.changed ? 'green' : 'red')};
`

export class ChangePasswordForm extends Component {
  state = { changePassToken: null, isPasswordChanged: null }

  componentDidMount() {
    if (window.location.search) {
      const query = qs.parse(window.location.search)
      if (query.changePass) {
        this.setState({
          changePassToken: query.changePass,
        })
      }
    }
  }

  render() {
    return (
      <Formik
        initialValues={{ password: '', passwordRepeat: '' }}
        validate={values => {
          let errors = {}
          if (values.password !== values.passwordRepeat) {
            errors.passwordRepeat = 'Wprowadzone hasła nie są takie same!'
          }
          return errors
        }}
        onSubmit={values => {
          callApi
            .url(`${API}/user/password-recovery/recover`)
            .post({ newPassword: values.password, token: this.state.changePassToken })
            .res(res => {
              this.setState({
                isPasswordChanged: true,
              })
            })
            .catch(e => {
              this.setState({
                isPasswordChanged: false,
              })
              console.error(e)
            })
        }}>
        {props => {
          const { values, handleChange, handleSubmit, handleBlur, errors, touched } = props
          return (
            <Form onSubmit={handleSubmit}>
              <FormHeader>Zmień hasło</FormHeader>
              <InputLabel htmlFor="password">Nowe hasło</InputLabel>
              <Input
                name="password"
                type="password"
                placeholder="hasło..."
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <InputLabel htmlFor="passwordRepeat">Powtórz nowe hasło</InputLabel>
              <Input
                name="passwordRepeat"
                type="password"
                placeholder="hasło..."
                value={values.passwordRepeat}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <StatusMessage changed={this.state.isPasswordChanged}>
                {errors.passwordRepeat && touched.password && touched.passwordRepeat && (
                  <span>{errors.passwordRepeat}</span>
                )}
                {this.state.isPasswordChanged && <span>Hasło zostało zmienione, zaloguj się.</span>}
                {this.state.isPasswordChanged === false && <span>Błąd podczas zmiany hasła</span>}
              </StatusMessage>
              {!this.state.isPasswordChanged ? (
                <SubmitButton type="submit" disabled={errors.passwordRepeat}>
                  Zmień
                </SubmitButton>
              ) : (
                <SubmitButton onClick={this.props.closeSelf}>Zamknij</SubmitButton>
              )}
            </Form>
          )
        }}
      </Formik>
    )
  }
}
