import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import qs from 'querystringify'
import { Provider } from 'react-redux'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { UserModal } from './components/UserModal'
import ScrollToTop from './components/ScrollToTop'
import { USER_ROLES } from './utils/constants'
import {
  Home,
  News,
  Courses,
  Schools,
  Materials,
  Instructors,
  ChangePassword,
  Profile,
  About,
  Terms,
  Privacy,
  Exam,
} from './pages'
import { connect } from 'react-redux'
import styled, { injectGlobal } from 'styled-components'
import SegoeUI from './fonts/segoeui.ttf'
import SegoeUIBold from './fonts/segoeuib.ttf'
import { devices, userModalTypes } from './utils/constants'

import 'react-datepicker/dist/react-datepicker.css'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import './index.css'

import store from './store'
import Products, { ProductsDetails } from './pages/Products'
import JobOffers from './pages/job-offers'
import { NewsDetails } from './pages/News'
import ForInsctructors, { ForInstructorsDetails } from './pages/ForInstructors'
import ForParents, { ForParentsDetails } from './pages/ForParents'
import TravelAgency, { TravelAgencyDetails } from './pages/TravelAgency'
import Discounts from './pages/Discounts'

injectGlobal`
    @font-face {
    font-family: 'Segoe';
    font-style: normal;
    font-weight: 400;
    src: url(${SegoeUI}) format('truetype');
  }

  @font-face {
    font-family: 'Segoe';
    font-style: normal;
    font-weight: 700;
    src: url(${SegoeUIBold}) format('truetype');
  }

  @media ${devices.tablet} {
    body {
      font-size: 10px;
    }
  }

  @media ${devices.laptop} {
    body {
      font-size: 16px;
    }
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  ul {
    list-style: none;
  }

  button,
  input,
  select,
  textarea {
    margin: 0;
  }

  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  img,
  video {
    height: auto;
    max-width: 100%;
  }

  iframe {
    border: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  td:not([align]),
  th:not([align]) {
    text-align: left;
  }

  body {
    margin: 0 auto;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 95%;
  margin: 0 auto;

  @media ${devices.laptopL} {
    width: 90%;
    padding: 0 1rem 0 1rem;
  }
`
class App extends Component {
  componentDidMount() {
    if (window.location.search) {
      const query = qs.parse(window.location.search)
      if (query.changePass) {
        this.props.toggleUserModal(userModalTypes.changePass)
      } else if (query.activeToken) {
        this.props.toggleUserModal(userModalTypes.active)
      } else if (query.newsletterToken) {
        this.props.toggleUserModal(userModalTypes.newsletter)
      }
    }
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            {window.location.pathname !== '/exam' && (
              <Container>
                <Header
                  currentUser={this.props.currentUser}
                  logOut={this.props.logOut}
                  toggleUserModal={this.props.toggleUserModal}
                />
              </Container>
            )}
            <Switch>
              <Route exact path="/news" component={News} />
              <Route exact path="/news/:id" component={NewsDetails} />
              <Route exact path="/for-instructors" component={ForInsctructors} />
              <Route exact path="/for-instructors/:id" component={ForInstructorsDetails} />
              <Route exact path="/for-parents" component={ForParents} />
              <Route exact path="/for-parents/:id" component={ForParentsDetails} />
              <Route exact path="/travel-agency" component={TravelAgency} />
              <Route exact path="/travel-agency/:id" component={TravelAgencyDetails} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/products/:id" component={ProductsDetails} />
              <Route exact path="/courses" component={Courses} />
              <Route exact path="/schools" component={Schools} />
              <Route exact path="/job-offers" component={JobOffers} />
              <Route exact path="/discounts" component={Discounts} />
              <Route
                exact
                path="/instructors"
                render={props => (
                  <Instructors currentUser={this.props.currentUser} location={props.location} />
                )}
              />
              <Route exact path="/materials" component={Materials} />
              <Route exact path="/newpass" component={ChangePassword} />
              <Route exact path="/about" component={About} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              {this.props.currentUser.token && (
                <Route
                  exact
                  path="/profile"
                  render={() => <Profile currentUser={this.props.currentUser} />}
                />
              )}
              <Route
                exact
                path="/exam"
                render={() =>
                  this.props.currentUser.token &&
                  this.props.currentUser.role === USER_ROLES.BASIC ? (
                    <Exam currentUser={this.props.currentUser} />
                  ) : (
                    <h1>Hola Hola!</h1>
                  )
                }
              />
              <Route path="/" component={Home} />
            </Switch>
            {window.location.pathname !== '/exam' && (
              <Footer
                toggleUserModal={this.props.toggleUserModal}
                currentUser={this.props.currentUser}
                logOut={this.props.logOut}
              />
            )}
            {window.location.pathname !== '/exam' && (
              <UserModal
                isUserModalOpen={this.props.userModal.isUserModalOpen}
                toggleUserModal={this.props.toggleUserModal}
                modalFormType={this.props.userModal.userModalType}
                setUserModalType={this.props.setUserModalType}
              />
            )}
          </ScrollToTop>
        </BrowserRouter>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    userModal: state.userModal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch.currentUser.logOut(),
    toggleUserModal: modalFormType => dispatch.userModal.toggleUserModal(modalFormType),
    setUserModalType: modalFormType => dispatch.userModal.setUserModalType(modalFormType),
  }
}

const AppConnectedToStore = connect(mapStateToProps, mapDispatchToProps)(App)

ReactDOM.render(
  <Provider store={store}>
    <AppConnectedToStore />
  </Provider>,
  document.getElementById('root')
)
