import React, { Component } from 'react'
import { API, colors } from '../../utils/constants'
import { callApi } from '../../utils/api'
import qs from 'querystringify'

export default class ActiveNewsletterModal extends Component {
  state = { message: 'Ładowanie' }
  componentDidMount() {
    if (window.location.search) {
      const query = qs.parse(window.location.search)
      if (query.newsletterToken) {
        if (query.active)
          callApi
            .url(`${API}/newsletter/active`)
            .post({ token: query.newsletterToken })
            .res(res => {
              this.setState({
                message: 'Mail dodany',
              })
            })
            .catch(e => {
              this.setState({
                message: 'Problem z dodaniem maila',
              })
              console.error(e)
            })
        else {
          callApi
            .url(`${API}/newsletter/delete/${query.newsletterToken}`)
            .delete()
            .res(res => {
              this.setState({
                message: 'Mail usunięty',
              })
            })
            .catch(e => {
              this.setState({
                message: 'Problem z usunięciem maila',
              })
              console.error(e)
            })
        }
      }
    }
  }

  render() {
    return (
      <div
        style={{
          width: '300px',
          textAlign: 'center',
          borderRadius: '15px',
          verticalAlign: 'center',
          margin: '10px 0',
        }}>
        <h1>{this.state.message}</h1>
        <button
          style={{
            width: '60px',
            border: 'none',
            background: `${colors.blue}`,
            minWidth: '60%',
            fontSize: '0.9rem',
            fontWeight: '600',
            color: '#fff',
            borderRadius: '30px',
            height: '50px',
            cursor: 'pointer',
            marginBottom: '10px',
          }}
          onClick={this.props.closeSelf}>
          Zamknij
        </button>
      </div>
    )
  }
}
