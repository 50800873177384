import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { devices, editProfilePageOptions, fonts, colors, API } from './../../utils/constants'
import profileArrow from './../../images/profileArrow.svg'

const NavbarStyled = styled.div`
  grid-area: navbar;
  font-family: ${fonts.mainFont};
`

const customStyles = {
  control: base => ({
    ...base,
    background: '#f7f9fc',
    borderRadius: 'none',
    border: 'none',
    fontFamily: `${fonts.mainFont}`,
    fontSize: '1.1rem',
    fontWeight: 600,
    color: `${colors.dark}`,
  }),
}

const MobileSelectWrapper = styled.div`
  display: block;
  margin: 10px 0;

  div {
    z-index: 10;
  }

  @media ${devices.tablet} {
    display: none;
  }
`

const TabletSelectWrapper = styled.div`
  display: none;
  @media ${devices.tablet} {
    display: flex;
  }
  @media ${devices.laptop} {
    flex-wrap: wrap;
  }
`
const TabletSelectButton = styled.button`
  width: 33%;
  text-align: left;
  background: #f7f9fc;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.dark};
  margin: 0 0 10px 0;
  display: flex;
  justify-content: space-between;

  &:nth-child(2) {
    margin: 0 10px 10px 10px;
  }

  &:nth-child(3) {
    margin: 0 10px 10px 0px;
  }

  @media ${devices.laptop} {
    width: 100%;
    margin: 0;

    &:nth-child(2) {
      margin: 0;
    }

    &:nth-child(3) {
      margin: 0;
    }
  }
`

const Icon = styled.div`
  background: ${props => (props.active ? `${colors.blue}` : 'transparent')};
  transform: rotate(90deg);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  img {
    filter: ${props => (props.active ? 'brightness(13)' : 'none')};
  }

  @media ${devices.laptop} {
    transform: rotate(0deg);
  }
`

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-end;

  @media ${devices.laptop} {
    margin: 0 0 20px 0;
  }
`
const Level = styled.div`
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.dark};
  margin: 0 0 10px 0;

  span {
    background: #fad29e;
    display: inline-flex;
    height: 100%;
    padding: 9.5px 20px;
    margin: 0 10px 0 0;
  }

  @media ${devices.tablet} {
    width: unset;
    margin: 0;
    span {
      margin: 0 10px 0 0;
    }
  }

  @media ${devices.laptop} {
    order: 2;
    margin: 0 0 0 10px;
    position: absolute;
    top: 0;
    left: 80px;
  }
`
const Avatar = styled.div`
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    max-height: 100%;
  }

  @media ${devices.laptop} {
    width: 100px;
    height: 100px;
    order: 1;
  }
`

const Name = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.dark};
  line-height: 40px;
  margin: 0 10px;

  @media ${devices.laptop} {
    order: 3;
    font-size: 1.1rem;
  }
`
const City = styled.span`
  opacity: 0.4;
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 40px;
  color: ${colors.dark};

  @media ${devices.laptop} {
    order: 4;
    display: none;
  }
`

export const Navbar = props => {
  const coursesWithExam = props.personalInfo.courses ? props.personalInfo.courses.filter(
    course => course.levels === 'Asystent Instruktora' && course.currentExam || course.levels === 'Instruktor SITS' && course.currentExam
  ) : []

  return (
    <NavbarStyled>
      <Info>
        <Level>
          <span>{props.personalInfo.level}</span>
        </Level>
        <Avatar>
          <img src={`${API}${props.personalInfo.imageUrl}?t=${Date.now()}`} alt="avatar" />
        </Avatar>
        <Name>
          {props.personalInfo.firstName} {props.personalInfo.lastName}
        </Name>
        <City>{props.personalInfo.city}</City>
      </Info>
      <TabletSelectWrapper>
        <TabletSelectButton
          onClick={() => props.changeProfilePage({ value: 'personalInfo', label: 'Twoje dane' })}>
          <div>Twoje dane</div>
          <Icon active={props.profilePage.value === 'personalInfo'}>
            <img src={profileArrow} alt="profileArrow" />
          </Icon>
        </TabletSelectButton>
        <TabletSelectButton
          onClick={() => props.changeProfilePage({ value: 'card', label: 'Legitymacja' })}>
          <div>Legitymacja</div>
          <Icon active={props.profilePage.value === 'card'}>
            <img src={profileArrow} alt="profileArrow" />
          </Icon>
        </TabletSelectButton>
        <TabletSelectButton
          onClick={() =>
            props.changeProfilePage({ value: 'courseHistory', label: 'History kursów' })
          }>
          <div>Historia kursów</div>
          <Icon active={props.profilePage.value === 'courseHistory'}>
            <img src={profileArrow} alt="profileArrow" />
          </Icon>
        </TabletSelectButton>
        {coursesWithExam.length > 0 && (
          <TabletSelectButton
            onClick={() => props.changeProfilePage({ value: 'exams', label: 'Egzaminy' })}>
            <div>Egzamin</div>
            <Icon active={props.profilePage.value === 'exams'}>
              <img src={profileArrow} alt="profileArrow" />
            </Icon>
          </TabletSelectButton>
        )}
      </TabletSelectWrapper>

      <MobileSelectWrapper>
        <Select
          value={props.profilePage}
          onChange={props.changeProfilePage}
          options={editProfilePageOptions}
          styles={customStyles}
        />
      </MobileSelectWrapper>
    </NavbarStyled>
  )
}
