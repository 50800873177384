import React from 'react'
import styled from 'styled-components'
import { devices, fonts, colors } from '../utils/constants'
import TermsSideImg from './../../src/images/schoollist.jpg'

const TermsWrapper = styled.div`
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-areas: '. content .';

  @media ${devices.tablet} {
    grid-template-columns: 40px auto 40px;
    grid-template-areas: '. content .';
  }

  @media ${devices.laptop} {
    grid-template-columns: 40px 1fr 3fr 40px;
    grid-template-areas: '. picture content .';
    grid-column-gap: 80px;
  }

  @media ${devices.desktop} {
    grid-template-columns: 168px 1fr 3fr 168px;
  }
`

const TermsContent = styled.div`
  grid-area: content;
  font-family: ${fonts.mainFont};
  font-size: 1rem;
`
const TermsImage = styled.div`
  grid-area: picture;
  display: none;
  width: 100%;
  img {
    width: 100%;
  }

  @media ${devices.laptop} {
    display: block;
  }
`

const Terms = () => (
  <TermsWrapper>
    <TermsImage>
      <img src={TermsSideImg} alt={'about'} />
    </TermsImage>
    <TermsContent>
      <h2>Warunki korzystania</h2>
      <p>
        Stowarzyszenie Instruktorów i Trenerów Snowboardu jest podmiotem odpowiedzialnym za
        szkolenie kadr instruktorskich na terenie Polski. W zamierzeniu członków założycieli,
        Stowarzyszenie ma być silną organizacją skupiającą całe polskie środowisko instruktorów i
        trenerów snowboardu. Stawiamy sobie za cel kształcenie dydaktyków najwyższej klasy, oferując
        im pełen dostęp do najnowszych materiałów szkoleniowych, dbając jednocześnie o interesy
        członków na wszystkich polach związanych z pracą instruktorską i trenerską. Staramy się na
        bieżąco ustanawiać i rozwijać standardy szkolenia snowboardowego oraz przyczyniać się do
        wzrostu poziomu polskiego snowboardingu a także bezpieczeństwa na stokach. Stowarzyszenie
        Instruktorów i Trenerów Snowboardu jest podmiotem działającym na podstawie ustawy z dnia 7
        kwietnia 1989 r. Prawo o stowarzyszeniach (Dz. U. z 2001 Nr 79, poz. 855). Osobowość prawną
        uzyskało z dniem 22.12.2008 r., kiedy zostało wpisane do Rejestru Stowarzyszeń Krajowego
        Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia Fabrycznej we Wrocławiu
        Wydział VI Gospodarczy KRS. Stowarzyszenie legitymuje się numerami: KRS 0000320087 i NIP
        899-267-28-75. Zapraszamy do bliższego zapoznania się z naszą witryną.
      </p>
    </TermsContent>
  </TermsWrapper>
)

export default Terms
