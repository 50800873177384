import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { withFormik } from 'formik'
import { callApiFormData } from './../../utils/api'
import { devices, fonts, colors } from './../../utils/constants'
import DatePicker from 'react-datepicker'
import { API } from '../../utils/constants'
import PropTypes from 'prop-types'
import { AddFeaturedImage } from './imageUploadForm'
import MediaQuery from 'react-responsive'
import { ClipLoader } from 'react-spinners'

const Form = styled.form`
  height: 100%;
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  z-index: 100;
  padding: 0;

  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }

  @media ${devices.desktop} {
    width: 70vw;
  }
`

const DescriptionLabel = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  margin: 20px 0 10px 0;
`

const InputStyled = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 5.6px;
  background-color: #f6f8f9;
  margin: 0 0 10px 0;
  border: none;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  width: 100%;
  ::placeholder {
    font-size: 14px;
    color: #aaa7ac;
  }

  @media ${devices.tablet} {
    width: ${props => (props.width ? props.width : '100%')};
    margin: ${props => (props.customMargin ? props.customMargin : '0 0 10px 0')};
    border-right: ${props => (props.customBorder ? props.customBorder : 'none')};
  }
`

const ImageUploader = styled.div`
  width: 100%;
  height: auto;
  border-radius: 5.6px;
  margin: 0 0 8px 0;
  border: none;
  ::placeholder {
    font-size: 14px;
    color: #aaa7ac;
  }
`

const StartDateInput = styled.div`
  height: 35px;
  vertical-align: middle;
  border-radius: 5.6px;
  background-color: #f6f8f9;
  margin: 0 0 8px 0px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  font-size: 14px;
`

const InLineDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`

const FormHeader = styled.h3`
  margin: 40px auto 10px auto;
  font-weight: 700;
  font-size: 1.9rem;
  text-align: center;

  @media ${devices.tablet} {
    margin: 20px auto 10px auto;
  }
`
const FormDescription = styled.p`
  margin: 20px auto;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
`

const LoginButton = styled.button`
  background: ${props => (props.loading ? 'grey' : `${colors.blue}`)};
  margin: 70px auto 0 auto;
  min-width: 60%;
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;
  border-radius: 30px;
  border: none;
  height: 47px !important;
  cursor: pointer;
  display: block;
  margin-bottom: 15px;
`

const LeftSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 48px;

  @media ${devices.laptop} {
    width: 67%;
    margin: 0 40px 0 80px;
  }

  @media ${devices.desktop} {
    width: 67%;
    margin: 0 70px 0 150px;
  }
`

const RightSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 48px;

  @media ${devices.laptop} {
    width: 33%;
    margin: 0 80px 0 40px;
  }

  @media ${devices.desktop} {
    width: 33%;
    margin: 0 140px 0 70px;
  }
`

const ErrorMessage = styled.span`
  margin: 0 0 0 20px;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  color: #b33a3a;
  display: block;
  height: 13.5px;
`

const SchoolTypeRadio = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  label {
    margin: 3px 23px 0 5px;
    font-family: ${fonts.mainFont};
    font-size: 0.9rem;
    font-weight: 600;
    color: ${colors.dark};
  }

  fieldset {
    border: none;
  }

  @media ${devices.tablet} {
    flex-direction: row;
  }
`

const StatusMessage = styled.div`
  font-size: 1rem;
  font-family: ${fonts.secondaryFont};
  display: block;
  text-align: center;
  color: green;
  max-width: 295px;
  width: 100%;
  margin: 0 auto;

  @media ${devices.tablet} {
    max-width: 548px;
  }

  @media ${devices.laptop} {
    max-width: 90%;
  }
`

class SchoolRegisterForm extends Component {
  componentDidMount() {
    this.props.setStatus({
      loader: false,
      isSubmitted: false,
    })
  }

  render() {
    const { values, errors, status, handleSubmit, handleChange, setFieldValue } = this.props
    if (!status) return null
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <FormHeader>Konto szkoły</FormHeader>
          <FormDescription>
            Uzupełnij dane swojej placówki w celu <br /> znalezienia się na stronie
          </FormDescription>
          <InLineDiv>
            <LeftSide>
              <MediaQuery maxWidth={1023}>
                <DescriptionLabel>Logo szkoły</DescriptionLabel>
                <ImageUploader>
                  <AddFeaturedImage uploadFeaturedImage={file => setFieldValue('file', file)} />
                </ImageUploader>
              </MediaQuery>
              <DescriptionLabel>Podstawowe informacje</DescriptionLabel>
              <InputStyled
                name="fullName"
                type="text"
                placeholder="Pełna nazwa szkoły"
                value={values.fullName}
                onChange={handleChange}
                required
              />
              <InputStyled
                name="nip"
                type="text"
                placeholder="NIP"
                value={values.nip}
                onChange={handleChange}
                width={'calc(50% - 10px)'}
                customMargin={'0 10px 10px 0'}
                required
              />
              <InputStyled
                name="regon"
                type="text"
                placeholder="REGON"
                width={'50%'}
                value={values.regon}
                onChange={handleChange}
              />
              <ErrorMessage>{errors && <span>{errors.password}</span>}</ErrorMessage>
              <DescriptionLabel>Rodzaj szkoły</DescriptionLabel>
              <SchoolTypeRadio>
                <fieldset>
                  <input
                    type="radio"
                    name="type"
                    value={values.type}
                    checked={values.type === 'pss'}
                    onChange={() => {
                      setFieldValue('type', 'pss')
                    }}
                  />
                  <label htmlFor="userType">Patronacka(PSS)</label>
                </fieldset>
                <fieldset>
                  <input
                    type="radio"
                    name="type"
                    value={values.type}
                    checked={values.type === 'lss'}
                    onChange={() => {
                      setFieldValue('type', 'lss')
                    }}
                  />
                  <label htmlFor="userType">Licencjonowana(LSS)</label>
                </fieldset>
              </SchoolTypeRadio>
              <DescriptionLabel>Podstawy działalności prawnej</DescriptionLabel>
              <InputStyled
                name="legalBasisNumber"
                type="text"
                placeholder="Numer"
                value={values.legalBasisNumber}
                onChange={handleChange}
                required
              />
              <InputStyled
                name="legalBasisRegistraionPlace"
                type="text"
                placeholder="Miejsce rejestracji"
                value={values.legalBasisRegistraionPlace}
                onChange={handleChange}
                required
              />

              <InputStyled
                name="legalBasisPermit"
                type="text"
                placeholder="Zakres działania"
                value={values.legalBasisPermit}
                onChange={handleChange}
                required
              />
              <DatePicker
                name="startUpDate"
                placeholderText="Data rozpoczęcia działalności"
                onChange={date => setFieldValue('startUpDate', date)}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={50}
                selected={values.startUpDate}
                customInput={<DatepickerCustomInput />}
              />
              <ErrorMessage>
                {errors.startUpDate && <span>To pole jest obowiązkowe.</span>}
              </ErrorMessage>
            </LeftSide>
            <RightSide>
              <MediaQuery minWidth={1024}>
                <DescriptionLabel>Logo szkoły</DescriptionLabel>
                <ImageUploader>
                  <AddFeaturedImage uploadFeaturedImage={file => setFieldValue('file', file)} />
                </ImageUploader>
              </MediaQuery>
              <DescriptionLabel>Adres szkoły</DescriptionLabel>
              <InputStyled
                name="street"
                type="text"
                placeholder="Ulica"
                value={values.street}
                onChange={handleChange}
                required
              />
              <InputStyled
                name="postCode"
                type="text"
                placeholder="Kod pocztowy"
                value={values.postCode}
                onChange={handleChange}
                width={'calc(45% - 10px)'}
                customMargin={'0 10px 10px 0'}
                required
              />
              <InputStyled
                name="city"
                type="text"
                placeholder="Miejscowość"
                value={values.city}
                onChange={handleChange}
                width={'55%'}
                required
              />
              <DescriptionLabel>Dane kontaktowe</DescriptionLabel>
              <InputStyled
                name="phone"
                type="text"
                placeholder="Telefon"
                value={values.phone}
                onChange={handleChange}
                required
              />
              <InputStyled
                name="website"
                type="text"
                placeholder="Strona WWW"
                value={values.website}
                onChange={handleChange}
                required
              />
            </RightSide>
          </InLineDiv>
          {!status.isSubmitted ? (
            <LoginButton disabled={status.loader} loading={status.loader} type="submit">
              {!status.loader ? (
                'Utwórz profil szkoły'
              ) : (
                <ClipLoader sizeUnit={'px'} size={15} color={'#ecf0ff'} loading={values.loader} />
              )}
            </LoginButton>
          ) : (
            <Fragment>
              <StatusMessage>
                Twoje konto zostało utworzone, aktywuj je za pomocą odnośnika we wiadomości email.
              </StatusMessage>
              <LoginButton onClick={this.props.closeSelf}>Zamknij</LoginButton>
            </Fragment>
          )}
        </Form>
      </React.Fragment>
    )
  }
}

let SchoolRegisterFormHigherOrderComponent = withFormik({
  mapPropsToValues: () => ({
    type: 'pss',
    fullName: '',
    nip: '',
    shortName: '',
    regon: '',
    website: '',
    city: '',
    postCode: '',
    street: '',
    phone: '',
    legalBasisNumber: '',
    legalBasisRegistraionPlace: '',
    legalBasisPermit: '',
    startUpDate: null,
  }),
  validate: values => {
    let errors = {}
    if (!values.startUpDate) {
      errors.startUpDate = true
    }
    return errors
  },
  handleSubmit: (values, { props, setStatus }) => {
    const model = {
      ...values,
      ...props.registerFormValues,
      startUpDate: values.startUpDate ? values.startUpDate.toISOString() : null,
      shortName: 'shortName',
    }

    setStatus({ loader: true })
    callApiFormData
      .url(`${API}/school`)
      .formData(model)
      .post()
      .res(res => {
        setStatus({ loader: false, isSubmitted: true })
      })
      .catch(e => {
        setStatus({ loader: false })
        console.error(e)
      })
  },
  validateOnChange: false,
  validateOnBlur: false,
})(SchoolRegisterForm)

export default SchoolRegisterFormHigherOrderComponent

class DatepickerCustomInput extends React.Component {
  render() {
    return (
      <StartDateInput onClick={this.props.onClick}>
        {this.props.value ? this.props.value : 'Data rozpoczęcia działalności'}
      </StartDateInput>
    )
  }
}

DatepickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
}
