import styled from 'styled-components'

export const ReadMoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 32px;
  margin-bottom: 50px;

  @media screen and (max-width: 769px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`

export const HideOnMobile = styled.div`
  @media screen and (min-width: 768px) {
    display: contents;
  }
  display: none;
`
