import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import RecoverPassForm from './login/RecoverPass'

const Form = styled.form`
  display: flex;
  width: 30%;
  flex-direction: column;
  margin: 0 auto;
  button {
    height: 30px;
  }
`

const LoginForm = props => {
  const { values, handleSubmit, handleChange } = props
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <input
          name="email"
          type="email"
          placeholder="Email"
          value={values.email}
          onChange={handleChange}
          required
        />
        <input
          name="password"
          type="password"
          placeholder="Hasło"
          value={values.password}
          onChange={handleChange}
          required
        />
        <button type="submit">SUBMIT</button>
      </Form>
      <RecoverPassForm />
    </React.Fragment>
  )
}

let LoginFormHigherOrderComponent = withFormik({
  mapPropsToValues: () => ({ email: ``, password: '' }),
  handleSubmit: (values, { props }) => {
    props.logIn(values)
  },
})(LoginForm)

LoginFormHigherOrderComponent = connect(
  state => ({ currentUser: state.currentUser }),
  dispatch => ({
    logIn: logInData => dispatch.currentUser.logIn(logInData),
  })
)(LoginFormHigherOrderComponent)

export default LoginFormHigherOrderComponent
