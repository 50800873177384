import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { RecoverForm } from '../header/loginForm/RecoverForm'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { devices, fonts, colors, userModalTypes } from '../../utils/constants'
import modalSideImg from './../../images/loginModalBackground.jpg'

const FormWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 70vh;

  @media ${devices.tablet} {
    width: 480px;
  }

  @media ${devices.laptop} {
    min-height: 0;
  }
`

const Form = styled.form`
  display: flex;
  font-family: ${fonts.mainFont};
  color: ${colors.dark};
  flex-direction: column;
`
const FormText = styled.p`
  margin: 0 0 0 48px;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  color: #b33a3a;
  display: block;
  height: 13.5px;
`

const InputLabel = styled.label`
  font-size: 1.14rem;
  font-weight: 600;
  margin: 0 0 10px 48px;
`

const Input = styled.input`
  margin: 0 48px 28px 48px;
  background: #f6f8f9;
  border: none;
  padding: 15px;
  font-size: 0.9rem;
`

const FormHeader = styled.span`
  margin: 20px auto;
  font-weight: 700;
  font-size: 1.9rem;
`

const RecoverPassButton = styled.span`
  margin: 0 48px 0 0;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
`
const LoginButton = styled.button`
  background: ${colors.blue};
  margin: 20px auto;
  min-width: 60%;
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;
  border-radius: 30px;
  border: none;
  height: 47px;
  cursor: pointer;
`
const SideImage = styled.div`
  display: none;
  width: 480px;
  height: 560px;
  overflow: hidden;
  position: relative;

  img {
    width: 480px;
    height: 560px;
  }

  @media ${devices.laptop} {
    display: block;
  }
`
const GoToRegister = styled.button`
  position: absolute;
  bottom: 52px;
  left: 50%;
  transform: translate(-50%, 0);
  background: none;
  border: none;
  color: ${colors.blue};
  font-size: 1rem;
  font-weight: 700;
  font-family: ${fonts.mainFont};
  cursor: pointer;

  span {
    text-decoration: underline;
  }
`

class LoginForm extends Component {
  state = {
    recoverPassword: null,
  }

  recoverPassword = () => {
    this.setState(prevState => {
      return { recoverPassword: !prevState.recoverPassword }
    })
  }

  render() {
    const { values, handleSubmit, handleChange, status } = this.props
    return (
      <Fragment>
        {!this.state.recoverPassword && (
          <FormWrapper>
            <FormHeader>Logowanie</FormHeader>
            <Form onSubmit={handleSubmit} id="loginForm">
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                required
              />
              <InputLabel htmlFor="password">Hasło</InputLabel>
              <Input
                name="password"
                type="password"
                placeholder="Hasło"
                value={values.password}
                onChange={handleChange}
                required
              />
              {status ? <FormText color="danger">Nieprawidłowy login lub hasło!</FormText> : null}
              <RecoverPassButton onClick={() => this.recoverPassword()}>
                Nie pamiętasz hasła?
              </RecoverPassButton>
            </Form>
            <LoginButton type="submit" form="loginForm">
              Zaloguj się
            </LoginButton>
          </FormWrapper>
        )}
        {this.state.recoverPassword && (
          <RecoverForm closeModal={this.props.closeModal} backToLoginForm={this.recoverPassword} />
        )}
        <SideImage>
          <img src={modalSideImg} alt="loginSideImage" />
          <GoToRegister onClick={() => this.props.setUserModalType(userModalTypes.register)}>
            Nie masz jeszcze konta?<span> Zarejestruj się</span>
          </GoToRegister>
        </SideImage>
      </Fragment>
    )
  }
}

let LoginFormHigherOrderComponent = withFormik({
  mapPropsToValues: () => ({ email: ``, password: '' }),
  handleSubmit: (values, { props, setStatus }) => {
    props
      .logIn(values)
      .then(props.toggleUserModal)
      .catch(err => {
        setStatus('loginError')
        console.error(err)
      })
  },
})(LoginForm)

LoginFormHigherOrderComponent = connect(
  state => ({ currentUser: state.currentUser }),
  dispatch => ({
    logIn: logInData => dispatch.currentUser.logIn(logInData),
    toggleUserModal: () => dispatch.userModal.toggleUserModal(),
    setUserModalType: modalFormType => dispatch.userModal.setUserModalType(modalFormType),
  })
)(LoginFormHigherOrderComponent)

export default LoginFormHigherOrderComponent
