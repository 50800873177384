import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 90%;
  padding: 0 1rem 0 1rem;
  margin: 0 auto;
  padding-top: 70px;
  display: flex;
`

export default Container
