import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { DesktopNavigation } from './header/DesktopNavigation'
import { MobileNavigation } from './header/MobileNavigation'
import sitsLogo from './../images/sitsLogo.svg'
import { devices, userModalTypes, fonts, colors, USER_ROLES } from './../utils/constants'
import { SCHOOL_URL, ADMIN_URL } from './../env'

const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  height: 100px;
  position: relative;
`
const LogoWrapper = styled(Link)`
  display: flex;
  height: 100%;
`

const LogoStyled = styled.img`
  align-self: center;
`

const NavItem = styled.span`
  align-self: center;
  text-decoration: none;
  color: ${colors.blue};
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  display: none;
  padding: 0 6px;

  @media ${devices.laptopPlus} {
    display: block;
    padding: 0 15px;
  }
`

const NavButton = styled.span`
  background: ${colors.blue};
  color: #fff;
  align-self: center;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 16px;
  padding: 13px 33px;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  display: none;

  @media ${devices.laptopPlus} {
    display: block;
  }
`

const NavLink = styled(Link)`
  background: ${colors.blue};
  color: #fff;
  align-self: center;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 16px;
  padding: 13px 33px;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  display: none;
  text-decoration: none;

  @media ${devices.laptopPlus} {
    display: block;
  }
`

const NavA = styled.a`
  background: ${colors.blue};
  color: #fff;
  align-self: center;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  font-size: 16px;
  padding: 13px 33px;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  display: none;
  text-decoration: none;
  @media ${devices.laptopPlus} {
    display: block;
  }
`

const MobileNavigationWrapper = styled.div`
  display: block;

  @media ${devices.laptopPlus} {
    display: none;
  }
`

export const Header = props => (
  <HeaderStyled>
    <LogoWrapper to="/">
      <LogoStyled src={sitsLogo} alt="sits-logo" height="62" width="95" />
    </LogoWrapper>
    <DesktopNavigation />
    {!props.currentUser.token && (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <NavItem onClick={() => props.toggleUserModal(userModalTypes.login)}>Zaloguj się</NavItem>
        <NavButton onClick={() => props.toggleUserModal(userModalTypes.register)}>
          Stwórz konto
        </NavButton>
      </div>
    )}
    {props.currentUser.token && (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <NavItem onClick={() => props.logOut()}>Wyloguj</NavItem>
        {props.currentUser.role === USER_ROLES.BASIC && (
          <NavLink to="/profile">Profil - {props.currentUser.email}</NavLink>
        )}
        {props.currentUser.role === USER_ROLES.SCHOOL && (
          <NavA href={SCHOOL_URL}>Panel szkoły</NavA>
        )}
        {props.currentUser.role === USER_ROLES.ADMIN && <NavA href={ADMIN_URL}>Panel admina</NavA>}
      </div>
    )}
    <MobileNavigationWrapper>
      <MobileNavigation
        toggleUserModal={props.toggleUserModal}
        currentUser={props.currentUser}
        logOut={props.logOut}
      />
    </MobileNavigationWrapper>
  </HeaderStyled>
)
