import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { callApi } from '../../../utils/api'
import { API, devices, fonts, colors } from '../../../utils/constants'

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 550px;
  background: #f7f9fc;
  font-family: ${fonts.mainFont};
  font-weight: 600;
  color: ${colors.dark};
  font-size: 1.1rem;

  @media ${devices.tablet} {
    width: 100%;
  }

  @media ${devices.laptop} {
    width: 56%;
  }

  @media ${devices.desktop} {
    width: 46%;
  }
`
const Input = styled.input`
  background: transparent;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #d0d9f9;
  border-left: none;
  margin: 10px auto;
  width: 60%;
  padding: 10px 0;
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  &:first-child {
    margin-top: 50px;
  }

  @media ${devices.tablet} {
    &:first-child {
      margin-top: 120px;
    }
    padding: 15px 0;
  }
`

const TextArea = styled.textarea`
  background: transparent;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #d0d9f9;
  border-left: none;
  margin: 10px auto;
  padding: 10px 0;
  width: 60%;
  font-family: ${fonts.mainFont};
  font-size: 1.1rem;
  font-weight: 600;
  resize: none;

  &:first-child {
    margin-top: 50px;
  }

  @media ${devices.tablet} {
    &:first-child {
      margin-top: 120px;
    }
    padding: 15px 0;
  }
`

const InputSubmit = styled.input`
  background: #112369;
  color: #fff;
  align-self: center;
  font-family: ${fonts.mainFont};
  font-weight: 700;
  font-size: 16px;
  padding: 16px 33px;
  border-radius: 30px;
  width: 195px;
  margin: 30px auto 20px auto;
  border: none;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const StatusMessage = styled.div`
  margin: 0 auto;
  font-size: 0.6rem;
  font-family: ${fonts.secondaryFont};
  display: block;
  height: 13.5px;
  color: ${colors.blue};
`

export class Form extends Component {
  state = { isFormSubmited: false }

  render() {
    return (
      <Formik
        onSubmit={values => {
          callApi
            .url(`${API}/admin/contact`)
            .post({ email: values.email, phoneNumber: values.phoneNumber, content: values.message })
            .res(() => this.setState({ isFormSubmited: true }))
            .catch(e => console.error(e))
        }}
        initialValues={{
          email: '',
          phoneNumber: '',
          message: '',
        }}>
        {props => {
          const { values, handleChange, handleBlur, handleSubmit } = props

          return (
            <FormStyled onSubmit={handleSubmit}>
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <Input
                name="phoneNumber"
                type="tel"
                placeholder="Numer telefonu"
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <TextArea
                id="message"
                type="textarea"
                placeholder="Treść wiadomości"
                rows="5"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputSubmit type="submit" value="Wyślij" disabled={this.state.isFormSubmited} />
              <StatusMessage>
                {this.state.isFormSubmited && <span>Wiadomość została wysłana</span>}
              </StatusMessage>
            </FormStyled>
          )
        }}
      </Formik>
    )
  }
}
